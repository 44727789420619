import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navebar from "./Navebar";
import Footer from "./Footer";
const Contact = () => {
  const navigate = useNavigate();
  const [userData, setData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    userid: ""
  });


  const handleChange = (e) => {
    if (e) {
      const name = e.target.name;
      const value = e.target.value;
      setData((prevData) => ({ ...prevData, [name]: value }));
    }
  };


  useEffect(() => {

    handleChange();
    // fetch data
    const dataFetch = async () => {
      try {
        const data = await (await fetch("/profile", {
          method: "GET",
          headers: {
            Accept: "application/json", "Content-Type": "application/json"
          },
          credentials: "include"
        })).json();
        console.log(data);
        setData(data);
        // set state when the data received
      } catch (err) {
        navigate('/login')
        console.log(err);
      }
    };
    dataFetch();
  }, [navigate]);

 

  ///Send the data in backend
  const contactForm = async (e) => {
    e.preventDefault();
    const { name, email, phone, message } = userData;
    const res = await fetch('/contact', {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        name, email, phone, message
      })
    });
    const data = await res.json();
    if (!data) {
      alert('Data not send');
    } else {
      alert('Data send');
      setData({ ...userData, message: "" });
    }
  }
  return (
    <>
      <div className="main-wrapper website">
        <Navebar />
        <div className="pv-shop-area commonpage">
          <div className="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
            <div className="container eduvibe-animated-shape">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcrumb-inner text-start">
                    <div className="page-title">
                      <h3 className="title">Get In Touch </h3>
                    </div>
                    <nav className="edu-breadcrumb-nav">
                      <ol className="edu-breadcrumb d-flex justify-content-start liststyle">
                        <li className="breadcrumb-item">
                          <Link to="/">
                            Home</Link>
                        </li>
                        <li className="separator">
                          <i className="ri-arrow-drop-right-line"></i>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Fill in the data below.
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                  <div className="shape-image shape-image-1">
                    <img
                      src="/front/images/shapes/shape-11-07.png"
                      alt="Shape Thumb"
                    />
                  </div>
                  <div className="shape-image shape-image-2">
                    <img
                      src="/front/images/shapes/shape-01-02.png"
                      alt="Shape Thumb"
                    />
                  </div>
                  <div className="shape-image shape-image-3">
                    <img
                      src="/front/images/shapes/shape-03.png"
                      alt="Shape Thumb"
                    />
                  </div>
                  <div className="shape-image shape-image-4">
                    <img
                      src="/front/images/shapes/shape-13-12.png"
                      alt="Shape Thumb"
                    />
                  </div>
                  <div className="shape-image shape-image-5">
                    <img
                      src="/front/images/shapes/shape-36.png"
                      alt="Shape Thumb"
                    />
                  </div>
                  <div className="shape-image shape-image-6">
                    <img
                      src="/front/images/shapes/shape-05-07.png"
                      alt="Shape Thumb"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container eduvibe-animated-shape mt-4">
            <div className="edu-contact-us-area eduvibe-contact-us bg-color-white">
              <div className="container eduvibe-animated-shape">
                <div className="row g-5 mt--20">
                  <div className="col-lg-6">
                    <div className="contact-info pr--70 pr_lg--0 pr_md--0 pr_sm--0">
                      <div className="row g-5">
                        {/* Start Contact Info  */}
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 sal-animate" data-sal-delay={150} data-sal="slide-up" data-sal-duration={800}>
                          <div className="contact-address-card-1 website">
                            <div className="inner">
                              <div className="icon">
                                <i className="ri-global-line" />
                              </div>
                              <div className="content">
                                <h6 className="title">MISSISSAUGA WEST</h6>
                                <p>500 – 3660 Hurontario St Mississauga, ON. L5B 3C4 Canada</p>
                                <p><a href="tel:+905(306)0666">(905) 306-0666</a></p>
                                <p><a href="tel:+647(948)5571">(647) 948-5571</a></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* End Contact Info  */}
                        {/* Start Contact Info  */}
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 sal-animate" data-sal-delay={200} data-sal="slide-up" data-sal-duration={800}>
                          <div className="contact-address-card-1 phone">
                            <div className="inner">
                              <div className="icon">
                                <i className="icon-Headphone" />
                              </div>
                              <div className="content">
                                <h6 className="title">KINGSTON</h6>
                                <p>1469 Princess St,Kingston, ON. K7M 3E9 Canada</p>
                                <p><a href="tel:+613(544)8973"> (613) 544-8973</a></p>
                                <p><a href="tel:+619(544)8980">(619) 544-8980</a></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* End Contact Info  */}
                        {/* Start Contact Info  */}
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 sal-animate" data-sal-delay={250} data-sal="slide-up" data-sal-duration={800}>
                          <div className="contact-address-card-1 email">
                            <div className="inner">
                              <div className="icon">
                                <i className="icon-mail-open-line" />
                              </div>
                              <div className="content">
                                <h6 className="title">Email Us</h6>
                                <p><a href="mailto:info@aolccollege.ca"> info@aolccollege.ca</a></p>
                                <p><a href="mailto:admissions@aolccollege.ca">admissions@aolccollege.ca</a></p>
                               
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* End Contact Info  */}
                        {/* Start Contact Info  */}
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 sal-animate" data-sal-delay={300} data-sal="slide-up" data-sal-duration={800}>
                          <div className="contact-address-card-1 location">
                            <div className="inner">
                              <div className="icon">
                                <i className="icon-mail-open-line" />
                              </div>
                              <div className="content">
                                <h6 className="title">Canadians</h6>
                                <p><a href="mailto:admissions.mississauga@aolccollege.ca">admissions.mississauga@aolccollege.ca</a></p>
                                <p><a href="mailto:admissions.kingston@aolccollege.ca">admissions.kingston@aolccollege.ca </a></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* End Contact Info  */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                  <form className="rnt-contact-form rwt-dynamic-form row requires-validation" method="POST">
      <div className="col-md-12">
        <div className="form-group">
          <input
            className="form-control form-control-lg"
            type="text"
            name="name"
            id="name"
            value={userData.name}
            onChange={handleChange}
            placeholder="Full Name"
            required
          />
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-group">
          <input
            className="form-control form-control-lg"
            type="email"
            name="email"
            id="email"
            value={userData.email}
            onChange={handleChange}
            placeholder="E-mail Address"
            required
          />

          <input
            type="hidden"
            name="userid"
            id="userid"
            value={userData.userid}
            required
          />
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-group">
          <input
            className="form-control form-control-lg"
            type="text"
            name="phone"
            id="phone"
            value={userData.phone}
            onChange={handleChange}
            placeholder="Phone number"
            required
          />
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-group">
          <textarea
            className="form-control form-control-lg"
            name="message"
            id="message"
            value={userData.message}
            onChange={handleChange}
            placeholder="Message"
            required
          />
        </div>
      </div>
      <div className="col-md-12 form-button">
        <div className="form-group">
          <button
            onClick={contactForm}
            name="signup"
            id="signup"
            type="submit"
            className="rn-btn edu-btn w-100"
          >
            <span>Submit Now</span><i className="icon-arrow-right-line-right" />
          </button>
        </div>
      </div>
    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Contact;