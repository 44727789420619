import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
//import { AdminContext } from '../../App';
import axios from 'axios';

const AdminLogout = () => {

   //const {dispatch} = useContext(AdminContext);
   const navigate  = useNavigate();
   const dispatch = useDispatch();
  // Promises
  useEffect(() => {
        fetch('/adminlogout',{
            method: "GET",
            headers: {
                Accept: "application/json", "Content-Type": "application/json"
            },
            credentials: "include"
        }).then((res) => {

            axios.post('/api/activityLog', {
                log_name: 'User Logout',
                log_type: 'userlogout',
                log_id: 'N/A'
             });

            dispatch({ type: 'LOGOUT_USER' });
            localStorage.removeItem('userInfo');

            dispatch({type:"ADMIN", payload:false});
            localStorage.removeItem('Admin');
            localStorage.removeItem('viewAs');
            localStorage.removeItem('viewAsEmail');
            
            navigate('/admin/login');
        }).catch((err) => {
            console.log(err);
        });

  });

  return (
    <>
        
    </>
  )
}

export default AdminLogout