import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoutes = () => {

    const token_s = !!localStorage.getItem('Admin');
    const auth = { token: token_s };
    return(
        auth.token ? <Outlet/> : <Navigate to="/admin/login"/>
    )
}

export default PrivateRoutes