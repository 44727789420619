import React, { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../App";

const Logout = () => {
   const {dispatch} = useContext(UserContext);
   const navigate  = useNavigate();
  // Promises
  useEffect(() => {
        fetch('/logout',{
            method: "GET",
            headers: {
                Accept: "application/json", "Content-Type": "application/json"
            },
            credentials: "include"
        }).then((res) => {
            dispatch({type:"USER_FRONT", payload:false});
            localStorage.removeItem('UserF');
            
            navigate('/login');
        }).catch((err) => {
            console.log(err);
        });

  });

  return (
    <>
        
    </>
  )
}

export default Logout