import React, { useState, useEffect } from 'react'
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


const CloaTravelLetterList = () => {

  const userData = useSelector((state) => state.user);
 
  // DataTables

  useEffect(() => {
    const user_id = btoa(userData._id);
    const authtype = btoa(userData.authtype);
    // Fetch tasks when component mounts
    axios.get(`/api/cloatravelreq/${user_id}/${authtype}`).then((response) => {
      setRecords(response.data);
    });
    

  }, [userData._id,userData.authtype]);

 
  const columns = [
    
    {
      name: 'Student ID',
        selector: row=> row.entrollment_id,
        sortable: true
    },
    {
      name: 'Name',
        selector:  row=> row.firstName+' '+row.lastName,
        sortable: true
    },
    {
      name: 'Email',
        selector: row=> row.email,
        sortable: true
    },
    {
        name: 'Status',
        selector: row=> row.travel_letter_approved_rejected,
        sortable: true
    },
    { 
        name: 'Message',
        selector: row=> row.travel_letter_message,
        sortable: true
    },
    
    { name: 'Action', selector: function(row) {
        return (
          <>
          <Link to={`/admin/cloa-travel-letter/edit/${row._id}`}><i className='fa fa-edit mr-2 text-info'></i></Link>
          </>
        );
        
      } 
    }
    
    
  ];




const data = [];
const [records, setRecords] = useState(data);

  return (
    <>
    
      <div className="wrapper">
        <AdminHeader />

        <AdminSidebar />

        <div className="content-wrapper mt-2">
          <section className="content">
            <div className="">
              <div className="row">
                <div className="col-12">
                   
                    <div className="card card-dark">
                    <div className="card-header">
                      <h3 className="card-title">CLOA Travel Request Listing</h3>

                    </div>

                    <div className="card-body">
                    <DataTable columns={columns} data={records} pagination></DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <br/>
        <AdminFooter />
      </div>
    </>
  );
};

export default CloaTravelLetterList;