// userReducer.js
const userReducer = (state = {}, action) => {
  console.log(action);
  switch (action.type) {
    case 'LOGIN_USER':
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      };
    case 'LOGOUT_USER':
      return {};
    default:
      return state;
  }
};

export default userReducer;
