// Loader.js
import React from 'react';
import { RingLoader } from 'react-spinners';

const Loader = () => {
  return (
    <div className="loader">
      <RingLoader size={150} color={'#36D7B7'} loading={true} />
    </div>
  );
};

export default Loader;
