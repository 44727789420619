import React, { useState, useEffect } from 'react'
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import toast from 'react-hot-toast';


const AdminUsers = () => {

  // Create User
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState({ campus: '',program: '', price: '',duration: '' });
  const [editTask, setEditTask] = useState(null);

  const addTask = () => {
    axios.post('/api/study/program', newTask).then((response) => {
     
        if(response.data.success){
          toast.success(response.data.success);
            // Fetch tasks when component mounts
            axios.get('/api/study/program').then((response2) => setRecords(response2.data));

            // Clear the input fields
            setNewTask({ campus: '',program: '', price: '',duration: '' });
        }else{
          toast.error(response.data.error);
        }
      
      // Update the state with the new task
      //setTasks([...tasks, response.data]);
      

    });
  };

  const editTaskClick = (task) => {
    setEditTask(task);
    setNewTask({ campus: task.campus,program: task.program, price: task.price,duration: task.duration });
  };

  const updateTask = () => {
    axios.put(`/api/study/program/${editTask._id}`, newTask).then((response) => {
      const updatedTasks = tasks.map((task) =>
        task._id === response.data._id ? response.data : task
      );

      if(response.data.success){
        toast.success(response.data.success);
        axios.get('/api/study/program').then((response2) => setRecords(response2.data));
      }else{
        toast.error(response.data.error);
      }

      setTasks(updatedTasks);
      setNewTask({ campus: '',program: '', price: '',duration: '' });
      setEditTask(null);
    });
  };

  const handleInputChange = (e) => {
    setNewTask({ ...newTask, [e.target.name]: e.target.value });
  };

  const deleteTask = (id) => {
    
    axios.delete(`/api/study/program/${id}`).then((response) => {

      if(response.data.success){
        toast.success(response.data.success);
        axios.get('/api/study/program').then((response2) => setRecords(response2.data));
      }else{
        toast.error(response.data.error);
      }

      setTasks(tasks.filter((task) => task._id !== id));
    });
  };


  // DataTables

  useEffect(() => {
    // Fetch tasks when component mounts
    axios.get('/api/study/program').then((response) => setRecords(response.data));
    axios.get('/api/campus').then((response) => setRecordsCampus(response.data));
  }, []);

 

  const columns = [
    {
      name: 'Campus',
        selector: (row)=> row.campus_name,
        sortable: true
    },
    {
        name: 'Program',
        selector: row=> row.program,
        sortable: true
    },
    {
      name: 'Price (CAD)',
      selector: row=> row.price,
      sortable: true
    },
    { 
        name: 'Duration',
        selector: row=> row.duration,
        sortable: true
    },
    { name: 'Action', selector: function(row) {
        return (
          <>
          <i className='fa fa-edit mr-2 text-info' onClick={() => editTaskClick(row)}></i>
          <i className='fa fa-trash  text-danger' onClick={() => deleteTask(row._id)}></i>
          
          </>
        );
        
      } 
    }
    
    
];


const [campusList, setRecordsCampus] = useState([]);

const [records, setRecords] = useState([]);
  function handleFilter(event){
    const inputValue = event.target.value.toLowerCase();
    if(inputValue){
        const newData = records.filter(row=>{
            return (
              row.campus_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
              row.program.toLowerCase().includes(event.target.value.toLowerCase()) ||
              row.price.toLowerCase().includes(event.target.value.toLowerCase())  ||
              row.duration.toLowerCase().includes(event.target.value.toLowerCase()) 
            )
        });
        setRecords(newData);
    }else{
      axios.get('/api/study/program').then((response) => setRecords(response.data));
    }
  }


  return (
    <>
    
      <div className="wrapper">
        <AdminHeader />

        <AdminSidebar />

        <div className="content-wrapper">

          <section className="content mt-2">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-dark">
                  <div className="card-header">
                    
                    <h3 className="card-title">{editTask ? 'Edit Study Program' : 'Add Study Program'}</h3>

                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className='row'>

                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="inputRole">Campus</label>
                            <select
                              id="inputRole"
                              name="campus"
                              value={newTask.campus}
                              onChange={handleInputChange}
                              className="form-control custom-select"
                            >
                              <option value="">Select Campus</option>
                              {campusList.map((cam) => (
                                  <option value={cam._id}>{cam.campus}</option>
                              ))}
                              
                            </select>
                          </div>
                      </div>

                      <div className='col-md-6'>
                      <div className="form-group">
                        <label htmlFor="inputEmail">Program</label>
                        <input
                          type="text"
                          id="inputEmail"
                          name="program"
                          value={newTask.program}
                          placeholder='Study Program'
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>


                    <div className='col-md-6'>
                      <div className="form-group">
                        <label htmlFor="inputName">Price (CAD)</label>
                        <input
                          type="text"
                          id="inputName"
                          name="price"
                          value={newTask.price}
                          placeholder='Enter Price'
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>

              
                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="inputStatus">Duration</label>
                            <select
                              id="inputStatus"
                              name="duration"
                              value={newTask.duration}
                              onChange={handleInputChange}
                              className="form-control custom-select"
                            >
                              <option value="">Select Duration</option>
                              <option value="6 Months">6 Months</option>
                              <option value="1 Year">1 Year</option>
                              <option value="1.6 Year">1.6 Year</option>
                              <option value="2 Year">2 Year</option>
                              <option value="2.6 Year">2.6 Year</option>
                            </select>
                          </div>
                      </div>
                   

                      
                      <div className='col-md-12'>
                      {editTask ? (
                              <input
                                    type="submit"
                                    value="Update"
                                    onClick={updateTask}
                                    className="btn btn-success btn-sm"
                                  />
                          ) : (
                
                          <input
                                type="submit"
                                value="Add"
                                onClick={addTask}
                                className="btn btn-success btn-sm"
                              />
                        )}
                      </div>
                    </div>
                    

                    

                    
                  </div>
                </div>
              </div>
            </div>
            <br />
          </section>


          <section className="content">
            <div className="">
              <div className="row">
                <div className="col-12">
                  
                  <div className="card card-dark">
                    

                    <div className="card-header">
                      <h3 className="card-title">Study Program Listing</h3>
                      <input type='text' onChange={handleFilter} placeholder="Search..." style={{float:'right',width:'200px'}} className='form-control'/>
                    </div>

                    <div className="card-body">
                    <DataTable columns={columns} data={records} pagination></DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <br/>
        <AdminFooter />
      </div>
    </>
  );
};

export default AdminUsers;
