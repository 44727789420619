import React from "react";
import AdminLoginform from '../admin/AdminLoginform';
import AgentLoginform from '../admin/AgentLoginform';
import AdminFooter from "./AdminFooter";


const Login = (props) => {
  const UserType = props.utype;
  return (
    <>
         
         {UserType === 'admin' ? (
            <AdminLoginform />
          ) : (
            <AgentLoginform />
          )}
          
          <AdminFooter/>
    </>
  );
};

export default Login;
