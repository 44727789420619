import React from "react";
import Navebar from "./Navebar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const About = () => {



  return (
    <>
    <div className="main-wrapper website">
      <Navebar />
      <div className="pv-shop-area commonpage">        
                  <div className="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
                    <div className="container eduvibe-animated-shape">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-start">
                                    <div className="page-title">
                                        <h3 className="title">About Us</h3>
                                    </div>
                                    <nav className="edu-breadcrumb-nav">
                                        <ol className="edu-breadcrumb d-flex justify-content-start liststyle">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="separator"><i className="ri-arrow-drop-right-line"></i></li>
                                            <li className="breadcrumb-item active" aria-current="page">About Us</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                <div className="shape-image shape-image-1">
                                    <img src="/front/images/shapes/shape-11-07.png" alt="Shape Thumb" />
                                </div>
                                <div className="shape-image shape-image-2">
                                    <img src="/front/images/shapes/shape-01-02.png" alt="Shape Thumb" />
                                </div>
                                <div className="shape-image shape-image-3">
                                    <img src="/front/images/shapes/shape-03.png" alt="Shape Thumb" />
                                </div>
                                <div className="shape-image shape-image-4">
                                    <img src="/front/images/shapes/shape-13-12.png" alt="Shape Thumb" />
                                </div>
                                <div className="shape-image shape-image-5">
                                    <img src="/front/images/shapes/shape-36.png" alt="Shape Thumb" />
                                </div>
                                <div className="shape-image shape-image-6">
                                    <img src="/front/images/shapes/shape-05-07.png" alt="Shape Thumb" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="about-us" className="edu-about-area about-style-1 edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                           
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="section-title" >
                                        <span className="pre-title">About Us</span>
                                    </div>
                                 
                                    
                                    <p>Our mission is to enhance the lives of students and their communities. We offer a supportive environment that's globally recognized for empowering students to achieve their educational and career objectives.</p>

                                     <p> We are committed to:</p>  
                                        <ul>
                                        
                                            <li>Addressing your specific training needs.</li>
                                            <li>Fostering your career success.</li>
                                            <li>Boosting your productivity and employability.</li>
                                            <li>Delivering high-quality learning experiences.</li>
                                            <li>Excelling in career, business, and computer skills training.</li>
                                            <li>Creating a welcoming and supportive learning atmosphere.</li>
                                            <li>Staffing with compassionate, skilled professionals.</li>
                                        </ul>
                                        <p>Our unique Integrated Learning™ System, exclusive to the Academy of Learning Career College, has facilitated over a million successful course completions.</p>
                                        <p>Registered with the Ministry of Training, Colleges and Universities of Ontario, our campuses are recognized leaders in providing quality, self-paced learning. Since 1989 in Kingston and from 2020 in Mississauga West, we've led with innovative coaching methods, offering individualized student support.</p>
                                        <p>Our Integrated Learning System (ILS) readies students for the workforce in less time than traditional colleges. We offer diverse programs including diplomas and certifications in key sectors like Business Administration and Information Technology. Our flexible scheduling enables students to tailor their education to their lifestyle, often completing programs in under a year.</p>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                  
       
        </div>  
      
      <Footer />
      </div>
    </>
  );
};

export default About;
