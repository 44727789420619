import React, { useEffect,useState } from "react";
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import AdminFooter from "./AdminFooter";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useSelector } from 'react-redux';

const Dashboard = () => {
  const userData = useSelector((state) => state.user);

  const data = [];
  const [campusRecords, setCampusRecords] = useState(data);
  const [LoaRecords, setLoaRecords] = useState(data);
  const [CLoaRecords, setCLoaRecords] = useState(data);
  const [StudentRecords, setStudentRecords] = useState(data);
  const [ProgramRecords, setProgramRecords] = useState(data);
  const [ProgramUsers, setUsersRecords] = useState(data);
  const [userPermissionsData, setPermissionsData] = useState([]);
  const [userLOATravelLetter, setLOATravelLetter] = useState([]);
  

  useEffect(() => {

    if(userData && userData.role && userData.role.permissions ){
      setPermissionsData(userData.role.permissions);
    }

    console.log('userPermissionsData',userPermissionsData);

    const user_id = btoa(userData._id);
    const authtype = btoa(userData.authtype);

    axios.get(`/api/loa/${user_id}/${authtype}`).then((response) => {
      setLoaRecords(response.data);
    });
    axios.get(`/api/cloa/${user_id}/${authtype}`).then((response) => {
      setCLoaRecords(response.data);
    });
    axios.get(`/api/student/${user_id}/${authtype}`).then((response) => {
      setStudentRecords(response.data);
    });
    axios.get('/api/study/program').then((response) => setProgramRecords(response.data));
    axios.get('/api/campus').then((response) => setCampusRecords(response.data));
    axios.get('/api/adminuser').then((response) => setUsersRecords(response.data));

    axios.get(`/api/loatravelreq/${user_id}/${authtype}`).then((response) => setLOATravelLetter(response.data));

  }, [userData._id,userData.authtype,userData,userPermissionsData]);

  const totalCampus = campusRecords.length;
  const totalLoa = LoaRecords.length;
  const totalCLoa = CLoaRecords.length;
  const totalStudent = StudentRecords.length;
  const totalProgram = ProgramRecords.length;
  const totalUsers = ProgramUsers.length;
  const totalLOATravelLetter = userLOATravelLetter.length;
  
  
  const isValueInArray = (array, value) => array.includes(value);
  const permissions = {
    Dashboard: 'Dashboard',
    Role: 'Role',
    User: 'User',
    Campus: 'Campus',
    StudyProgram: 'Study Program',
    Loa: 'Loa',
    Cloa: 'Cloa',
    StudentManagement: 'Student Management',
    LoaPaymentHistory: 'Loa Payment',
    CloaPaymentHistory: 'Cloa Payment',
    StudentPaymentHistory: 'Student Payment',
    LOATravelLetter: 'LOA Travel Letter',
    CLOATravelLetter: 'CLOA Travel Letter',
    STUDENTTravelLetter: 'STUDENT Travel Letter',
    ActivityLog: 'Activity Log',
    StudentRegisterReq: 'Register Request',
  };
  
  return (
    <>
      <div className="wrapper">
        <AdminHeader />

        <AdminSidebar />

        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Dashboard</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <section className="content">
            <div className="container-fluid">
              <div className="row">

              {isValueInArray(userPermissionsData, permissions.Campus) && 
                <div className="col-lg-3 col-6">
                  <div className="small-box bg-info">
                    <div className="inner">
                      <h3>{totalCampus}</h3>

                      <p>Total Campus </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-bag"></i>
                    </div>
                    <Link to="/admin/Campus" className="small-box-footer">
                      More Info <i className="fas fa-arrow-circle-right"></i>
                    </Link>
                  </div>
                </div>
                }

                {isValueInArray(userPermissionsData, permissions.Loa) && 
                <div className="col-lg-3 col-6">
                  <div className="small-box bg-success">
                    <div className="inner">
                      <h3>
                        {totalLoa}
                      </h3>
                      <p>Total LOA</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-stats-bars"></i>
                    </div>
                    <Link to="/admin/loa" className="small-box-footer">
                      More Info <i className="fas fa-arrow-circle-right"></i>
                    </Link>
                  </div>
                </div>
                }

               {isValueInArray(userPermissionsData, permissions.Cloa) && 
                <div className="col-lg-3 col-6">
                  <div className="small-box bg-warning">
                    <div className="inner">
                      <h3>{totalCLoa}</h3>

                      <p>Total CLOA</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add"></i>
                    </div>
                    <Link to="/admin/cloa" className="small-box-footer">
                      More Info <i className="fas fa-arrow-circle-right"></i>
                    </Link>
                  </div>
                </div>
                }
                {isValueInArray(userPermissionsData, permissions.StudentManagement) &&
                <div className="col-lg-3 col-6">
                  <div className="small-box bg-danger">
                    <div className="inner">
                      <h3>{totalStudent}</h3>

                      <p>Students</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-pie-graph"></i>
                    </div>
                    <Link to="/admin/student" className="small-box-footer">
                      More Info <i className="fas fa-arrow-circle-right"></i>
                    </Link>
                  </div>
                </div>
                }
                {isValueInArray(userPermissionsData, permissions.StudyProgram) && 
                <div className="col-lg-3 col-6">
                  <div className="small-box bg-danger">
                    <div className="inner">
                      <h3>{totalProgram}</h3>

                      <p>Total Study Program</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-pie-graph"></i>
                    </div>
                    <Link to="/admin/study/program" className="small-box-footer">
                      More Info <i className="fas fa-arrow-circle-right"></i>
                    </Link>
                  </div>
                </div>
                }
                {isValueInArray(userPermissionsData, permissions.User) && 
                <div className="col-lg-3 col-6">
                  <div className="small-box bg-warning">
                    <div className="inner">
                      <h3>{totalUsers}</h3>

                      <p>Total Users</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add"></i>
                    </div>
                    <Link to="/admin/users" className="small-box-footer">
                      More Info <i className="fas fa-arrow-circle-right"></i>
                    </Link>
                  </div>
                </div>
              }

              {isValueInArray(userPermissionsData, permissions.LOATravelLetter) &&
                <div className="col-lg-3 col-6">
                  <div className="small-box bg-info">
                    <div className="inner">
                      <h3>{totalLOATravelLetter}</h3>

                      <p>Total Letter of Travel</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add"></i>
                    </div>
                    <Link to="/admin/loa-travel-letter" className="small-box-footer">
                      More Info <i className="fas fa-arrow-circle-right"></i>
                    </Link>
                  </div>
                </div>
              }

              </div>
            </div>
          </section>
        </div>

        <AdminFooter />
      </div>
    </>
  );
};

export default Dashboard;
