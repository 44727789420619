import React from 'react'
//import 'bootstrap/dist/css/bootstrap.css';
import { Link } from 'react-router-dom';
//import Logo from '../images/logo.png';


const AdminFooter = () => {
  return (
    <>
    <footer className="main-footer">
      <strong>Copyright &copy; 2024 <Link to="https://aolccollege.com">AOLCC Mississauga and Kingston </Link>.</strong> All Rights Reserved.
          
      <div className="float-right d-none d-sm-inline-block">
        {/* <b>Version</b> 2.0 */}
      </div>
    </footer>

    <aside className="control-sidebar control-sidebar-dark">
    </aside>
    </>
  )
}

export default AdminFooter