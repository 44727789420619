import React, { useEffect } from 'react'
//import 'bootstrap/dist/css/bootstrap.css';
import { Link } from 'react-router-dom';
import Logo from "./../images/loa-logo.png";


const Footer = () => {


    useEffect(() => {
    
      }, []);

  return (
    <>

        <footer className="eduvibe-footer-one edu-footer footer-style-default">
            <div className="footer-top">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="edu-footer-widget">
                                <div className="logo">
                                    <Link to="/">
                                        <img className="logo-light" src={Logo} alt="SiteLogo" />
                                    </Link>
                                </div>
                                <p className="description">Choosing your career path in life is a very important one, one that comes with a lot of time to determine what it is that you enjoy doing and what you will get the most out of.</p>
                                <ul className="social-share">
                                    <li><Link to="https://www.facebook.com/AcademyofLearningKingston" target='_blank'><i className="icon-Fb"></i></Link></li>
                                    <li><Link to="https://www.linkedin.com/company/academy-of-learning-college---kingston-campus/?trk=cp_followed_name_academy-of-learning-college---kingston-campus" target='_blank'><i className="icon-linkedin"></i></Link></li>
                                    <li><Link href="https://www.youtube.com/user/AcademyofLearning09/featured" target='_blank'><i className="icon-youtube"></i></Link></li>
                                    <li><Link to="https://twitter.com/aolccollege" target='_blank'><i className="icon-Twitter"></i></Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="edu-footer-widget explore-widget">
                                <h5 className="widget-title">MISSISSAUGA WEST</h5>
                                <div className="inner">
                                    <div className="widget-information">
                                        <ul className="information-list">
                                            <li><i className="icon-map-pin-line"></i>500 – 3660 Hurontario St Mississauga, ON. L5B 3C4 Canada </li>
                                            <li><i className="icon-phone-fill"></i><Link to="tel:(905)306-0666">(905) 306-0666</Link></li>
                                            <li><i className="icon-phone-fill"></i><Link to="tel:(647)948-5571">(647) 948-5571</Link></li>
                                            <li><i className="icon-mail-line-2"></i><Link  to="mailto:admissions.mississauga@aolccollege.ca">admissions.mississauga@aolccollege.ca</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="edu-footer-widget quick-link-widget">
                                <h5 className="widget-title">KINGSTON</h5>
                                <div className="inner">
                                    <div className="widget-information">
                                        <ul className="information-list">
                                            <li><i className="icon-map-pin-line"></i>1469 Princess St, Kingston, ON. K7M 3E9 Canada  </li>
                                            <li><i className="icon-phone-fill"></i><Link to="tel:(613)544-8973">(613) 544-8973</Link></li>
                                            <li><i className="icon-phone-fill"></i><Link to="tel:(619)544-8980">(619) 544-8980</Link></li>
                                            <li><i className="icon-mail-line-2"></i><Link  to="mailto:admissions.kingston@aolccollege.ca ">admissions.kingston@aolccollege.ca </Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                      
                    </div>

                </div>
            </div>
            <div className="copyright-area copyright-default">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner text-center">
                                <p><strong>Copyright &copy; 2024 <a href="https://aolccollege.com">AOLCC Mississauga and Kingston </a>.</strong> All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    
    </>
  )
}

export default Footer