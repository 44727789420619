import React, { useState, useEffect } from 'react'
import { useParams,Link } from "react-router-dom";
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import axios from 'axios';

const CloaView = () => {

const [dataList, setData] = useState([]);
const params = useParams();


useEffect(() => {
      axios.get(`/api/cloaverify/${params.id}`).then((response) => {
        setData(response.data);
      });
 }, [params.id]);


return (
<>
<div className="wrapper">
   <AdminHeader />
   <AdminSidebar />
   <div className="content-wrapper">
      <section className="content mt-2">
         <div className="row">
            <div className="col-md-12">
               <div className="card card-dark">
                  <div className="card-header">
                     <h3 className="card-title">View Details</h3>
                     <div className="card-tools">
                        <Link to={`/admin/cloa`} className="btn btn-tool" title="Collapse">Back</Link>
                     </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                        <div className="col-md-3">
                            <label className="" htmlFor="FirstName">
                            First Name
                            </label>
                        </div>
                        <div className="col-md-9">{dataList.firstName}</div>
                        <div className="col-md-3">
                            <label className="" htmlFor="LastName">
                            Last Name
                            </label>
                        </div>
                        <div className="col-md-9">
                            {dataList.lastName}
                        </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="Emaiil">
                            Email
                            </label>
                        </div>
                        <div className="col-md-9">
                            {dataList.email}
                        </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="DateOfBirth" style={{textTransform: 'none'}}>
                            Date of Birth
                            </label>
                        </div>
                        <div className="col-md-9"> {dataList.dob} </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="Address">
                            Address
                            </label>
                        </div>
                        <div className="col-md-9">
                            <p>                           {dataList.unit}<br />{dataList.street_no},<br />
                            {dataList.street_name},<br />{dataList.city},<br />{dataList.state},<br />{dataList.country},<br />{dataList.postal_code} 
                            </p>
                        </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="StudyProgram">
                            Study Program
                            </label>
                        </div>
                        <div className="col-md-9">
                        {dataList.study_program_id ? (
                            <p>{dataList.study_program_id.program}</p>
                        ) : (
                            <p>N/A</p>
                        )}
                        </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="PrepaidFees">
                            Prepaid Fees
                            </label>
                        </div>
                        <div className="col-md-9">
                            {dataList.prepaid_fee}
                        </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="StDate">
                            Start Date
                            </label>
                        </div>
                        <div className="col-md-9">
                            {dataList.st_date}
                        </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="StDate">
                            End Date
                            </label>
                        </div>
                        <div className="col-md-9">
                            {dataList.end_year_date}
                        </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="StDate">
                            Expiration Date
                            </label>
                        </div>
                        <div className="col-md-9">
                            {dataList.expire_date}
                        </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="StDate">
                            Payment Status
                            </label>
                        </div>
                        <div className="col-md-9">
                        {dataList.created_by ? (
                            
                            <p>{dataList.created_by.agent_payment_status}</p>
                            
                        ) : (
                            <p>N/A</p>
                        )}
                        </div>
                        
                    </div>
                  <div>
               </div>
            </div>
         </div>
      </div>
   </div>
        
      </section>
   </div>
   <br/>
   <AdminFooter />
</div>
</>
);
};
export default CloaView;