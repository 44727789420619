import React, { useState, useEffect } from "react";
import { useParams,Link,useNavigate  } from "react-router-dom";
import Navebar from "./Navebar";
import Footer from "./Footer";
import axios from 'axios';
import html2pdf from 'html2pdf.js'


const StudentTravelLetterFromFront = () => {
         
    const [dataList, setData] = useState([]);
    const params = useParams();
    const navigate = useNavigate();
    
    const downloadTravelLetter = (data) =>{
    
      if (data && data.entrollment_id && data.firstName) {
      const pdfname_receipt = data.entrollment_id+'_'+data.firstName+'_STUDENT_TRAVEL_LETTER.pdf';
      const element_receipt = document.getElementById('pdfContent');
    
      // Configuration options for html2pdf
      const options_receipt = {
        filename: pdfname_receipt,
        margin: 10,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };
    
      html2pdf().set(options_receipt).from(element_receipt).save();
      
      }
      
      // Navigate back to the previous page
     navigate(-1);
      
    }
    
    useEffect(() => {
        
        axios.get(`/api/student/${params.id}`).then((response) => {
          setData(response.data);
    
          console.log(response.data);
          
          // Set a timeout for 5 seconds after data fetching
          const timeoutId = setTimeout(() => {
              // Your code to run after 5 seconds
              downloadTravelLetter(response.data);
          }, 1000);
    
          // Cleanup function to clear the timeout in case the component unmounts
          return () => {
              clearTimeout(timeoutId);
          };
    
        });
    
        axios.put(`/api/studenttravelreqUpdate/${params.id}`, {travel_letter_downloads:'1'})
           .then((response) => {
             
           })
           .catch((error) => {
             console.error('Submission failed', error);
           });
    
    }, [params.id]);
    
  return (
    <>
     <div className="main-wrapper website">
    <Navebar />
    <div className="pv-shop-area commonpage">        
     
                  
                  <div className="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
                    <div className="container eduvibe-animated-shape">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-start">
                                    <div className="page-title">
                                        <h3 className="title">My Account</h3>
                                    </div>
                                    <nav className="edu-breadcrumb-nav">
                                        <ol className="edu-breadcrumb d-flex justify-content-start liststyle">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="separator"><i className="ri-arrow-drop-right-line"></i></li>
                                            <li className="breadcrumb-item active" aria-current="page">My Account</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
       
        </div>
    <div className="pv-shop-area bg-image bg-image--36 mt-5">        
       <div className="container eduvibe-animated-shape"> 
            
                <div className="row">   
                <div id="pdfContent">
                            


                            <div>
                             
                              <div className="page-wrapper">
                                <div className="page page-appendix-header">
                                  {/* <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0, padding: 15, paddingBottom: 15}} align="center">
                                    <tbody>
                                      <tr>
                                        <td width="33.333333%">
                                          <a href="javascript:void(0);" style={{outline: 'none'}} tabIndex={-1} target="_blank">
                                            <img align="center" alt="Logo" border={0} src="/front/img/Academt.jpeg" style={{textDecoration: 'none', msInterpolationMode: 'bicubic', height: 'auto', border: 0, width: 200}} width={200} />
                                          </a>
                                        </td>
                                       
                                      </tr>
                                    </tbody>
                                  </table> */}

                                  <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 15, paddingBottom: 0, paddingRight: 15}}>
                                    <tbody>
                                      <tr>
                                        <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                          <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0, marginBottom: '1em'}} align="center">
                                            <tbody><tr>
                                                <td>
                                                  <p style={{fontFamily: '"calibri", sans-serif', fontSize: 14, fontWeight: 400, lineHeight: '1.2', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17}}>
                                                  {dataList.travel_req_date}
                                                  </p>
                                                </td>
                                              </tr>
                                            </tbody></table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 15, paddingBottom: 0, paddingRight: 15}}>
                                    <tbody>
                                      <tr>
                                        <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                          <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0, marginBottom: '1em'}} align="center">
                                            <tbody><tr>
                                                <td>
                                                  <p style={{fontFamily: '"calibri", sans-serif', fontSize: 14, fontWeight: 400, lineHeight: '1.2', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17}}>
                                                  To Whom It May Concern:
                                                  </p>
                                                </td>
                                              </tr>
                                            </tbody></table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 15, paddingBottom: 0, paddingRight: 15}}>
                                    <tbody>
                                      <tr>
                                        <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                          <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0, marginBottom: '1em'}} align="center">
                                            <tbody><tr>
                                                <td>
                                                  <p style={{fontFamily: '"calibri", sans-serif', fontSize: 14, fontWeight: 400, lineHeight: '1.2', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17}}>
                                                  This letter is to confirm that {dataList.firstName+' '+dataList.lastName} with student Id {dataList.entrollment_id} has been accepted into the {dataList.study_program_id ? dataList.study_program_id.program : null} Diploma program at Academy of Learning Career College – Mississauga West Campus, at 3660 Hurontario St, Suite 500, Mississauga, ON L5B 3C4.
                                                  </p>
                                                </td>
                                              </tr>
                                            </tbody></table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 15, paddingBottom: 0, paddingRight: 15}}>
                                    <tbody>
                                      <tr>
                                        <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                          <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0, marginBottom: '1em'}} align="center">
                                            <tbody><tr>
                                                <td>
                                                  <p style={{fontFamily: '"calibri", sans-serif', fontSize: 14, fontWeight: 400, lineHeight: '1.2', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17}}>
                                                  Academy of Learning Career College is approved by the Health Ministry for the COVID - 19 Readiness plan. For this reason, we support this student's request that his travel to Canada is deemed essential.
                                                  </p>
                                                </td>
                                              </tr>
                                            </tbody></table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                 
                                  <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{padding: 15, paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0}}>
                                    <tbody>
                                      <tr>
                                        <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                          <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                                            <tbody><tr>
                                                <td>
                                                  <p style={{fontFamily: '"calibri", sans-serif', fontSize: 14, fontWeight: 400, lineHeight: '1.2', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}>
                                                  Students are required to check in and report to the College within 72 hours of their arrival in Canada to complete the final steps of their onboarding process and to be oriented for the commencement of their program. Students who fail to do so may be deemed to be in violation of college policy and may be reported to IRCC accordingly for further action.
                                                  </p>
                                                </td>
                                              </tr>
                                            </tbody></table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                          <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                                            <tbody><tr>
                                                <td>
                                                  <p style={{fontFamily: '"calibri", sans-serif', fontSize: 14, fontWeight: 400, lineHeight: '1.2', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, marginTop: 15}}>
                                                  Should you have any questions please contact us at 905-306-0666 or email us at 

                                                    <a href="javascript:void(0);" style={{fontWeight: 700, color: '#000', textDecoration: 'none'}} target="_blank"><strong> globaladmissions@aolccollege.ca.</strong></a>
                                                  </p>
                                                </td>
                                              </tr>
                                            </tbody></table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                          <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                                            <tbody><tr>
                                                <td>
                                                  <p style={{fontFamily: '"calibri", sans-serif', fontSize: 14, fontWeight: 400, lineHeight: '1.2', marginTop: 15, marginBottom: 15, wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17}}>
                                                  We look forward to welcoming our international students to Canada! 
                                                  </p>
                                                </td>
                                              </tr>
                                            </tbody></table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div>
                                    <p style={{paddingBottom: '3pt', paddingLeft: '0pt', textIndent: '0pt', textAlign: 'left', fontFamily: '"calibri", sans-serif', fontSize: 14, fontWeight: 400}}>
                                    Best Regards,</p>
                                    <p style={{paddingLeft: '6pt', textIndent: '0pt', textAlign: 'left'}}>
                                      <span> </span>
                                    </p>
                                    <table border={0} cellSpacing={0} cellPadding={0}>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <img width={159} height={59} src="/front/img/signature.jpg" />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <p />
                                    <p style={{paddingTop: '1pt', paddingLeft: '0pt', textIndent: '0pt', textAlign: 'left', fontFamily: '"calibri", sans-serif', fontSize: 14, fontWeight: 400}}>
                                      Tara Teeling</p>
                                    <p style={{paddingTop: '1pt', paddingLeft: '0pt', textIndent: '0pt', textAlign: 'left', fontFamily: '"calibri", sans-serif', fontSize: 14, fontWeight: 400}}>
                                      Campus Director</p>
                                    <p style={{paddingLeft: '0pt', textIndent: '0pt', lineHeight: '12pt', textAlign: 'left', fontFamily: '"calibri", sans-serif', fontSize: 14, fontWeight: 400}}>
                                      Academy of Learning Career
                                      College</p>
                                    <p style={{paddingLeft: '0pt', textIndent: '0pt', lineHeight: '12pt', textAlign: 'left', fontFamily: '"calibri", sans-serif', fontSize: 14, fontWeight: 400}}>
                                      Kingston &amp; Mississauga West Campuses</p>
                                    <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                                    {/* <p class="s6"
                                          style="padding-top: 5pt; padding-left: 0pt; text-indent: 0pt; font-style: italic; text-align: left;">
                                          Encl.: Letter of
                                          Acceptance</p> */}
                                  </div>
                                </div>
                             
                              </div>
                             
                             
                            </div>


                          </div>
                    
                </div>
                                     
            </div>
                  </div>
      <Footer />
      </div>
    </>
  );
    
};

export default StudentTravelLetterFromFront;
