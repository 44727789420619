import React, { useState, useEffect } from 'react'
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import toast from 'react-hot-toast';


const StudentRegisterReq = () => {

  // Delete Role
  const deleteTask = (id) => {

    const isConfirmed = window.confirm('Are you sure you want to delete this item?');
    if (isConfirmed) {
    
    axios.delete(`/api/studentregreq/${id}`).then((response) => {

      if(response.data.success){
        toast.success(response.data.success);
        axios.get('/api/studentregreq').then((response2) => setRecords(response2.data));
      }else{
        toast.error(response.data.error);
      }
    });

    }

  };


  // DataTables

  useEffect(() => {
    // Fetch tasks when component mounts
    axios.get('/api/studentregreq').then((response) => setRecords(response.data));
  }, []);

  const columns = [
    {
      name: 'First Name',
        selector: row=> row.fname,
        sortable: true
    },
    {
      name: 'Last Name',
        selector: row=> row.lname,
        sortable: true
    },
    {
      name: 'Email',
        selector: row=> row.email,
        sortable: true
    },
    {
      name: 'Phone',
        selector: row=> row.phone,
        sortable: true
    },
    { 
        name: 'Message',
        selector: row=> row.message,
        sortable: true
    },
    { name: 'Action', selector: function(row) {
        return (
          <>
          <i className='fa fa-trash  text-danger' onClick={() => deleteTask(row._id)}></i>
          
          </>
        );
        
      } 
    }
    
    
  ];


  const [records, setRecords] = useState([]);
  function handleFilter(event){
    const inputValue = event.target.value.toLowerCase();
    if(inputValue){
        const newData = records.filter(row=>{
            return (
              row.fname.toLowerCase().includes(event.target.value.toLowerCase()) ||
              row.lname.toLowerCase().includes(event.target.value.toLowerCase()) ||
              row.email.toLowerCase().includes(event.target.value.toLowerCase()) ||
              row.phone.toLowerCase().includes(event.target.value.toLowerCase()) ||
              row.message.toLowerCase().includes(event.target.value.toLowerCase()) 
            )
        });
        setRecords(newData);
    }else{
      axios.get('/api/studentregreq').then((response) => setRecords(response.data));
    }
  }


  return (
    <>
    
      <div className="wrapper">
        <AdminHeader />

        <AdminSidebar />

        <div className="content-wrapper mt-2">
          <section className="content">
            <div className="">
              <div className="row">
                <div className="col-12">
                   
                    <div className="card card-dark">
                    <div className="card-header">
                      <h3 className="card-title">Student Register Request Listing</h3>
                      <input type='text' onChange={handleFilter} placeholder="Search..." style={{float:'right',width:'200px'}} className='form-control'/>


                    </div>

                    <div className="card-body">
                    <DataTable columns={columns} data={records} pagination></DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <br/>
        <AdminFooter />
      </div>
    </>
  );
};

export default StudentRegisterReq;