import React, { useState, useEffect } from 'react'
import { useParams,Link,useLocation,useNavigate  } from "react-router-dom";
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import "./Studentpdf.css";
import axios from 'axios';
import numeral from 'numeral';

import html2pdf from 'html2pdf.js'
import toast from 'react-hot-toast';

const   StudentPdfView = () => {

const [dataList, setData] = useState([]);
const params = useParams();
const navigate = useNavigate();

const location = useLocation();
const searchParams = new URLSearchParams(location.search);
const pdfType = searchParams.get('pdfType');

const { REACT_APP_API_URL_LOCAL, REACT_APP_API_URL_PRODUCTION, REACT_APP_ENV_TYPE } = process.env;
const baseEndpoint = REACT_APP_ENV_TYPE === 'production' ? REACT_APP_API_URL_PRODUCTION : REACT_APP_API_URL_LOCAL;

const prefix = 'A';
const randomPart = Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
const invoiceNumber =  `${prefix}${randomPart}`;

  const downloadPdf = (data) =>{
    if (data && data.entrollment_id && data.firstName) {
    const pdfname = data.entrollment_id+'_'+data.firstName+'_Student_Form.pdf';
    const element = document.getElementById('pdfContent');

    // Configuration options for html2pdf
    const options = {
      filename: pdfname,
      margin: 10,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
  
    html2pdf().set(options).from(element).toPdf().get('pdf').then((pdf) => {
      const pdfData = btoa(pdf.output());
      fetch(`${baseEndpoint}/save-pdf`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({alldata:data,id:params.id,data: pdfData,pdfname: pdfname,type:'student',field_type:'form_field' }),
      })
        .then((response) => {
          if (response.ok) {
            toast.success('File saved on server');
            navigate(`/admin/student/verifydownload/${params.id}`);
            // send code here
            
          } else {
            toast.error('Failed to save file on server');
          }
        })
        .catch((error) => {
          toast.error('Error saving file on server');
          console.error('Error saving file on server:', error);
        });

     });
    // Navigate back to the previous page
     //navigate(-1);
    }else{

    }
  }

  const downloadPdfReceipt = (data) =>{

    if (data && data.entrollment_id && data.firstName) {
    const pdfname_receipt = data.entrollment_id+'_'+data.firstName+'_STUDENT_Receipt.pdf';
    const element_receipt = document.getElementById('loareceipt');
 
    // Configuration options for html2pdf
    const options_receipt = {
      filename: pdfname_receipt,
      margin: 10,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
  
    html2pdf().set(options_receipt).from(element_receipt).toPdf().get('pdf').then((pdf) => {
      const pdfData = btoa(pdf.output());
      fetch(`${baseEndpoint}/save-pdf`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ alldata:data,id:params.id,data: pdfData,pdfname: pdfname_receipt,type:'student',field_type:'receipt_field' }),
      })
      .then((response) => {
        if (response.ok) {
          // Second form download
          downloadPdf(data);
        } else {
          toast.error('Failed to save file on server');
        }
      })
      .catch((error) => {
        toast.error('Error saving file on server');
        console.error('Error saving file on server:', error);
      });

     });
    
    }
    
    // Navigate back to the previous page
    //navigate(-1);
    
  }

  useEffect(() => {
    let isMounted = true;  // Flag to check if the component is still mounted
  
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/student/${params.id}`);
        if (isMounted) {
          setData(response.data);
  
          // Set a timeout for 5 seconds after data fetching
          const timeoutId = setTimeout(() => {
            // Your code to run after 5 seconds
            if (pdfType === 'pdfform') {
              // downloadPdf(response.data);
            }
            if (pdfType === 'pdfreceipt') {
              downloadPdfReceipt(response.data);
            }
          }, 100);
  
  
          // Cleanup function to clear the timeout in case the component unmounts
          return () => {
            clearTimeout(timeoutId);
          };
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  
    // Cleanup function to set the isMounted flag to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, [params.id, pdfType, navigate]);


const formattedDate_issue_date = typeof dataList.issue_date === 'string' ? dataList.issue_date.replace(/-/g, '/') : '';
const formattedDate_created_date = typeof dataList.created_date === 'string' ? dataList.created_date.replace(/-/g, '/') : '';
const formattedDate_st_date = typeof dataList.st_date === 'string' ? dataList.st_date.replace(/-/g, '/') : '';
const formattedDate_end_year_date = typeof dataList.end_year_date === 'string' ? dataList.end_year_date.replace(/-/g, '/') : '';
const formattedDate_expire_date = typeof dataList.expire_date === 'string' ? dataList.expire_date.replace(/-/g, '/') : '';
const formattedDOB = typeof dataList.dob === 'string' ? dataList.dob.replace(/-/g, '/') : '';



const ucfirst = (str) => {
  if (str === undefined || str === null) {
    return ''; 
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const formatted_program_Fees = dataList.campus_id ? numeral(dataList.program_fees).format('0,0.00') : null;

const formatted_prepaid_fee = dataList.campus_id ? numeral(dataList.prepaid_fee).format('0,0.00') : null;
const formatted_remaining_fees = dataList.campus_id ? numeral(dataList.remaining_fees).format('0,0.00') : null;


return (
<>
<div className="wrapper">
   <AdminHeader />
   <AdminSidebar />
   <div className="content-wrapper">
      <section className="content mt-2">
         <div className="row">
            <div className="col-md-12">
               <div className="card card-dark">
                  <div className="card-header">
                     <h3 className="card-title">Welcome {ucfirst(dataList.firstName)}</h3>
                     <div className="card-tools">
                        <Link to={`/admin/student/edit/${params.id}`} className="btn btn-tool" title="Collapse">Back</Link>
                     </div>
                  </div>
                  <div className="card-body">
                    <div className='row'>
                      <div className='col-md-12 text-center'>
                        

                        {/* {pdfType === 'pdfform' ? ( */}
                        <div id="pdfContent">
                              


                        <div>
                          {/* Cover page  start */}
                          <div className="page-wrapper">
                            <div className="page page-appendix-header">
                              {/* Cover page start */}
                              <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0, paddingRight: 15, paddingLeft: 15, paddingTop: 0, paddingBottom: 15}} align="center">
                                <tbody>
                                  <tr>
                                    <td width="50%">
                                      <a href="javascript:void(0);" style={{outline: 'none'}} tabIndex={-1} target="_blank">
                                        <img align="center" alt="Logo" border={0} src="/front/img/Academt.jpeg" style={{textDecoration: 'none', msInterpolationMode: 'bicubic', height: 'auto', border: 0, width: 250, display: 'block',marginTop: '-50px'}} />
                                      </a>
                                    </td>
                                    <td className="header-text-wrap" width="25%" style={{textAlign: 'right', paddingBottom: 35}}>
                                      <h4 style={{margin: 0}}><strong>Mississauga West Campus</strong></h4>
                                      <p>500-3660 Hurontario Street </p>
                                      <p>Mississauga, ON L5B 3C4</p>
                                      <p>T: (905) 306-0666</p>
                                      <p>F: (647) 948-5571</p>
                                    </td>
                                    <td className="header-text-wrap" width="25%" style={{textAlign: 'right', paddingBottom: 35}}>
                                      <h4 style={{fontFamily: '"calibri", sans-serif', margin: 0}}><strong>Kingston Campus</strong></h4>
                                      <p>1469 Princess Street</p>
                                      <p>Kingston, ON K7M 3E9</p>
                                      <p>T: (613) 544-8973</p>
                                      <p>F: (613) 544-8980</p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{margin: 'auto'}}>
                                <tbody>
                                  <tr>
                                    <td style={{width: '100%', marginLeft: 0, marginRight: 0, marginTop: 30}}>
                                      <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginBottom: '10px', marginLeft: 0, marginRight: 0}} align="center">
                                        <tbody><tr>
                                            <td>
                                              <p style={{fontFamily: '"calibri", sans-serif', fontSize: 18, fontWeight: 700, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'center', msoLineHeightAlt: 17, textTransform: 'uppercase'}}>
                                                OFFER OF ADMISSION
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table className="offer-admission-table" style={{paddingTop: 15, paddingBottom: 15, width: '100%', marginLeft: 0, marginRight: 0}} border={0} cellPadding={0} cellSpacing={0} align="center">
                                <tbody>
                                  <tr>
                                    <td style={{width:'25%'}}>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        <strong>Date of Issue:</strong>
                                      </p>
                                    </td>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                      { formattedDate_issue_date }
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        <strong>Student Name:</strong>
                                      </p>
                                    </td>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        {dataList.firstName+' '+dataList.lastName}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        <strong>Date of Birth:</strong> <span style={{fontSize: 12, fontStyle: 'italic'}}> (YYYY/MM/DD)</span>
                                      </p>
                                    </td>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        {formattedDOB}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        <strong>Address:</strong>
                                      </p>
                                    </td>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                       {dataList.unit+' '+dataList.street_no+' '+dataList.street_name ? dataList.street_name : null} 
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        <strong>City:</strong>
                                      </p>
                                    </td>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                      
                                        {dataList.city ? dataList.city : null}
                                      </p>
                                    </td>
                                    <td style={{width:'18%'}}>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        <strong>Province/State:</strong>
                                      </p>
                                    </td>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                       
                                        {dataList.state ? dataList.state : null}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        <strong>Country:</strong>
                                      </p>
                                    </td>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        {dataList.country}
                                      </p>
                                    </td>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        <strong>Postal Code:</strong>
                                      </p>
                                    </td>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        {dataList.postal_code}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table style={{width: '100%', marginLeft: 0, marginTop: 10, marginBottom: 10, marginRight: 0}} border={0} cellPadding={0} cellSpacing={0} align="center">
                                <tbody>
                                  <tr>
                                    <td width={35}>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        Dear
                                      </p>
                                    </td>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        {dataList.firstName}<span>,</span>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 10, paddingBottom: 10, paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0}}>
                                <tbody>
                                  <tr>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, marginBottom: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17, color: '#000000'}}>
                                        Congratulations! Based on the assessment of your application, we are pleased to offer
                                        you admission into the
                                        following full-time program at Academy of Learning Career College.
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table style={{width: '100%', marginLeft: 'auto', marginRight: 'auto', paddingTop: 15, paddingBottom: 0}} border={0} cellPadding={0} cellSpacing={0} align="center">
                                <tbody><tr bgcolor="#DDDDDD">
                                    <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                      <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{margin: 'auto'}}>
                                        <tbody><tr>
                                            <td>
                                              <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 600, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'center', msoLineHeightAlt: 17, textTransform: 'uppercase'}}>
                                                <strong>PROGRAM INFORMATION</strong>
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                              <table style={{width: '100%', marginLeft: 0, marginRight: 0, paddingTop: 15, paddingBottom: 0}} border={0} cellPadding={0} cellSpacing={0} align="center">
                                <tbody>
                                  <tr>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        <strong>Program Name:</strong>
                                      </p>
                                    </td>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                      {dataList.study_program_id ? dataList.study_program_id.program : null}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        <strong>Campus Location:</strong>
                                      </p>
                                    </td>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                      {dataList.campus_id ? dataList.campus_id.campus : null} 
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        <strong>Level of Study:</strong>
                                      </p>
                                    </td>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        Diploma
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        <strong>Type of Program:</strong>
                                      </p>
                                    </td>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        Academic
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        <strong>Academic Status:</strong>
                                      </p>
                                    </td>
                                    <td>
                                      <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                                        Full-Time
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 10, paddingBottom: 10, paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0}}>
                                <tbody>
                                  <tr>
                                    <td>
                                    <br></br>
                                      <p style={{fontSize: '14px',fontFamily: '"calibri", sans-serif', fontSzAize: 17, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17, color: '#000000'}}>
                                        The Letter of Acceptance, together with this Offer of Admission, can be used to apply
                                        for your Study Permit and Student Visa through your local Canadian Embassy.<strong> Please note: This Offer of Admission MUST be accompanied by
                                          the detailed Letter of Acceptance in order to be valid. The detailed Letter of
                                          Acceptance shall contain the student's
                                          personal information including student number, the College's institutional
                                          information, and the program
                                          information.
                                        </strong>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 0, paddingBottom: 10, paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0}}>
                                <tbody>
                                  <tr>
                                    <td>
                                    <br></br>
                                      <p style={{fontSize: '14px',fontFamily: '"calibri", sans-serif',marginBottom:15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17, color: '#000000'}}>
                                        To confirm your acceptance of this offer and your seat, you must pay your program fees
                                        in full  <strong>prior</strong> to the start of
                                        your studies. For international students who are outside of Canada, program fees must be
                                        paid and received by the
                                        College in full <strong>prior</strong> to departure from your home country. The College
                                        reserves the right to withdraw your Letter of
                                        Acceptance and Offer of Admission if you do not confirm and pay your tution fees, as stipulated on this
                                        letter.
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 15, paddingBottom: 0, paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0}}>
                                <tbody>
                                  <tr bgcolor="#DDDDDD">
                                    <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                      <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                                        <tbody><tr>
                                            <td>
                                              <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 500, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'center', msoLineHeightAlt: 17, textTransform: 'uppercase'}}>
                                                <strong>INSTITUTION INFORMATION</strong>
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                      <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                                        <tbody><tr>
                                            <td>
                                              <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, marginTop: 15, marginBottom: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', msoLineHeightAlt: 17, textAlign: 'justify'}}>
                                                <span style={{fontWeight: 700}}><strong> Academy of Learning Career
                                                    College</strong></span> is registered with the Ministry of Colleges
                                                and Universities in the Province of
                                                Ontario. It is listed on Service Ontario as a Private Career College in good
                                                standing, and it is a Designated Learning
                                                Institution (DLI #O19303790322).
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 15, paddingBottom: 15, paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0}}>
                                <tbody>
                                  <tr bgcolor="#D2001A">
                                    <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                      <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                                        <tbody><tr>
                                            <td>
                                              <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 500, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'center', color: '#fff', msoLineHeightAlt: 17, textTransform: 'uppercase'}}>
                                                <strong>IMPORTANT NOTE TO STUDENTS </strong>
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                  <tr bgcolor="#FFE4C0">
                                    <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                      <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                                        <tbody><tr>
                                            <td>
                                              <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', msoLineHeightAlt: 17, textAlign: 'justify'}}>
                                                Please be reminded that you MUST have a valid study permit as issued to you
                                                by Immigration, Refugees and
                                                Citizenship Canada (IRCC), as well as have valid medical and health
                                                insurance in place for the entire duration of
                                                your study program. A copy of your valid study permit, as well as a copy of
                                                your valid medical and health insurance
                                                policy, must be provided to Academy of Learning Career College <span style={{fontWeight: 700}}><strong> before</strong> </span>you begin
                                                your studies.
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="footer-badge-wrap" style={{pageBreakAfter: 'always'}}>
                              <table width="100%" border={0} cellPadding={0} cellSpacing={0} align="center" style={{margin: 0, width: '100%', borderCollapse: 'collapse', borderWidth: 0, padding: 0}}>
                                <tbody>
                                  <tr>
                                    <td width="33.333333%" bgcolor="#aacd3f" style={{padding: 15}}>
                                    </td>
                                    <td width="33.333333%" bgcolor="#ea7023" style={{padding: 15}}>
                                    </td>
                                    <td width="33.333333%" bgcolor="#5ac7db" style={{padding: 15}}>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="page-wrapper">
                            <div className="page page-appendix-header">
                              <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0, padding: 15, paddingBottom: 15}} align="center">
                                <tbody>
                                  <tr>
                                    <td width="50%">
                                      <a href="javascript:void(0);" style={{outline: 'none'}} tabIndex={-1} target="_blank">
                                        <img align="center" alt="Logo" border={0} src="/front/img/Academt.jpeg" style={{textDecoration: 'none', msInterpolationMode: 'bicubic', height: 'auto', border: 0,  width: 250, display: 'block',marginTop: '-50px'}} width={200} />
                                      </a>
                                    </td>
                                    <td className="header-text-wrap" width="25%" style={{textAlign: 'right', paddingBottom: 35}}>
                                      <h4 style={{margin: 0}}><strong>Mississauga West Campus</strong></h4>
                                      <p>500-3660 Hurontario Street </p>
                                      <p>Mississauga, ON L5B 3C4</p>
                                      <p>T: (905) 306-0666</p>
                                      <p>F: (647) 948-5571</p>
                                    </td>
                                    <td className="header-text-wrap" width="25%" style={{textAlign: 'right', paddingBottom: 35}}>
                                      <h4 style={{margin: 0}}><strong>Kingston Campus</strong></h4>
                                      <p>1469 Princess Street</p>
                                      <p>Kingston, ON K7M 3E9</p>
                                      <p>T: (613) 544-8973</p>
                                      <p>F: (613) 544-8980</p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 0, paddingBottom: 0, width: '100%', marginLeft: 0, marginRight: 0}}>
                                <tbody>
                                  <tr bgcolor="#DDDDDD">
                                    <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                      <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                                        <tbody><tr>
                                            <td>
                                              <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 600, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'center', msoLineHeightAlt: 17, textTransform: 'uppercase'}}>
                                                <strong>IMPORTANT INFORMATION
                                                </strong>
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 15, paddingBottom: 0, paddingRight: 15}}>
                                <tbody>
                                  <tr>
                                    <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                      <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0, marginBottom: '1em'}} align="center">
                                        <tbody><tr>
                                            <td>
                                              <p style={{margin: '1em 0 1em', fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}>By confirming your acceptance of this offer, you acknowledge and accept that:</p>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table style={{width: '100%', marginLeft: 0, marginRight: 0}} border={0} cellPadding={0} cellSpacing={0} align="center">
                                <tbody>
                                  <tr>
                                    <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                      <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                                        <tbody><tr>
                                            <td style={{width: 20, position: 'relative', verticalAlign: 'top', paddingTop: 5}}><span style={{}}><img src="/front/img/solid-dot.png" style={{width: 5}} alt="Dots" /></span> </td>
                                            <td>
                                              <p style={{margin: '0 0 1em', fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}>The primary purpose of your travel to and stay in Canada is for full-time study, NOT for work or employment. Should you choose to pursue work or employment, you will work within the legal limits as permitted by IRCC, federal, provincial, and municipal law. You MUST place first priority on your studies.</p>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                                        <tbody><tr>
                                            <td style={{width: 20, margin: 'auto', position: 'relative', verticalAlign: 'top', paddingTop: 7}}> <span style={{}}><img src="/front/img/solid-dot.png" style={{width: 5}} alt="Dots" /></span></td>
                                            <td>
                                              <p style={{margin: '0 0 1em', fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}>You are 18 years of age or over and shall take full responsibility of your actions and decisions. You understand that upon arrival in Canada and the issuance of your Study Permit by IRCC, you must represent yourself for any student affairs as they relate to Academy of Learning Career College.</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{width: 20, margin: 'auto', position: 'relative', verticalAlign: 'top', paddingTop: 7}}><span style={{}}><img src="/front/img/solid-dot.png" style={{width: 5}} alt="Dots" /></span></td>
                                            <td>
                                              <p style={{margin: '0 0 1em', fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}>You have done your due diligence and research on Academy of Learning Career College. It is your responsibility to be fully aware of the location of the college. You understand that it is your responsibility to arrange and cover all costs for travel to and accommodation in Canada for the entire duration of your study period, including any scheduled breaks and study gaps as permissible by IRCC.</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{width: 20, margin: 'auto', position: 'relative', verticalAlign: 'top', paddingTop: 7}}><span style={{}}><img src="/front/img/solid-dot.png" style={{width: 5}} alt="Dots" /></span></td>
                                            <td>
                                              <p style={{margin: '0 0 1em', fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}>Academy of Learning Career College is a registered Private Career College and Designated Learning Institution (DLI). At present Private Career College programs on their own, are not eligible for the Post-Graduate Work Permit (PGWP) following the completion of the study program.</p>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table border={0} cellPadding={0} cellSpacing={0} style={{width: '96%', marginLeft: 20, marginRight: 0, paddingRight: 20}} align="center"> 
                                        <tbody><tr>
                                            <td style={{width: 20, margin: 'auto', position: 'relative', verticalAlign: 'top'}}> <span style={{}}>
                                                <img src="/front/img/circle-dot-2.png" style={{width: 5}} alt="Dots" />
                                              </span></td>
                                            <td>
                                              <p style={{margin: '0 0 1em', fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}><i>
                                                However, for students who are accepted into the Medical Office Administration diploma program, and
                                                who are intending to proceed to the Office Administration – Health Services (OFAH) program at
                                                Georgian College through the Transfer Credit Articulation Agreement, upon successful completion of
                                                both programs AND satisfaction of any other criteria as required by Immigration, Refugees and
                                                Citizenship Canada (IRCC), you may qualify for the Post-Graduate Work Permit (PGWP)</i></p>
                                            </td>
                                          </tr>
                                        </tbody></table>  
                                    </td>
                                  </tr>
                                </tbody>
                              </table>  
                              <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{padding: 15, paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0}}>
                                <tbody>
                                  <tr>
                                    <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                      <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                                        <tbody><tr>
                                            <td>
                                              <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}>
                                                Students are required to check in and report to the College within 72 hours
                                                of their arrival in Canada to complete
                                                the final steps of their onboarding process and to be oriented for the
                                                commencement of their program. Students
                                                who fail to do so may be deemed to be in violation of College policy and may
                                                be reported to IRCC accordingly for
                                                further action.
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                      <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                                        <tbody><tr>
                                            <td>
                                              <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, marginTop: 15}}>
                                                For inquiries regarding admission and enrolment, please contact us at
                                                <a href="javascript:void(0);" style={{fontWeight: 700, color: '#000', textDecoration: 'none'}} target="_blank"><strong> admissions@aolccollege.ca.</strong></a>
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                                      <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                                        <tbody><tr>
                                            <td>
                                              <p style={{fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400, lineHeight: '1.4', marginTop: 15, marginBottom: 15, wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17}}>
                                                We look forward to welcoming our international students to Canada and to our
                                                college!
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div>
                                <p style={{paddingBottom: '3pt', paddingLeft: '0pt', textIndent: '0pt', textAlign: 'left', fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400}}>
                                  Sincerely,</p>
                                <p style={{paddingLeft: '6pt', textIndent: '0pt', textAlign: 'left'}}>
                                  <span> </span>
                                </p>
                                <table border={0} cellSpacing={0} cellPadding={0}>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <img width={159} height={59} src="/front/img/signature.jpg" />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <p />
                              
                                <p style={{paddingLeft: '0pt', textIndent: '0pt', lineHeight: '12pt', textAlign: 'left', fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400}}>
                                  Tara Teeling</p>

                                  <p style={{paddingLeft: '0pt', textIndent: '0pt', lineHeight: '12pt', textAlign: 'left', fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400}}>
                                  Academic Director</p>


                                <p style={{paddingLeft: '0pt', textIndent: '0pt', lineHeight: '12pt', textAlign: 'left', fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400}}>
                                  Academy of Learning Career College</p>
                                <p style={{paddingLeft: '0pt', textIndent: '0pt', lineHeight: '12pt', textAlign: 'left', fontFamily: '"calibri", sans-serif', fontSize: 15, fontWeight: 400}}>
                                  Kingston &amp; Mississauga West</p>
                                <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                                {/* <p class="s6"
                                      style="padding-top: 5pt; padding-left: 0pt; text-indent: 0pt; font-style: italic; text-align: left;">
                                      Encl.: Letter of
                                      Acceptance</p> */}
                              </div>
                            </div>
                            <div className="footer-badge-wrap" style={{pageBreakAfter: 'always'}}>
                              <table width="100%" border={0} cellPadding={0} cellSpacing={0} align="center" style={{margin: 0, width: '100%', borderCollapse: 'collapse', borderWidth: 0, padding: 0}}>
                                <tbody>
                                  <tr>
                                    <td width="33.333333%" bgcolor="#aacd3f" style={{padding: 15}}>
                                    </td>
                                    <td width="33.333333%" bgcolor="#ea7023" style={{padding: 15}}>
                                    </td>
                                    <td width="33.333333%" bgcolor="#5ac7db" style={{padding: 15}}>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {/* Cover page  end */}
                          {/* Form content start */}
                          <div className="page-wrapper form-content-wrapper">
                            <div className="page page-appendix-header">
                              <table style={{width: '100%', position: 'absolute', top: 0, textAlign: 'center'}}>
                                <tbody><tr>
                                    <td>
                                      <img width={250} style={{margin: '-75px 0px 15px 0px'}} src="/front/img/Academt.jpeg" />
                                    </td>
                                  </tr>
                                </tbody></table>
                              {/*Letter of acceptance and date table*/}
                              <table style={{width: '100%', marginTop: 60, padding: 0, marginLeft: '0px', marginBottom: '-10px'}}>
                                <tbody><tr style={{padding: 0, margin: 0}}>
                                    <td style={{width: '50%'}}>
                                      <p style={{textTransform: 'uppercase', margin: 0, fontSize: '14.5px', fontWeight: 'bold', padding: 0}}>
                                        Letter of Acceptance</p>
                                    </td>
                                    <td style={{width: '50%', textAlign: 'right'}}>
                                      <p style={{margin: '5px 0'}}>
                                        <span style={{marginRight: 15, wordSpacing: 0, fontSize: '16px'}}>Date
                                          (YYYY/MM/DD):</span> <span style={{textDecoration: 'underline', fontSize: '16px'}}> {formattedDate_created_date} </span></p>
                                    </td>
                                  </tr>
                                </tbody></table>
                              {/*Personal Information Heading and it's table data*/}
                              <h4 style={{textTransform: 'uppercase', margin: '1px 0',fontSize:'12.5px'}}><strong>Personal Information</strong></h4>
                              <table className="outer">
                                <tbody><tr>
                                    <td style={{width: '50%'}}>
                                      <table className="nested">
                                        <tbody><tr style={{fontStyle: 'bold'}}>
                                            <td style={{width: '10%', textAlign: 'center', borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">1</span>
                                            </td>
                                            <td style={{width: '90%', paddingLeft: 5}}>
                                              <span style={{ fontWeight: 'bold' }} className="span1">First Name</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested">
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center'}}>
                                            </td>
                                            <td style={{width: '90%', paddingLeft: 5}}>
                                              <span className="span3">{ ucfirst(dataList.firstName) }</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '50%'}}>
                                      <table className="nested">
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '10%', textAlign: 'center', borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">2</span>
                                            </td>
                                            <td style={{width: '90%', paddingLeft: 5}}>
                                              <span className="span1">Last Name</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested">
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center'}}>
                                            </td>
                                            <td style={{width: '90%', paddingLeft: 5}}>
                                              <span className="span3">{ ucfirst(dataList.lastName) }</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{width: '50%'}}>
                                      <table className="nested">
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '10%', textAlign: 'center', borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">3</span>
                                            </td>
                                            <td style={{width: '90%', paddingLeft: 5}}>
                                              <span className="span1">Date of Birth (YYYY/MM/DD)</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested">
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center'}}>
                                            </td>
                                            <td style={{width: '90%', paddingLeft: 5}}>
                                              <span className="span3">{formattedDOB}</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '50%'}}>
                                      <table className="nested">
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '10%', textAlign: 'center', borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">4</span>
                                            </td>
                                            <td style={{width: '90%', paddingLeft: 5}}>
                                              <span className="span1">Student ID Number</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested">
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center'}}>
                                            </td>
                                            <td style={{width: '90%', paddingLeft: 5}}>
                                              <span className="span3">
                                                {dataList.entrollment_id}
                                               </span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                              {/*Student Mailing Address and it's table data*/}
                              <table className="outer" style={{borderTop: 0, borderBottom: 0}}>
                                <tbody><tr style={{borderBottom: 0}}>
                                    <td style={{borderTop: 0, borderBottom: 0, width: '100%'}}>
                                      <table className="nested">
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '5%', textAlign: 'center', borderRight: '0.1px solid black'}}>
                                              <span className="span1">5</span>
                                            </td>
                                            <td style={{borderRight: 0, width: '95%', paddingLeft: 5}}>
                                              <span className="span1">Student's Full Mailing Address</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                              <table className="outer" style={{borderTop: 0}}>
                                <tbody><tr>
                                    <td style={{width: '25%'}}>
                                      <table className="nested">
                                        <tbody><tr>
                                            <td style={{paddingLeft: 5}}> 
                                              <span style={{ fontWeight: 'bold' }} className="span2">PO Box</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span style={{paddingLeft: 3}}>-</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%'}}>
                                      <table className="nested">
                                        <tbody><tr>
                                            <td style={{paddingLeft: '5px'}}>
                                              <span style={{ fontWeight: 'bold' }} className="span2">Apt/Unit</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span className="span3" style={{paddingLeft: '3px'}}>
                                              { dataList.unit } 
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%'}}>
                                      <table className="nested">
                                        <tbody><tr>
                                            <td style={{paddingLeft: 5}}>
                                              <span style={{ fontWeight: 'bold' }} className="span2">Street No.</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span style={{paddingLeft: 3}}>{ dataList.street_no } </span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%'}}>
                                      <table className="nested">
                                        <tbody><tr>
                                            <td style={{paddingLeft: 5}}>
                                              <span style={{ fontWeight: 'bold' }} className="span2">Street Name</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span className="span3" style={{paddingLeft: 3}}>
                                              {dataList ? dataList.street_name : null}
                                                </span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{width: '25%'}}>
                                      <table className="nested">
                                        <tbody><tr>
                                            <td style={{paddingLeft: 5}}>
                                              <span style={{ fontWeight: 'bold' }} className="span2">City/Town</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span className="span3" style={{paddingLeft: 3}}>{dataList.city ? dataList.city : null} </span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%'}}>
                                      <table className="nested">
                                        <tbody><tr>
                                            <td style={{paddingLeft: 5}}>
                                              <span style={{ fontWeight: 'bold' }} className="span2">Country</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span className="span3" style={{paddingLeft: 3}}>{ dataList.country }</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%'}}>
                                      <table className="nested">
                                        <tbody>
                                        <tr>
                                            <td style={{paddingLeft: 5}}>
                                              <span style={{ fontWeight: 'bold' }} className="span2">Province/State</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span className="span3" style={{paddingLeft: 3}}>{ dataList.state } </span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%'}}>
                                      <table className="nested">
                                        <tbody><tr>
                                            <td style={{paddingLeft: 5}}>
                                              <span style={{ fontWeight: 'bold' }} className="span2">Postal Code</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span className="span3" style={{paddingLeft: 3}}>
                                              { dataList.postal_code } 
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                              {/*Institutional Information Heading and it's table data*/}
                              <h4 style={{textTransform: 'uppercase', margin: '1px 0',fontSize:'12.5px'}}><strong>Institutional Information</strong></h4>
                              <table className="outer">
                                <tbody><tr>
                                    <td style={{width: '50%'}}>
                                      <table className="nested">
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '10%', textAlign: 'center', borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">6</span>
                                            </td>
                                            <td style={{width: '96%', paddingLeft: 5}}>
                                              <span className="span1">Full Name of Institution</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested">
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center'}}>
                                            </td>
                                            <td style={{width: '96%', paddingLeft: 5}}>
                                              <span>Academy of Learning Career and Business College</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '50%'}}>
                                      <table className="nested">
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '10%', textAlign: 'center', borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">7</span>
                                            </td>
                                            <td style={{width: '96%', paddingLeft: 5}}>
                                              <span className="span1">Designated Learning Institution Number</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested">
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center'}}>
                                            </td>
                                            <td style={{width: '90%', paddingLeft: 5}}>
                                              <span>O19303790322</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                              <table className="outer" style={{borderTop: 0, borderBottom: 0}}>
                                <tbody><tr style={{borderBottom: 0}}>
                                    <td style={{width: '100%', borderTop: 0, borderBottom: 0}}>
                                      <table className="nested">
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '5%', textAlign: 'center', borderRight: '0.1px solid black'}}>
                                              <span className="span1">8</span>
                                            </td>
                                            <td style={{borderRight: 0, width: '95%', paddingLeft: 5}}>
                                              <span className="span1">Address of Institution</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                              <table className="outer" style={{borderBottom: 0}}>
                                <tbody><tr>
                                    <td style={{width: '25%', borderTop: 0, borderBottom: 0}}>
                                      <table className="nested">
                                        <tbody><tr>
                                            <td style={{paddingLeft: 5}}>
                                              <span style={{ fontWeight: 'bold' }} className="span2">PO Box</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span className="span3" style={{paddingLeft: 3}}>
                                              {dataList.campus_id ? (
                                                  <span>{dataList.campus_id.po_box}</span>
                                              ) : (
                                                  <span>N/A</span>
                                              )}
                                                </span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%'}}>
                                      <table className="nested">
                                        <tbody><tr>
                                            <td style={{paddingLeft: '5px'}}>
                                              <span style={{ fontWeight: 'bold' }} className="span2">Apt/Unit</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span className="span3" style={{paddingLeft: '3px'}}>
                                              {dataList.campus_id ? (
                                                 <span>{dataList.campus_id.unit}</span>
                                              ) : (
                                                  <span>N/A</span>
                                              )}
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%'}}>
                                      <table className="nested">
                                        <tbody><tr>
                                            <td style={{paddingLeft: 5}}>
                                              <span style={{ fontWeight: 'bold' }} className="span2">Street No.</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              
                                              {dataList.campus_id ? (
                                              <span className="span3" style={{paddingLeft: 3}}>
                                                  {dataList.campus_id.street_no}
                                               </span>
                                              ) : (
                                                <span className="span3" style={{paddingLeft: 3}}>
                                                    N/A
                                                 </span>
                                              )}
                                               
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%'}}>
                                      <table className="nested">
                                        <tbody><tr>
                                            <td style={{paddingLeft: 5}}>
                                              <span style={{ fontWeight: 'bold' }} className="span2">Street Name</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span className="span3" style={{paddingLeft: 3}}>
                                              
                                              {dataList.campus_id ? ucfirst(dataList.campus_id.street_name) : null}
                                                  
                                                </span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{width: '25%'}}>
                                      <table className="nested" style={{borderBottom: 0}}>
                                        <tbody><tr>
                                            <td style={{paddingLeft: 5}}>
                                              <span style={{ fontWeight: 'bold' }} className="span2">City/Town</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span className="span3" style={{paddingLeft: 3, borderBottom: 0}}>
                                              {dataList.campus_id ? ucfirst(dataList.campus_id.city) : null}
                                                </span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%'}}>
                                      <table className="nested" style={{borderBottom: 0}}>
                                        <tbody><tr>
                                            <td style={{paddingLeft: 5}}>
                                              <span style={{ fontWeight: 'bold' }} className="span2">Country</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span className="span3" style={{paddingLeft: 3, borderBottom: 0}}>
                                                {dataList.campus_id ? ucfirst(dataList.campus_id.country) : null}
                                                </span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%'}}>
                                      <table className="nested" style={{borderBottom: 0}}>
                                        <tbody><tr>
                                            <td style={{paddingLeft: 5}}>
                                              <span style={{ fontWeight: 'bold' }} className="span2">Province/State</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span className="span3" style={{paddingLeft: 3, borderBottom: 0}}>
                                              {dataList.campus_id ? ucfirst(dataList.campus_id.state) : null}
                                                </span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%'}}>
                                      <table className="nested" style={{borderBottom: 0}}>
                                        <tbody><tr>
                                            <td style={{paddingLeft: 5, borderBottom: 0}}>
                                              <span style={{ fontWeight: 'bold' }} className="span2">Postal Code</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span className="span3" style={{paddingLeft: 3, borderBottom: 0}}>
                                              {dataList.campus_id ? ucfirst(dataList.campus_id.postal_code) : null}
                                                </span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                              <table className="outer" style={{borderTop: 0}}>
                                <tbody>
                                  <tr>
                                    <td style={{width: '25%', borderTop: 0}}>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '20%', textAlign: 'center', borderTop: 0, borderRight: '0.1px solid black'}}>
                                              <span className="span1">9</span>
                                            </td>
                                            <td style={{width: '80%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1">Telephone Number</span>
                                            </td>
                                          </tr>
                                        
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%', borderTop: 0}}>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '20%', textAlign: 'center', borderTop: 0, borderRight: '0.1px solid black'}}>
                                              <span className="span1">10</span>
                                            </td>
                                            <td style={{width: '80%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1">Fax Number</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%', borderTop: 0}}>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '20%', textAlign: 'center', borderTop: 0, borderRight: '0.1px solid black'}}>
                                              <span className="span1">11</span>
                                            </td>
                                            <td style={{width: '80%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1">Website</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%', borderTop: 0}}>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '20%', textAlign: 'center', borderTop: 0, borderRight: '0.1px solid black'}}>
                                              <span className="span1">12</span>
                                            </td>
                                            <td style={{width: '80%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1">Type of School/Institution</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{width: '25%', borderTop: 0}}>
                                      <table className="nested" style={{paddingLeft: 5, borderTop: 0}}>
                                        <tbody><tr>
                                            <td style={{textAlign:'center'}}>
                                              <span>{dataList.campus_id ? dataList.campus_id.telephone_number : null}</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%', borderTop: 0,textAlign:'center'}}>
                                      <table className="nested" style={{paddingLeft: 5, borderTop: 0}}>
                                        <tbody><tr>
                                            <td>
                                              <span>{dataList.campus_id ? dataList.campus_id.fax_no : null}</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%', borderTop: 0}}>
                                      <table className="nested" style={{paddingLeft: 5, borderTop: 0}}>
                                        <tbody><tr>
                                            <td style={{textAlign:'center'}}>
                                              <span>www.aolccollege.com</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '25%', borderTop: 0}}>
                                      <table className="nested" style={{paddingLeft: 5, borderTop: 0}}>
                                        <tbody><tr>
                                            <td style={{width: '50%', borderTop: 0}}>
                                              <table className="inner_nested" style={{borderTop: 0}}>
                                                <tbody><tr>
                                                    <td style={{width: '30%', textAlign: 'center', borderTop: 0}}>
                                                      <img src="/front/img/Blank-Block-13-11-2021.png" width={9} />
                                                    </td>
                                                    <td style={{width: '70%', textAlign: 'left', borderTop: 0}}>
                                                      <span style={{marginBottom: 10}}>Public</span>
                                                    </td>
                                                  </tr>
                                                </tbody></table>
                                            </td>
                                            <td style={{width: '50%', borderTop: 0}}>
                                              <table className="inner_nested" style={{borderTop: 0}}>
                                                <tbody><tr>
                                                    <td style={{width: '30%', textAlign: 'center', borderTop: 0}}>
                                                      <img src="/front/img/Cross-Block-13-11-2021.png" width={9} />
                                                    </td>
                                                    <td style={{width: '70%', textAlign: 'left', borderTop: 0}}>
                                                      <span style={{marginBottom: 10}}>Private</span>
                                                    </td>
                                                  </tr>
                                                </tbody></table>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                              <table className="outer" style={{borderTop: 0}}>
                                <tbody><tr>
                                    <td style={{width: '50%', borderTop: 0}}>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0, borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">13</span>
                                            </td>
                                            <td style={{width: '32%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1">Name of Contact</span>
                                            </td>
                                            <td style={{width: '32%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1">Position</span>
                                            </td>
                                            <td style={{width: '32%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1">Telephone</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0}}>
                                            </td>
                                            <td style={{width: '32%', paddingLeft: 5, borderTop: 0}}>
                                              <span>{dataList.campus_id ? ucfirst(dataList.campus_id.name_of_contact) : null}</span>
                                            </td>
                                            <td style={{width: '32%', paddingLeft: 5, borderTop: 0}}>
                                              <span>{dataList.campus_id ? ucfirst(dataList.campus_id.position) : null}</span>
                                            </td>
                                            <td style={{width: '32%', paddingLeft: 5, borderTop: 0}}>
                                              <span>{dataList.campus_id ? ucfirst(dataList.campus_id.telephone_number) : null}</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '50%', borderTop: 0}}>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0, borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">14</span>
                                            </td>
                                            <td style={{width: '96%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1">Email</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0}}>
                                            </td>
                                            <td style={{width: '90%', paddingLeft: 3, borderTop: 0}}>
                                              <span>{dataList.campus_id ? dataList.campus_id.email : null}</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                              {/*Program Information Heading and it's table data*/}
                              <h4 style={{textTransform: 'uppercase', margin: '1px 0',fontSize:'12.5px'}}><strong>Program Information</strong></h4>
                              <table className="outer">
                                <tbody><tr>
                                    <td style={{width: '50%'}}>
                                      <table className="nested">
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '10%', textAlign: 'center', borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">15</span>
                                            </td>
                                            <td style={{width: '50%', paddingLeft: 5, borderRight: '0.1px solid black'}}>
                                              <span className="span1">Academic Status</span>
                                            </td>
                                            <td style={{width: '39%', paddingLeft: 5}}>
                                              <span className="span1">Instruction Hrs / Week</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested" style={{marginTop:'-1px'}}>
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center'}}>
                                            </td>
                                            <td style={{width: '50%', paddingLeft: 0,borderRight: '0.1px solid black',height: '31px'}}>
                                              <table className="inner_nested" style={{paddingLeft: 0,width:'100%'}}>
                                                <tbody><tr>
                                                    <td style={{width: '50%'}}>
                                                      <table className="inner_nested_in">
                                                        <tbody><tr>
                                                            <td style={{width: '30%', textAlign: 'center'}}>
                                                              <img src="/front/img/Cross-Block-13-11-2021.png" width={9} />
                                                            </td>
                                                            <td style={{width: '70%', textAlign: 'left'}}>
                                                              <span style={{marginBottom: 10}}>Full Time</span>
                                                            </td>
                                                          </tr>
                                                        </tbody></table>
                                                    </td>
                                                    <td style={{width: '50%'}}>
                                                      <table className="inner_nested_in">
                                                        <tbody><tr>
                                                            <td style={{width: '30%', textAlign: 'center'}}>
                                                              <img src="/front/img/Blank-Block-13-11-2021.png" width={9} />
                                                            </td>
                                                            <td style={{width: '70%', textAlign: 'left'}}>
                                                              <span style={{marginBottom: 10}}>Part Time</span>
                                                            </td>
                                                          </tr>
                                                        </tbody></table>
                                                    </td>
                                                  </tr>
                                                </tbody></table>
                                            </td>
                                            <td style={{width: '39%', paddingLeft: 5, textAlign: 'center'}}>
                                              <span>25</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '50%'}}>
                                      <table className="nested" style={{marginTop:'-5px'}}>
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '10%', textAlign: 'center', borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">16</span>
                                            </td>
                                            <td style={{width: '96%', paddingLeft: 5}}>
                                              <span className="span1">Field/Program of Study</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested" style={{}}>
                                        <tbody><tr style={{padding: '5px 0'}}>
                                            <td style={{width: '10%', textAlign: 'center'}}>
                                            </td>
                                            <td style={{width: '90%', paddingLeft: 3}}>
                                              <p style={{height: 1}} />
                                              <span className="span3">{dataList.study_program_id ? dataList.study_program_id.program : null}</span>
                                              <p style={{height: 5}} />
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                              <table className="outer" style={{borderTop: 0}}>
                                <tbody><tr>
                                    <td style={{width: '50%', borderTop: 0}}>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0, borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">17</span>
                                            </td>
                                            <td style={{width: '96%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1">Level of Study</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0}}>
                                            </td>
                                            <td style={{width: '90%', paddingLeft: 5, borderTop: 0}}>
                                              <p style={{height: 1}} />
                                              <span>Diploma</span>
                                              <p style={{height: 28}} />
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '50%', borderTop: 0}}>
                                      <table className="nested" style={{borderTop: 0,marginTop:'-15px'}}>
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0, borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">18</span>
                                            </td>
                                            <td style={{width: '96%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1">Type of Training Program</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0}}>
                                            </td>
                                            <td style={{width: '95%', paddingLeft: 0, borderTop: 0}}>
                                              <table className="inner_nested" style={{paddingLeft: 0, borderTop: 0, marginTop: '-2px'}}>
                                                <tbody><tr>
                                                    <td style={{width: '50%', borderTop: 0}}>
                                                      <table className="inner_nested_in" style={{borderTop: 0}}>
                                                        <tbody><tr>
                                                            <td style={{width: '35%', textAlign: 'center', borderTop: 0}}>
                                                              <img src="/front/img/Blank-Block-13-11-2021.png" width={9} style={{marginLeft:'4px'}}/>
                                                            </td>
                                                            <td style={{width: '65%', textAlign: 'left', borderTop: 0}}>
                                                              <span style={{marginBottom: 10}}>Vocational</span>
                                                            </td>
                                                          </tr>
                                                        </tbody></table>
                                                    </td>
                                                    <td style={{width: '50%', borderTop: 0}}>
                                                      <table className="inner_nested_in" style={{borderTop: 0, marginLeft: 9}}>
                                                        <tbody><tr>
                                                            <td style={{width: '35%', textAlign: 'center', borderTop: 0}}>
                                                              <img src="/front/img/Cross-Block-13-11-2021.png" width={9} />
                                                            </td>
                                                            <td style={{width: '65%', textAlign: 'left', borderTop: 0}}>
                                                              <span style={{marginBottom: 10}}>Academic</span>
                                                            </td>
                                                          </tr>
                                                        </tbody></table>
                                                    </td>
                                                  </tr>
                                                </tbody></table>
                                              <table className="inner_nested" style={{paddingLeft: 0, borderTop: 0, marginTop: '0px',marginLeft: '-5px'}}>
                                                <tbody><tr>
                                                    <td style={{width: '50%', borderTop: 0}}>
                                                      <table className="inner_nested_in" style={{borderTop: 0}}>
                                                        <tbody><tr>
                                                            <td style={{width: '35%', textAlign: 'center', borderTop: 0}}>
                                                              <img src="/front/img/Blank-Block-13-11-2021.png" width={9} />
                                                            </td>
                                                            <td style={{width: '65%', textAlign: 'left', borderTop: 0}}>
                                                              <span style={{marginBottom: 10}}>Professional</span>
                                                            </td>
                                                          </tr>
                                                        </tbody></table>
                                                    </td>
                                                    <td style={{width: '50%', borderTop: 0}}>
                                                      <table className="inner_nested_in" style={{borderTop: 0}}>
                                                        <tbody><tr>
                                                            <td style={{width: '35%', textAlign: 'center', borderTop: 0}}>
                                                              <img src="/front/img/Blank-Block-13-11-2021.png" width={9} />
                                                            </td>
                                                            <td style={{width: '35%', textAlign: 'left', borderTop: 0}}>
                                                              <span style={{marginBottom: 10}}>Other</span><span style={{display: 'block', width: 130, marginLeft: 30, borderBottom: '0.1px solid #000000'}} />
                                                            </td>
                                                          </tr>
                                                        </tbody></table>
                                                    </td>
                                                  </tr>
                                                </tbody></table>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                              <table className="outer" style={{borderTop: 0}}>
                                <tbody><tr>
                                    <td style={{width: '50%', borderTop: 0}}>
                                      <table className="nested" style={{borderTop: 0,marginTop:'-22px'}}>
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0, borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">19</span>
                                            </td>
                                            <td style={{width: '96%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1">Exchange Program</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested" style={{paddingTop: 2, paddingBottom: 12, borderTop: 0}}>
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0}}>
                                            </td>
                                            <td style={{width: '96%', paddingLeft: 0, borderTop: 0}}>
                                              <table className="inner_nested" style={{paddingLeft: 0, borderTop: 0}}>
                                                <tbody><tr>
                                                    <td style={{width: '50%', borderTop: 0}}>
                                                      <table className="inner_nested_in" style={{borderTop: 0}}>
                                                        <tbody><tr>
                                                            <td style={{width: '30%', textAlign: 'center', borderTop: 0}}>
                                                              <img src="/front/img/Blank-Block-13-11-2021.png" width={9} />
                                                            </td>
                                                            <td style={{width: '70%', textAlign: 'left', borderTop: 0}}>
                                                              <span style={{marginBottom: 10}}>Yes</span>
                                                            </td>
                                                          </tr>
                                                        </tbody></table>
                                                    </td>
                                                    <td style={{width: '50%', borderTop: 0}}>
                                                      <table className="inner_nested_in" style={{borderTop: 0}}>
                                                        <tbody><tr>
                                                            <td style={{width: '30%', textAlign: 'center', borderTop: 0}}>
                                                              <img src="/front/img/Cross-Block-13-11-2021.png" width={9} />
                                                            </td>
                                                            <td style={{width: '70%', textAlign: 'left', borderTop: 0}}>
                                                              <span style={{marginBottom: 10}}>No</span>
                                                            </td>
                                                          </tr>
                                                        </tbody></table>
                                                    </td>
                                                  </tr>
                                                </tbody></table>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested" style={{marginTop: '-8px'}}>
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0}}>
                                            </td>
                                            <td style={{width: '20%', paddingLeft: 5, borderTop: 0}}>
                                            </td>
                                            <td style={{width: '76%', paddingLeft: 5, borderTop: 0}}>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '50%', borderTop: 0}}>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '10%', marginTop: '-5px', textAlign: 'center', borderTop: 0, borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1" style={{}}>20</span>
                                            </td>
                                            <td style={{width: '96%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1" style={{}}>Estimated Tuition Fee for First Academic Year</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0}}>
                                            </td>
                                            <td style={{width: '20%', paddingLeft: 5, borderTop: 0}}>
                                              <span>${formatted_program_Fees}</span>
                                            </td>
                                            <td style={{width: '20%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span2">Fees Prepaid</span>
                                            </td>
                                            <td style={{width: '56%', paddingLeft: 5, borderTop: 0}}>
                                              <table className="inner_nested" style={{paddingLeft: 5, borderTop: 0}}>
                                                <tbody><tr>
                                                    <td style={{width: '50%', borderTop: 0}}>
                                                      <table className="inner_nested_in" style={{borderTop: 0}}>
                                                        <tbody><tr>
                                                            <td style={{width: '30%', textAlign: 'center', borderTop: 0}}>
                                                            
                                                            {dataList.prepaid_fee > 0 ? (
                                                              <img src="/front/img/Cross-Block-13-11-2021.png" width={9} />
                                                            ) : (
                                                              <img src="/front/img/Blank-Block-13-11-2021.png" width={9} />
                                                            )}
                                                              
                                                            </td>
                                                            <td style={{width: '70%', textAlign: 'left', borderTop: 0}}>
                                                              <span style={{marginBottom: 10}}>Yes</span>
                                                            </td>
                                                          </tr>
                                                        </tbody></table>
                                                    </td>
                                                    <td style={{width: '50%', borderTop: 0}}>
                                                      <table className="inner_nested_in" style={{borderTop: 0}}>
                                                        <tbody><tr>
                                                            <td style={{width: '30%', textAlign: 'center', borderTop: 0}}>
                                                              
                                                            {dataList.prepaid_fee <= 0 ? (
                                                              <img src="/front/img/Cross-Block-13-11-2021.png" width={9} />
                                                            ) : (
                                                              <img src="/front/img/Blank-Block-13-11-2021.png" width={9} />
                                                            )}
                                                            </td>
                                                            <td style={{width: '70%', textAlign: 'left', borderTop: 0}}>
                                                              <span style={{marginBottom: 10}}>No</span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody></table>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested" style={{marginTop: '-8px'}}>
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0}}>
                                            </td>
                                            <td style={{width: '20%', paddingLeft: 5, borderTop: 0}}>
                                            </td>
                                            <td style={{width: '76%', paddingLeft: 5, borderTop: 0,paddingTop:'5px'}}>
                                              <span className="span2">Amount of Prepaid Fees:</span><span>
                                              {dataList.prepaid_fee ? '$'+dataList.prepaid_fee : null}
                                                </span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                              <table className="outer" style={{borderTop: 0}}>
                                <tbody><tr>
                                    <td style={{width: '50%', borderTop: 0}}>
                                      <table className="nested" style={{borderTop: 0,marginTop:'0px'}}>
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0, borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">21</span>
                                            </td>
                                            <td style={{width: '96%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1">Scholarship/Teaching Assistantship/Other Financial Aid</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0}}>
                                            </td>
                                            <td style={{width: '95%', paddingLeft: 0, borderTop: 0}}>
                                              <table className="inner_nested" style={{paddingLeft: 0, borderTop: 0, marginTop: '-3px'}}>
                                                <tbody><tr>
                                                    <td style={{width: '30%', borderTop: 0}}>
                                                      <table className="inner_nested_in" style={{borderTop: 0}}>
                                                        <tbody><tr>
                                                            <td style={{width: '30%', textAlign: 'center', borderTop: 0}}>
                                                              <img src="/front/img/Blank-Block-13-11-2021.png" width={9} style={{marginBottom:0}}/>
                                                            </td>
                                                            <td style={{width: '70%', textAlign: 'left', borderTop: 0}}>
                                                              <span style={{marginBottom: 10}}>Yes</span>
                                                            </td>
                                                          </tr>
                                                        </tbody></table>
                                                    </td>
                                                    <td style={{width: '70%', borderTop: 0}}>
                                                      <table className="inner_nested_in" style={{borderTop: 0}}>
                                                        <tbody><tr>
                                                            <td style={{width: '70%', textAlign: 'left', borderTop: 0}}>
                                                              <span style={{marginBottom: 10}}>Specify:</span><span style={{display: 'block', width: 135, marginLeft: 41, borderBottom: '0.1px solid #000000'}} />
                                                            </td>
                                                          </tr>
                                                        </tbody></table>
                                                    </td>
                                                  </tr>
                                                </tbody></table>
                                              <table className="inner_nested" style={{paddingLeft: 0, borderTop: 0, marginBottom: '8px'}}>
                                                <tbody><tr>
                                                    <td style={{width: '30%', borderTop: 0}}>
                                                      <table className="inner_nested_in" style={{borderTop: 0}}>
                                                        <tbody><tr>
                                                            <td style={{width: '30%', textAlign: 'center', borderTop: 0}}>
                                                              <img src="/front/img/Cross-Block-13-11-2021.png" width={9} />
                                                            </td>
                                                            <td style={{width: '70%', textAlign: 'left', borderTop: 0}}>
                                                              <span style={{marginBottom: 10}}>No</span>
                                                            </td>
                                                          </tr>
                                                        </tbody></table>
                                                    </td>
                                                    <td style={{width: '70%', borderTop: 0}}>
                                                      <table className="inner_nested_in" style={{borderTop: 0}}>
                                                        <tbody><tr>
                                                            <td />
                                                          </tr>
                                                        </tbody></table>
                                                    </td>
                                                  </tr>
                                                </tbody></table>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '50%', borderTop: 0}}>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0, borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">22</span>
                                            </td>
                                            <td style={{width: '96%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1">Internship/Work Practicum</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0}}>
                                            </td>
                                            <td style={{width: '95%', paddingLeft: 0, borderTop: 0}}>
                                              <table className="inner_nested" style={{paddingLeft: 0, borderTop: 0, marginTop: '-3px'}}>
                                                <tbody><tr>
                                                    <td style={{width: '30%', borderTop: 0}}>
                                                      <table className="inner_nested_in" style={{borderTop: 0}}>
                                                        <tbody><tr>
                                                            <td style={{width: '30%', textAlign: 'center', borderTop: 0}}>
                                                              <img src="/front/img/Blank-Block-13-11-2021.png" width={9} style={{marginBottom: 0}} />
                                                            </td>
                                                            <td style={{width: '70%', textAlign: 'left', borderTop: 0}}>
                                                              <span style={{marginBottom: 10}}>Yes</span>
                                                            </td>
                                                          </tr>
                                                        </tbody></table>
                                                    </td>
                                                    <td style={{width: '70%', borderTop: 0}}>
                                                      <table className="inner_nested_in" style={{borderTop: 0}}>
                                                        <tbody><tr>
                                                            <td style={{width: '70%', textAlign: 'left', borderTop: 0}}>
                                                              <span style={{marginBottom: 10}}>Length:</span><span style={{display: 'block', width: 135, marginLeft: 72, borderBottom: '0.1px solid #000000'}} />
                                                            </td>
                                                          </tr>
                                                        </tbody></table>
                                                    </td>
                                                  </tr>
                                                </tbody></table>
                                              <table className="inner_nested" style={{paddingLeft: 0, borderTop: 0,marginBottom: '8px'}}>
                                                <tbody><tr>
                                                    <td style={{width: '30%', borderTop: 0}}>
                                                      <table className="inner_nested_in" style={{borderTop: 0}}>
                                                        <tbody><tr>
                                                            <td style={{width: '30%', textAlign: 'center', borderTop: 0}}>
                                                              <img src="/front/img/Cross-Block-13-11-2021.png" width={9} />
                                                            </td>
                                                            <td style={{width: '70%', textAlign: 'left', borderTop: 0}}>
                                                              <span style={{marginBottom: 10}}>No</span>
                                                            </td>
                                                          </tr>
                                                        </tbody></table>
                                                    </td>
                                                    <td style={{width: '70%', borderTop: 0}}>
                                                      <table className="inner_nested_in" style={{borderTop: 0}}>
                                                        <tbody><tr>
                                                            <td style={{width: '70%', textAlign: 'left', borderTop: 0}}>
                                                              <span style={{marginBottom: 10}}>Field of
                                                                work:</span><span style={{display: 'block', width: 135, marginLeft: 73, borderBottom: '0.1px solid #000000'}} />
                                                            </td>
                                                          </tr>
                                                        </tbody></table>
                                                    </td>
                                                  </tr>
                                                </tbody></table>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                              <table className="outer" style={{borderTop: 0}}>
                                <tbody><tr>
                                    <td style={{width: '50%', borderTop: 0}}>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '5%', textAlign: 'center', borderTop: 0, borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">23</span>
                                            </td>
                                            <td style={{width: '95%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1">Conditions of Acceptance Specified as Clearly as Possible
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr>
                                            <td style={{width: '5%', textAlign: 'center', borderTop: 0}}>
                                            </td>
                                            <td style={{width: '95%', paddingLeft: 5, borderTop: 0}}>
                                              <br />
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                              <table className="outer" style={{borderTop: 0}}>
                                <tbody><tr>
                                    <td style={{width: '50%', borderTop: 0}}>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0, borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">24</span>
                                            </td>
                                            <td style={{width: '96%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1">Length of Program (YYYY/MM/DD)</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0}}>
                                            </td>
                                            <td style={{width: '96%', paddingLeft: 5, borderTop: 0}}>
                                              <span>Start Date: {formattedDate_st_date}</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0}}>
                                            </td>
                                            <td style={{width: '96%', paddingLeft: 5, borderTop: 0}}>
                                              <span>Completion Date: {formattedDate_end_year_date}</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0}}>
                                            </td>
                                            <td style={{width: '96%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span2">
                                                Or Minimum <span style={{display: 'inline-block', width: 75, marginLeft: 8, marginRight: 5}} />
                                                <span className="span2" style={{marginLeft: 5, marginRight: 5}}>Year of
                                                  Full-Time Studies</span>
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                    <td style={{width: '50%', borderTop: 0}}>
                                      <table className="nested" style={{borderTop: 0,marginTop:'-11px'}}>
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0, borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">25</span>
                                            </td>
                                            <td style={{width: '96%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1">Expiration of Letter of Acceptance (YYYY/MM/DD)</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr>
                                            <td style={{width: '10%', textAlign: 'center', borderTop: 0}}>
                                            </td>
                                            <td style={{width: '90%', paddingLeft: 5, borderTop: 0}}>
                                              <p style={{height: 2}} />
                                              <span>{formattedDate_expire_date}</span>
                                              <p style={{height: 23}} />
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                              <table className="outer" style={{borderTop: 0}}>
                                <tbody>
                                  <tr>
                                    <td style={{width: '50%', borderTop: 0}}>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr style={{fontWeight: 'bold'}}>
                                            <td style={{width: '5%', textAlign: 'center', borderTop: 0, borderRight: '0.1px solid black', borderBottom: '0.1px solid black'}}>
                                              <span className="span1">26</span>
                                            </td>
                                            <td style={{width: '95%', paddingLeft: 5, borderTop: 0}}>
                                              <span className="span1">Other Relevant Information</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                      <table className="nested" style={{borderTop: 0}}>
                                        <tbody><tr>
                                            <td style={{width: '5%', textAlign: 'center', borderTop: 0}}>
                                            </td>
                                            <td style={{width: '95%', paddingLeft: 5, paddingBottom: 2, borderTop: 0}}>
                                              <span>{dataList.other_info ? dataList.other_info : null}</span>
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                              {/*Footer contents*/}
                              <table style={{width: '100%'}} align="center">
                                <tbody><tr>
                                    <td style={{width: '50%', textAlign: 'left'}}>
                                      <p>
                                        <span style={{marginRight: 0, fontSize: '13.5px',paddingLeft: 0}}>Printed
                                          name of institution representative:
                                        </span>
                                        <span style={{paddingLeft: 3, fontSize: '13.5px'}}>
                                          Tara Teeling
                                        </span>
                                      </p>
                                    </td>
                                    <td style={{width: '50%', textAlign: 'left'}}>
                                      <table style={{width: '100%'}}>
                                        <tbody><tr>
                                            <td>
                                              <p style={{fontSize: '13.5px', float: 'left',paddingTop:'15px'}}>
                                                Signature of institution representative:</p>
                                              <img width={120} style={{float: 'right'}} src="/front/img/signature.jpg" />
                                            </td>
                                          </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                </tbody></table>
                            </div>
                            <div className="footer-badge-wrap" style={{ marginTop: '20px',position: 'relative',pageBreakAfter: dataList.study_program_id && dataList.study_program_id.program === "Medical Office Administration" ? 'always' : 'auto' }}>  
                              <table width="100%" border={0} cellPadding={0} cellSpacing={0} align="center" style={{margin: 0, width: '100%', borderCollapse: 'collapse', borderWidth: 0, padding: 0}}>
                                <tbody>
                                  <tr>
                                    <td width="33.333333%" bgcolor="#aacd3f" style={{padding: 15}}>
                                    </td>
                                    <td width="33.333333%" bgcolor="#ea7023" style={{padding: 15}}>
                                    </td>
                                    <td width="33.333333%" bgcolor="#5ac7db" style={{padding: 15}}>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            
                          </div>
                         
                          {/* Form content  end */}
                          {/* Appendix start */}
                          {dataList.study_program_id && dataList.study_program_id.program === "Medical Office Administration" ? (
                            <>
                              <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0, paddingLeft: 33, paddingRight: 50, paddingTop: 10, paddingBottom: 70}} align="center">
                            <tbody>
                              <tr>
                                <td width="33%" align="left">
                                  <a href="javascript:void(0);" style={{outline: 'none'}} tabIndex={-1} target="_blank">
                                    <img align="left" alt="Logo" border={0} src="/front/img/Aolcc-new.jpg" style={{textDecoration: 'none', msInterpolationMode: 'bicubic', height: 'auto', border: 0, display: 'block'}} width="159px" />
                                  </a>
                                </td>
                                <td width="50%" style={{textAlign: 'center'}} align="center">
                                </td>
                                <td width="33%" align="right">
                                 
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="page page-appendix-header">
                            <div>
                              <h4 style={{textAlign: 'center', marginTop: 0, textTransform: 'uppercase', fontFamily: '"calibri", sans-serif'}}>
                                <strong>APPENDIX 1</strong></h4>
                              <h4 style={{textAlign: 'center', textTransform: 'uppercase', fontFamily: '"calibri", sans-serif', marginBottom: 0}}>
                                <strong>TRANSFER CREDIT ARTICULATION</strong></h4>
                            </div>
                            <div>
                              <h4 style={{textAlign: 'justify', fontFamily: '"calibri", sans-serif',fontSize:'14px'}}>
                                <strong>Medical Office Administration Diploma at Academy of Learning Career College </strong>
                              </h4>
                              <h4 style={{textAlign: 'justify', fontFamily: '"calibri", sans-serif',fontSize:'14px'}}>
                                Students who have successfully completed the one-year <strong> Medical Office Administration Diploma
                                  with a minimum
                                  cumulative average of 75% </strong> will be given exemption from specific courses of the 2-year
                                Office Administration –
                                Health Services Diploma from Georgian College as indicated below.
                              </h4>
                              <h4 style={{textAlign: 'justify', fontFamily: '"calibri", sans-serif',fontSize:'14px'}}>
                                Diploma Pathway Progression for Georgian College Office Administration – Health Services for
                                students who
                                have completed Academy of Learning Career College Medical Office Administration
                              </h4>
                            </div>
                            <div className="table-box table-box-pathway">
                                  <table cellPadding={0} cellSpacing={0}>
                                    <tbody>
                                      <tr className="heading" style={{fontFamily: '"calibri", sans-serif'}}>
                                        <td style={{fontFamily: '"calibri", sans-serif'}}><strong>Georgian
                                            College
                                            Course Code</strong></td>
                                        <td style={{fontFamily: '"calibri", sans-serif'}}><strong>Georgian College Course</strong></td>
                                        <td style={{fontFamily: '"calibri", sans-serif'}}><strong>AOLCC Graduate
                                            Pathway
                                            Semester</strong>
                                        </td>
                                        <td style={{fontFamily: '"calibri", sans-serif'}}><strong>Georgian
                                            College
                                            Standard
                                            Semester</strong></td>
                                      </tr>
                                      <tr className="item">
                                        <td>COMP 1113 </td>
                                        <td>Business Documentation and Text Processing </td>
                                        <td>EXEMPT</td>
                                        <td>1</td>
                                      </tr>
                                      <tr className="item">
                                        <td>COMP 1013</td>
                                        <td>Advanced Business Documentation</td>
                                        <td>EXEMPT</td>
                                        <td>2</td>
                                      </tr>
                                      <tr className="item">
                                        <td>OFAD 1001</td>
                                        <td>Office Procedures and Strategies </td>
                                        <td>EXEMPT</td>
                                        <td>1</td>
                                      </tr>
                                      <tr className="item">
                                        <td>OFAD 1022 </td>
                                        <td>Professional Development and Employability 1</td>
                                        <td>EXEMPT</td>
                                        <td>1</td>
                                      </tr>
                                      <tr className="item">
                                        <td>OFAD 1023</td>
                                        <td>Professional Development and Employability 2 </td>
                                        <td>EXEMPT</td>
                                        <td>2</td>
                                      </tr>
                                      <tr className="item">
                                        <td>OFAD 1006</td>
                                        <td>Introduction to Medical Terminology</td>
                                        <td>EXEMPT</td>
                                        <td>2</td>
                                      </tr>
                                      <tr className="item">
                                        <td>MATH 1034</td>
                                        <td>Office Math Applications</td>
                                        <td>EXEMPT</td>
                                        <td>2</td>
                                      </tr>
                                      <tr className="item">
                                        <td>OFAD 1007</td>
                                        <td>Computer Applications 1 for the Office</td>
                                        <td>EXEMPT</td>
                                        <td>1</td>
                                      </tr>
                                      <tr className="item">
                                        <td>OFAD 1020</td>
                                        <td>Computer Applications 2 for the Office</td>
                                        <td>EXEMPT</td>
                                        <td>2</td>
                                      </tr>
                                      <tr className="item">
                                        <td>OFAD 2000</td>
                                        <td>Anatomy for Health Services 1</td>
                                        <td>EXEMPT</td>
                                        <td>3</td>
                                      </tr>
                                      <tr className="item">
                                        <td>OFAD 2031</td>
                                        <td>Health Services Transcription</td>
                                        <td>EXEMPT</td>
                                        <td>3</td>
                                      </tr>
                                      <tr className="item">
                                        <td>OFAD 2006</td>
                                        <td>Anatomy for Health Services 2</td>
                                        <td>EXEMPT</td>
                                        <td>4</td>
                                      </tr>
                                      <tr className="item">
                                        <td>OFAD 2014 </td>
                                        <td>Bookkeeping for the Office Professional</td>
                                        <td>EXEMPT</td>
                                        <td>3</td>
                                      </tr>
                                    </tbody>
                                  </table>

                            </div>
                          </div>
                          <div className="page page-appendix-header" style={{paddingTop: 0}}>
                            <div>
                              <h4 style={{textAlign: 'justify', fontFamily: '"calibri", sans-serif',fontSize:'14px'}}>In total, Academy of Learning Career College
                                students who successfully complete the Medical Office Administration will receive credit for up to
                                12 courses towards the Office Administration – Health Services Diploma at Georgian College, which
                                provides them
                                exemption from Semester 1 and 2 of Georgian College Office Administration - Health Services Diploma.
                              </h4>
                              <h4 style={{textAlign: 'justify', fontFamily: '"calibri", sans-serif',fontSize:'14px'}}>
                                Students will pursue the following courses towards Semester 3 and 4 at Georgian College for completing the 2-year Office Administration – Health Services.
                              </h4>
                            </div>
                            <div className="table-box">
                              <table cellPadding={0} cellSpacing={0}>
                                <tbody>
                                  <tr className="heading" style={{fontFamily: '"calibri", sans-serif'}}>
                                    <td style={{fontFamily: '"calibri", sans-serif'}}><strong>Georgian
                                        College
                                        Course Code</strong></td>
                                    <td style={{fontFamily: '"calibri", sans-serif'}}><strong>Georgian College Course</strong></td>
                                    <td style={{fontFamily: '"calibri", sans-serif'}}><strong>AOLCC Graduate
                                        Pathway
                                        Semester</strong></td>
                                    <td style={{fontFamily: '"calibri", sans-serif'}}><strong>Georgian
                                            College Standard Semester</strong></td>
                                  </tr>
                                  <tr className="item">
                                    <td>COMM*</td>
                                    <td>Communications Course</td>
                                    <td style={{textAlign: 'center'}}>3</td>
                                    <td>1</td>
                                  </tr>
                                  <tr className="item">
                                    <td>GNED*</td>
                                    <td>General Education course</td>
                                    <td style={{textAlign: 'center'}}>3</td>
                                    <td>1</td>
                                  </tr>
                                  <tr className="item">
                                    <td>GNED*</td>
                                    <td>General Education course</td>
                                    <td style={{textAlign: 'center'}}>3</td>
                                    <td>3</td>
                                  </tr>
                                  <tr className="item">
                                    <td>GNED*</td>
                                    <td>General Education course</td>
                                    <td style={{textAlign: 'center'}}>3</td>
                                    <td>4</td>
                                  </tr>
                                  <tr className="item">
                                    <td>COMP 2026</td>
                                    <td>Integrated Office Simulations</td>
                                    <td style={{textAlign: 'center'}}>3</td>
                                    <td>3</td>
                                  </tr>
                                  <tr className="item">
                                    <td>OFAD 2025</td>
                                    <td>Electronic Medical Office</td>
                                    <td style={{textAlign: 'center'}}>3</td>
                                    <td>3</td>
                                  </tr>
                                  <tr className="item">
                                    <td>COMP 1114</td>
                                    <td>Multimedia Communications</td>
                                    <td style={{textAlign: 'center'}}>4</td>
                                    <td>2</td>
                                  </tr>
                                  <tr className="item">
                                    <td>COMP 2027</td>
                                    <td>Computerized Administrative Simulations</td>
                                    <td style={{textAlign: 'center'}}>4</td>
                                    <td>4</td>
                                  </tr>
                                  <tr className="item">
                                    <td>OFAD 2032</td>
                                    <td>Health Services Practical Experience and Clinical Applications</td>
                                    <td style={{textAlign: 'center'}}>4</td>
                                    <td>4</td>
                                  </tr>
                                  <tr className="item">
                                    <td>OFAD 2026</td>
                                    <td>OHIP for Health Services</td>
                                    <td style={{textAlign: 'center'}}>4</td>
                                    <td>4</td>
                                  </tr>
                                  <tr className="item">
                                    <td>OFAD 2033 </td>
                                    <td>Foundations in Health Records and Information Management</td>
                                    <td style={{textAlign: 'center'}}>4</td>
                                    <td>4</td>
                                  </tr>
                                  <tr className="item">
                                    <td>COMM*</td>
                                    <td>Communications Course</td>
                                    <td style={{textAlign: 'center'}}>4</td>
                                    <td>2</td>
                                  </tr>
                                </tbody>
                              </table>

                            </div>
                          </div>
                          <div className="footer-badge-wrap" style={{position: 'relative',marginTop: '30px'}}>
                            <table width="100%" border={0} cellPadding={0} cellSpacing={0} align="center" style={{margin: 0, width: '100%', borderCollapse: 'collapse', borderWidth: 0, padding: 0}}>
                              <tbody>
                                <tr>
                                  <td width="33.333333%" bgcolor="#aacd3f" style={{padding: 15}}>
                                  </td>
                                  <td width="33.333333%" bgcolor="#ea7023" style={{padding: 15}}>
                                  </td>
                                  <td width="33.333333%" bgcolor="#5ac7db" style={{padding: 15}}>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                            </>

                            ) : (
                              null
                            )}
                          {/* Appendix end */}
                        </div>













                      </div>
                          {/* ) : (
                            null
                        )} */}

                {/* {pdfType === 'pdfreceipt' ? ( */}

                    <div id="loareceipt">
                                            
                    <table style={{width: '100%', top: 10}}>
                                  <tr>
                                      
                                      <td style={{width: '40%',textAlign: 'left'}}>
                                      <img width={230} src="/front/img/Academt.jpeg" />
                                      </td>
                                      <td style={{width: '60%', textAlign: 'right'}}>
                                      <h3 style={{fontSize: '18px',fontWeight: '800'}}>FULL TIME INTERNATIONAL FEE RECEIPT</h3>
                                      </td>
                                  </tr>
                                </table>


                                <table style={{width: '100%', top: 10}}>
                                  <tr>
                                      
                                      <td style={{width: '45%', padding: 10, textAlign: 'left'}}>
                                      <p>Student ID: {dataList.entrollment_id ? dataList.entrollment_id : null}</p>
                                      <p>Program: {dataList.study_program_id ? dataList.study_program_id.program : null}</p>
                                      <p>Start Date: {formattedDate_st_date}</p>
                                      <p>Expected Completion Date: {formattedDate_end_year_date}</p>
                                      <br/>
                                      <p>{dataList.firstName+' '+dataList.lastName}</p>

                                      {dataList.unit ? dataList.unit : null} {dataList.street_no ? dataList.street_no : null},
                                            
                                      <p>{dataList.street_name ? dataList.street_name : null},</p>
                                      <p>{dataList.city ? dataList.city : null}, {dataList.state ? dataList.state : null}, {dataList.country ? dataList.country : null}, {dataList.postal_code ? dataList.postal_code : null}</p>
                                      </td>

                                      <td style={{width: '55%', padding: 5, textAlign: 'justify',float:'right'}}>
                                      <h4 style={{margin: 0}}><strong>Mississauga West Campus</strong></h4>
                                      <p>500-3660 Hurontario Street </p>
                                      <p>Mississauga, ON L5B 3C4</p>
                                      <p>T: (905) 306-0666</p>
                                      <p>F: (647) 948-5571</p> <br/>

                                      <h4 style={{fontFamily: '"calibri", sans-serif', margin: 0}}><strong>Kingston Campus</strong></h4>
                                      <p>1469 Princess Street</p>
                                      <p>Kingston, ON K7M 3E9</p>
                                      <p>T: (613) 544-8973</p>
                                      <p>F: (613) 544-8980</p><br/>

                                      <p>DLI# O19303790322</p>
                                      <p>Invoice: {invoiceNumber}   </p>
                                      <p>Date:  {formattedDate_issue_date}</p>

                                      
                                      </td>
                                  </tr>
                                </table>


                                <table style={{tableLayout: 'fixed', width: '100%', borderCollapse: 'collapse', borderTop: 0}}>
                                      <tbody>
                                      <tr style={{padding: '10px 0'}}>
                                            <td style={{width: '25%', padding: 5, borderBottom: '1px solid black', textAlign: 'left'}}>
                                            <h4 style={{margin: 0}}><strong>Description</strong></h4>
                                            </td>
                                            <td style={{width: '75%', padding: 5 , borderBottom: '1px solid black', textAlign: 'left'}}>
                                            {/* <span>{dataList.firstName+' '+dataList.lastName}</span> */}
                                            <h4 style={{margin: 0}}><strong>Amount</strong></h4>
                                            </td>
                                      </tr>
                                      <tr style={{padding: '10px 0'}}>
                                            <td style={{width: '25%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                            <span>Total Fees:</span>
                                            </td>
                                            <td style={{width: '75%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                            <span>
                                            ${formatted_program_Fees}
                                            </span>
                                            </td>
                                      </tr>
                                      <tr style={{padding: '10px 0'}}>
                                            <td style={{width: '25%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                            <span>Amount Receivable:</span>
                                            </td>
                                            <td style={{width: '75%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                            <span>${formatted_program_Fees}</span>
                                            </td>
                                      </tr>
                                      <tr style={{padding: '10px 0'}}>
                                            <td style={{width: '25%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                            <span>Payment Received:</span>
                                            </td>
                                            <td style={{width: '75%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                            <span>${formatted_prepaid_fee}</span>
                                            </td>
                                      </tr>
                                      <tr style={{padding: '10px 0'}}>
                                            <td style={{width: '25%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                            <span>Total Payment Received:</span>
                                            </td>
                                            <td style={{width: '75%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                            <span>${formatted_prepaid_fee}</span>
                                            </td>
                                      </tr>

                                      <tr style={{padding: '10px 0'}}>
                                            <td style={{width: '25%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                            <span>Balance Due:</span>
                                            </td>
                                            <td style={{width: '75%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                            <span>${formatted_remaining_fees}</span>
                                            </td>
                                      </tr>

                                      </tbody>
                                      </table>
                                <table style={{width: '100%', marginTop: '10px'}}>
                                  <tr>
                                      <td style={{width: '100%', padding: 10,paddingBottom:0, textAlign: 'left',marginBottom:'10px'}}>
                                      <h4 style={{marginTop: 50,marginBottom: 5,float:'left',color:'grey'}}><strong>PAYMENT OPTIONS: </strong>The following payment options are accepted:</h4>

                                      <p style={{textAlign: 'right',float:'right'}}><i>All fees listed are in Canadian Dollars</i></p><br/>
                                      </td>
                                  </tr>
                                </table>
                                <table style={{width: '100%', top: 10,color:'grey'}}>
                                  <tr>
                                      
                                      <td style={{width: '50%', padding: 10, textAlign: 'left'}}>
                                      <h4 style={{margin: 0,color:'grey'}}><strong>Outside Canada</strong></h4>
                                      <h4 style={{margin: 0,color:'grey'}}><strong>Option 1:  Bank Wire Transfer </strong></h4>
                                      <p style={{fontSize: '12px'}}>Beneficiary Name: Academy of Learning Career College</p>
                                      <p style={{fontSize: '11px'}}>Beneficiary Address: 500-3660 Hurontario St, Mississauga ON. L5B 3C4</p>
                                      <p style={{fontSize: '12px'}}>Bank Name: Bank of Montreal</p>
                                      <p style={{fontSize: '12px'}}>Branch Address: 1841 Walkers Line, Burlington, ON, Canada L7M 0H6</p>
                                      <p style={{fontSize: '12px'}}>Bank Telephone: (905) 336-3375</p>
                                      <p style={{fontSize: '12px'}}>Bank Code: 001</p>
                                      <p style={{fontSize: '12px'}}>Transit Number: 38712</p>
                                      <p style={{fontSize: '12px'}}>Account Number: 1983318</p>
                                      <p style={{fontSize: '12px'}}>SWIFT BIC Code: BOFMCAM2</p>
                                      <p style={{height:'131px'}}></p>
                                    
                                      </td>

                                      <td style={{width: '50%', padding: 10, textAlign: 'left'}}>
                                      <h4 style={{margin: 0,color:'grey'}}><strong>In Canada</strong></h4>
                                      <h6 style={{margin: 0,color:'grey'}}><strong>Option 1:  Interac </strong></h6>
                                      <p style={{fontSize: '12px'}}>All e-transfer payments to be directed to pay.m@aolccollege.ca</p><br/>
                                      <h6 style={{margin: 0,color:'grey'}}><strong>Option 2: Cheques and Bank Draft</strong></h6>
                                      <p style={{fontSize: '12px'}}>Please make all cheques and bank drafts payable to “Academy of Learning Career College“. Ensure that your Student ID Number as noted on your Letter of Acceptance from Academy of Learning Career College, is clearly noted on the cheque or bank draft.</p>
                                      <p style={{fontSize: '12px'}}>For cheques, please ensure that there are sufficient funds in the bank account from which the cheque will draw funds. Students shall be responsible for all fees and expenses that result from insufficient funds or related reasons.</p>
                                      <br/>
                                      <h6 style={{margin: 0,color:'grey'}}><strong>Option 3: Credit Card</strong></h6>
                                      <p style={{fontSize: '12px'}}>Payments may be made by Credit Card at the Campus. An additional service fee will apply. This fee is billed at 2.75% of the fee payment to cover the fees of the service provider.</p>
                                      
                                      </td>
                                  </tr>
                                </table>

                      </div>
                    {/* ) : (
                        null
                    )} */}
                      </div>
                       
                    </div>
                  <div>
               </div>
            </div>
         </div>
      </div>
   </div>
        
      </section>
   </div>
   <br/>
   <AdminFooter />
</div>
</>
);
};
export default StudentPdfView;