import React, { useState } from "react";
import Logo from '../images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import Navebar from "./Navebar";
import Footer from "./Footer";

const Signup = () => {

    const navigate  = useNavigate();
    const [user, setUser] = useState({
        name:'',
        email:'',
        phone:'',
        work:'',
        authtype:'user',
        password:'',
        cpassword:''
    });

    //Handle inputs data
    let name, value;
    const handleInputs = (e) => {
        console.log(e);
        name = e.target.name;
        value = e.target.value;

        //User's all the data using spread operator
        setUser({...user, [name]:value});
    }

    const PostData = async (e) => {
        e.preventDefault();
        const {name, email, phone, work, authtype, password, cpassword} = user;
        const res = await fetch("/register", {
            method: "POST",
            headers: {
              "Content-Type":"application/json"
            },
            body: JSON.stringify({
              name, email, phone, work, authtype, password, cpassword
            })
        });

        const data = await res.json();
        if(data.status === 422 || !data){
            window.alert("Invalid registration");
            console.log("Invalid registration");
        }else{
            window.alert("Registration successfull");
            console.log("Registration successfull");
            navigate('/login');
        }

    }

  return (
    <>
      <div className="main-wrapper website">
      <Navebar />
      <div className="pv-shop-area">        
       <div className="container eduvibe-animated-shape commonpage"> 
                    <h3>Register Today</h3>
                    <p>Fill in the data below.</p>
                    <form className="requires-validation">
                    <div className="row">

                    <div className="col-md-8">

                    <div className="row">
                      <div className="col-md-12"> 
                      <div className="form-group">
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Full Name"
                          value={user.name}
                          onChange={handleInputs}
                          required
                        />
                        </div>
                      </div>

                      <div className="col-md-12">
                      <div className="form-group">
                        <input
                          className="form-control form-control-lg"
                          type="email"
                          name="email"
                          id="email"
                          placeholder="E-mail Address"
                          value={user.email}
                          onChange={handleInputs}
                          required
                        />
                        </div>
                      </div>

                      <div className="col-md-12">
                      <div className="form-group">
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Phone"
                          value={user.phone}
                          onChange={handleInputs}
                          required
                        />
                        </div>
                      </div>
                      <div className="col-md-12">
                      <div className="form-group">
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="work"
                          id="work"
                          placeholder="Work"
                          value={user.work}
                          onChange={handleInputs}
                          required
                        />
                        <input
                          type="hidden"
                          name="authtype"
                          id="authtype"
                          value="user"
                          onChange={handleInputs}
                          required
                        />
                       </div>
                        
                      </div>

                      <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control form-control-lg"
                          type="password"
                          name="password"
                          id="password"
                          placeholder="Password"
                          value={user.password}
                          onChange={handleInputs}
                          required
                        />
                      </div>  
                      </div>
                      <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control form-control-lg"
                          type="password"
                          name="cpassword"
                          id="cpassword"
                          placeholder="Confirm Password"
                          value={user.cpassword}
                          onChange={handleInputs}
                          required
                        />
                       </div>
                      </div>

                      

                      <div className="form-button">
                      <div className="form-group">
                        <button
                          name="signup"
                          id="signup"
                          type="submit"
                          onClick={PostData}
                          className="btn btn-primary"
                        >
                          Register
                        </button>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div className="col-md-4">

                        <img src={Logo} alt="Logo" width={180}/><br/>

                        <Link to="/login">Already Registered</Link>


                        </div>
                      </div>
                    </form>
                  
                </div>
              </div>
      </div>
      <Footer />
    </>
  );
};

export default Signup;
