import React, { useState, useEffect } from 'react'
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


const LoaListing = () => {

  const userData = useSelector((state) => state.user);
  
  // Delete Role
  const deleteTask = (id) => {

    let user_id = btoa(userData._id);
    let authtype = btoa(userData.authtype);

    const isConfirmed = window.confirm('Are you sure you want to delete this item?');
    if (isConfirmed) {
    
    axios.delete(`/api/loa/${id}`).then((response) => {

      if(response.data.success){
        toast.success(response.data.success);
        axios.get(`/api/loa/${user_id}/${authtype}`).then((response2) => setRecords(response2.data));
      }else{
        toast.error(response.data.error);
      }
    });

    }

  };

  // DataTables

  useEffect(() => {
    const user_id = btoa(userData._id);
    const authtype = btoa(userData.authtype);
    // Fetch tasks when component mounts
    axios.get(`/api/loa/${user_id}/${authtype}`).then((response) => {
      setRecords(response.data);
    });
    

  }, [userData._id,userData.authtype]);

 
  const columns = [
    {
      name: 'Student ID',
      selector: (row) => row.entrollment_id,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.firstName + ' ' + row.lastName,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: 'Study Program',
      selector: (row) => row.program,
      sortable: true,
    },
    {
      name: 'Agent Name',
      selector: (row) => row.created_by,
      sortable: true,
    },
    {
      name: 'Prepaid Fees',
      selector: (row) => row.prepaid_fee,
      sortable: true,
    },
    { 
      name: 'Start Date',
      selector: row=> row.st_date,
      sortable: true
    },
    { 
      name: 'Issue Date',
      selector: row=> row.created_date,
      sortable: true
    },
    // { 
    //   name: 'Date',
    //   selector: row=> row.created_date,
    //   sortable: true
    // },
    {
      name: 'Action',
      width: '150px',
      selector: function (row) {
        const renderAction = () => {
          return (
            <>
              <Link to={`/admin/loa/edit/${row._id}`}>
                <i className='fa fa-edit mr-2 text-info'></i>
              </Link>
              <Link to={`/admin/loa/view/${row._id}`}>
                <i className='fa fa-eye mr-2 text-warning'></i>
              </Link>
              <Link to={`/admin/loa/paymentlist/${row._id}`}>
                <i className='fas fa-money-check-alt mr-2 text-primary'></i>
              </Link>
  
              {row.travel_letter_downloads < 1 && row.travel_letter_approved_rejected === 1 && (
                <Link to={`/admin/loa/travel-letter-download/${row._id}`}>
                  <i className='fas fa-download mr-2 text-success'></i>
                </Link>
              )}

<Link title="Documents Upload" to={`/admin/loa/documents/upload/${row._id}`}><i className='fa fa-upload mr-2 text-success'></i></Link>
  
              <i className='fa fa-trash text-danger' onClick={() => deleteTask(row._id)}></i>
            </>
          );
        };
  
        return renderAction();
      },
    },
  ];
  


const [records, setRecords] = useState([]);
function handleFilter(event){

  const inputValue = event.target.value.toLowerCase();
  if(inputValue){
    const newData = records.filter(row => {
      const lowercaseValue = event && event.target && event.target.value ? event.target.value.toLowerCase() : '';
      const prepaidFeeIncludes = row.prepaid_fee && typeof row.prepaid_fee === 'number' && row.prepaid_fee.toString().toLowerCase().includes(lowercaseValue);
      
      return (
        row.email.toLowerCase().includes(lowercaseValue) ||
        (row.entrollment_id && row.entrollment_id.toString().toLowerCase().includes(lowercaseValue)) ||
        row.firstName.toLowerCase().includes(lowercaseValue) ||
        row.program.toLowerCase().includes(lowercaseValue) ||
        row.created_by.toLowerCase().includes(lowercaseValue) ||
        (row.st_date && row.st_date.toLowerCase().includes(lowercaseValue)) ||
        (row.created_date && row.created_date.toLowerCase().includes(lowercaseValue)) || // Check if created_date is defined
        prepaidFeeIncludes
      );
    });
      setRecords(newData);
  }else{
      const user_id = btoa(userData._id);
      const authtype = btoa(userData.authtype);
      axios.get(`/api/loa/${user_id}/${authtype}`).then((response) => {
        setRecords(response.data);
      });
  }
}


  return (
    <>
    
      <div className="wrapper">
        <AdminHeader />

        <AdminSidebar />

        <div className="content-wrapper mt-2">
          <section className="content">
            <div className="">
              <div className="row">
                <div className="col-12">
                   
                    <div className="card card-dark">
                    <div className="card-header">
                      <h3 className="card-title">LOA Listing</h3>
                      <input type='text' onChange={handleFilter} style={{float:'right',width:'200px'}} placeholder="Search..." className='form-control'/>

                      <Link to="/admin/loa/create" className='btn btn-primary text-white mr-2' style={{float:'right'}} > Create LOA</Link>

                    </div>

                    <div className="card-body">
                    <DataTable columns={columns} data={records} pagination></DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <br/>
        <AdminFooter />
      </div>
    </>
  );
};

export default LoaListing;