import React, { useState } from "react";
// import Logo from '../images/logo.png';
//import { useNavigate } from 'react-router-dom';
import Navebar from "./Navebar";
import Footer from "./Footer";
import toast from 'react-hot-toast';
import axios from 'axios';
import { Link } from "react-router-dom";


const StudentRegisterRequest = () => {

  const initialValues = { fname:'',
    lname:'',
    email:'',
    phone:'',
    message:'',
    program_intereset:'',
    country:''
  }
  const [user, setUser] = useState(initialValues);
  
  const [isValid, setIsValid] = useState(false);
  const [email, setEmail] = useState('');
  const [getOTP, setOTP] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
    //const navigate  = useNavigate();

    //Handle inputs data
    let name, value;
    const handleInputs = (e) => {
        console.log(e);
        name = e.target.name;
        value = e.target.value;

        //User's all the data using spread operator
        setUser({...user, [name]:value});

        if(name === 'email'){
          const newEmail = e.target.value;
          setEmail(newEmail);
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          setIsValid(emailRegex.test(newEmail));
        }

        if(name === 'otp'){
          const otp = e.target.value;
          setOTP(otp);
        }
        
    }

    const PostData = async (e) => {
        e.preventDefault();
        const {fname, lname, email, phone, program_intereset, country, message} = user;
        const res = await fetch("/studentRequest", {
            method: "POST",
            headers: {
              "Content-Type":"application/json"
            },
            body: JSON.stringify({
              fname, lname, email, phone, program_intereset, country, message
            })
        });

        const data = await res.json();
        
        if(data.error){
           // window.alert("Invalid requested");
            toast.error(data.error);
        }else{
          
            setUser(initialValues);
            toast.success("Requested Successfully !!");
            //navigate('/login');
        }
    }

    const handleVerifyEmail = () => {

      setIsSubmit(true);

      if (isValid) {
       
        localStorage.setItem('email', 'notverified');
        axios.get(`/api/agentverification/${email}`).then((response) => {

          if(response.data.error){
            setIsSubmit(false);
            toast.error(response.data.error);
          }else{
              setIsSubmit(false);
              //setData(response.data);
              localStorage.setItem('randomNumber', response.data.otp);
              toast.success(response.data.success);
              const handleSentVerifyEmail = document.getElementById('handleSentVerifyEmail');
              const handleVerifyEmail = document.getElementById('handleVerifyEmail');
              if (handleSentVerifyEmail) {
                handleSentVerifyEmail.style.display = 'block';
                handleVerifyEmail.style.display = 'none';
              }
          }

          
        });
      } else {
        setIsSubmit(false);
        toast.error('Please enter a valid email address before verification.');
      }
    };

    const handleSentVerifyEmail = (e) => {

        e.preventDefault();
     
      const handleSentVerifyEm = document.getElementById('handleSentVerifyEmail');
      const handleSentVerified = document.getElementById('handleSentVerified');
      const randomNum = localStorage.getItem('randomNumber');
      if(randomNum === getOTP){
        toast.success('Email successfully verified');
        handleSentVerifyEm.style.display = 'none';
        handleSentVerified.style.display = 'block';
        setIsReadOnly(true);
        localStorage.setItem('email', 'verified');

      }else{
        localStorage.setItem('email', 'notverified');
        toast.error('Sorry not verified. Try again');
        handleSentVerifyEm.style.display = 'block';
        handleSentVerified.style.display = 'none';
        setIsReadOnly(false);
      }
     
    };


  return (
    <>
      <div className="main-wrapper website">
      <Navebar />
      <div className="pv-shop-area">        
       <div className="container eduvibe-animated-shape commonpage"> 
                    <h3>Student Register Request </h3>
                    <p>Fill in the data below.</p>
                    <form className="requires-validation">
                    <div className="row">

                    <div className="col-md-8">

                    <div className="row">
                      <div className="col-md-6"> 
                      <div className="form-group">
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="fname"
                          id="fname"
                          placeholder="First Name"
                          value={user.fname}
                          onChange={handleInputs}
                        />
                        </div>
                      </div>

                      <div className="col-md-6"> 
                      <div className="form-group">
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="lname"
                          id="lname"
                          placeholder="Last Name"
                          value={user.lname}
                          onChange={handleInputs}
                        />
                        </div>
                      </div>

                      <div className="col-md-7">
                      <div className="form-group">
                        <input
                          className="form-control form-control-lg"
                          type="email"
                          name="email"
                          id="email"
                          placeholder="E-mail Address"
                          value={user.email}
                          readOnly={isReadOnly}
                          onChange={handleInputs}
                          required
                        />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">

                        <input
                          type="text"
                          id="otpEmail"
                          name="otp"
                          value={user.otp}
                          readOnly={isReadOnly}
                          placeholder='Enter OTP'
                          onChange={handleInputs}
                          style={{ margin: '0px', top: 'opx', fontSize: '14px', width: '155px' }}
                          className="form-control"
                        />
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group">

                          <button style={{ margin: '5px 0px', top: '-1px', right: '13px' }} disabled={isSubmit} className="btn btn-success btn-sm" id="handleVerifyEmail" onClick={handleVerifyEmail}>
                          {isSubmit ? 'Verifying...' : 'Verify'}
                          </button>
                          <button style={{ display: 'none', margin: '5px 0px', top: '-1px', right: '13px' }}  className="btn btn-success btn-sm" id="handleSentVerifyEmail" onClick={handleSentVerifyEmail}>
                          {isSubmit ? 'Verifying...' : 'Verify'}
                          </button>
                          <Link style={{ display: 'none', margin: '5px 0px', top: '-1px', right: '3px' }}  className="btn btn-success btn-sm" id="handleSentVerified" >Verified</Link>
                        </div>
                      </div>

                      <div className="col-md-12">
                      <div className="form-group">
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Phone"
                          value={user.phone}
                          onChange={handleInputs}
                        />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                         
                          <input name="country" value={user.country} onChange={handleInputs} type="text" className="input form-control" id="country" placeholder="Country " />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          
                          <input name="program_intereset" value={user.program_intereset} onChange={handleInputs} type="text" className="input form-control" id="program_intereset" placeholder="Program of Intereset " />
                        </div>
                      </div>

                      <div className="col-md-12">
                      <div className="form-group">
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="message"
                          id="message"
                          placeholder="Message"
                          value={user.message}
                          onChange={handleInputs}
                          
                        />
                       </div>
                        
                      </div>


                      <div className="form-button">
                      <div className="form-group">
                        <button
                          name="signup"
                          id="signup"
                          type="submit"
                          onClick={PostData}
                          className="btn btn-primary"
                        >
                          Request
                        </button>   <span className="alaccount">Already have an account? <Link to="/login">Login</Link></span>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div className="col-md-4">

                        {/* <img src={Logo} alt="Logo" width={180}/> */}

                        </div>
                      </div>
                    </form>
                  
                </div>
              </div>
      </div>
      <Footer />
    </>
  );
};

export default StudentRegisterRequest;
