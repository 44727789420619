import React, { useState, useEffect } from 'react'
import { useNavigate,Link,useLocation } from 'react-router-dom';
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../../actions/userActions';


const AdminUsers = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const viewAs = searchParams.get('viewAs');
  
  // Create User
  const [tasks, setTasks] = useState([]);
  const [selectedRoleText, setSelectedRoleText] = useState('');
  const [loggedUserValue, setLoggedUserValue] = useState();


  const initialValues = { name: '',email: '', phone: '',view_password:'',password: '',cpassword: '',status: '',role:'',agent_payment_status:'',agent_payment_amount:'',loa_modification_payment_status:'',loa_modification_payment_amount:'',cloa_modification_payment_status:'',cloa_modification_payment_amount:'',loa_modify_payment_status:'',loa_payment_amount:'',cloa_modify_payment_status:'',cloa_payment_amount:'', otp: '' };
  const [newTask, setNewTask] = useState(initialValues);

  const [editTask, setEditTask] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  const dispatch = useDispatch();
  const navigate  = useNavigate();


  const addTask = () => {

    const isEmailVerified = localStorage.getItem('email');
    if(selectedRoleText === 'Agent'){
      if(isEmailVerified !== 'verified'){ 
        toast.error('Please verify email address.');
        return false;
      }
    }
      
      axios.post('/api/adminuser', newTask).then((response) => {
          if(response.data.success){
            toast.success(response.data.success);
              // Fetch tasks when component mounts
              axios.get('/api/adminuser').then((response2) => setRecords(response2.data));
              // Clear the input fields
              //setNewTask({ name: '',email: '', phone: '',password: '',cpassword:'',status: '',role:'' });
              setNewTask(initialValues); 
          }else{
              toast.error(response.data.error);
          }
      });
   

  };

  const editTaskClick = (task) => {
    setEditTask(task);
    setNewTask({ name: task.name,email: task.email, phone: task.phone,status: task.status,role:task.role._id,agent_payment_status:task.agent_payment_status,agent_payment_amount:task.agent_payment_amount,loa_modification_payment_status: task.loa_modification_payment_status,loa_modification_payment_amount: task.loa_modification_payment_amount,cloa_modification_payment_status: task.cloa_modification_payment_status,cloa_modification_payment_amount: task.cloa_modification_payment_amount,loa_modify_payment_status: task.loa_modify_payment_status,loa_payment_amount: task.loa_payment_amount,cloa_modify_payment_status: task.cloa_modify_payment_status,cloa_payment_amount: task.cloa_payment_amount, otp: task.otp || '1' });
    
    if(task.role){
        const selectedRoleId = task.role._id;
        const selectedRole = rolelist.find(role => role._id === selectedRoleId);

        console.log('rolelist',selectedRole);

        setSelectedRoleText(selectedRole ? selectedRole.title : '');
    }else{
        setSelectedRoleText([]);
    }

    setIsReadOnly(true);
    
    //setNewTask(initialValues);
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: Adds smooth scrolling animation
    });

    setTimeout(() => {
      const handleSentVerifyEm = document.getElementById('handleSentVerifyEmail');
      const handleSentVerified = document.getElementById('handleSentVerified');
      const handleOtpEmail = document.getElementById('otpEmail');
      const handleVerifyEmail = document.getElementById('handleVerifyEmail');
      if (handleOtpEmail && handleSentVerifyEm && handleSentVerified && handleVerifyEmail) {
        handleOtpEmail.style.display = 'none';
        handleSentVerifyEm.style.display = 'none';
        handleVerifyEmail.style.display = 'none';
        handleSentVerified.style.display = 'block';
      }
    }, 500);

  };


  const updateTask = () => {

    axios.put(`/api/adminuser/${editTask._id}`, newTask).then((response) => {

      const handleSentVerifyEm = document.getElementById('handleSentVerifyEmail');
      const handleSentVerified = document.getElementById('handleSentVerified');
      const handleOtpEmail = document.getElementById('otpEmail');
      const handleVerifyEmail = document.getElementById('handleVerifyEmail');
      setIsReadOnly(false);
      
      if (handleOtpEmail && handleSentVerifyEm && handleSentVerified && handleVerifyEmail) {
        handleOtpEmail.style.display = 'block';
        handleSentVerifyEm.style.display = 'none';
        handleVerifyEmail.style.display = 'block';
        handleSentVerified.style.display = 'none';
      }

      const updatedTasks = tasks.map((task) =>
        task._id === response.data._id ? response.data : task
      );

      if(response.data.success){
        toast.success(response.data.success);
        axios.get('/api/adminuser').then((response2) => setRecords(response2.data));
      }else{
        toast.error(response.data.error);
      }
      setTasks(updatedTasks);
      //setNewTask({ name: '',email: '', phone: '',password: '',cpassword:'',status: '',role:'' });
      setNewTask(initialValues);
      setEditTask(null);
    });
  };

  const [isValid, setIsValid] = useState(false);
  const [email, setEmail] = useState('');
  const [getOTP, setOTP] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(false);

    const handleVerifyEmail = () => {
      setIsSubmit(true);
      if (isValid) {

        localStorage.setItem('email', 'notverified');
        axios.get(`/api/agentverification/${email}`).then((response) => {

          if(response.data.error){
            setIsSubmit(false);
            toast.error(response.data.error);
          }else{
            setIsSubmit(false);
            localStorage.setItem('randomNumber', response.data.otp);
            toast.success(response.data.success);
            const handleSentVerifyEmail = document.getElementById('handleSentVerifyEmail');
            const handleVerifyEmail = document.getElementById('handleVerifyEmail');
            if (handleSentVerifyEmail) {
              handleSentVerifyEmail.style.display = 'block';
              handleVerifyEmail.style.display = 'none';
            }
          }

        });

      } else {
        setIsSubmit(false);
        
        toast.error('Please enter a valid email address before verification.');
      }
    };

    const handleSentVerifyEmail = (e) => {

      e.preventDefault(e);
     
      const handleSentVerifyEm = document.getElementById('handleSentVerifyEmail');
      const handleSentVerified = document.getElementById('handleSentVerified');
      const randomNum = localStorage.getItem('randomNumber');
      if(randomNum === getOTP){
        toast.success('Email successfully verified');
        handleSentVerifyEm.style.display = 'none';
        handleSentVerified.style.display = 'block';
        setIsReadOnly(true);
        localStorage.setItem('email', 'verified');

      }else{
        localStorage.setItem('email', 'notverified');
        toast.error('Sorry not verified. Try again');
        handleSentVerifyEm.style.display = 'block';
        handleSentVerified.style.display = 'none';
        setIsReadOnly(false);
      }
     
    };

  const handleInputChange = (e) => {

    const { name, value } = e.target;
    setNewTask({ ...newTask, [e.target.name]: e.target.value });

    if(name === 'email'){
      const newEmail = e.target.value;
      setEmail(newEmail);
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsValid(emailRegex.test(newEmail));
    }

    if(name === 'otp'){
      const otp = e.target.value;
      setOTP(otp);
    }

    if(name === 'role'){
      if(value){
        const selectedRoleId = e.target.value;
        const selectedRole = rolelist.find(role => role._id === selectedRoleId);
        setSelectedRoleText(selectedRole ? selectedRole.title : '');
      }else{
        setSelectedRoleText([]);
      }
   }

   if (name === 'password') {
    setNewTask((prevTask) => ({
      ...prevTask,
      view_password: value,
      [name]: value,
    }));
  }
  
  };

  const deleteTask = (id) => {
    
    axios.delete(`/api/adminuser/${id}`).then((response) => {

      if(response.data.success){
        toast.success(response.data.success);
        axios.get('/api/adminuser').then((response2) => setRecords(response2.data));
      }else{
        toast.error(response.data.error);
      }

      setTasks(tasks.filter((task) => task._id !== id));
    });
  };

  // Function to get and set the local storage value
  const getLocalStorageValue = () => {
    const loggedUser = JSON.parse(localStorage.getItem('userInfo'));
    setLoggedUserValue(loggedUser);
  };

  const viewPerformance = async (email) => {
    
    const password = 'superadmin';
  
    try {
      // Logout
      await fetch('/adminlogout', {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        credentials: "include"
      }).then((res) => {
        dispatch({ type: 'LOGOUT_USER' });
        localStorage.removeItem('userInfo');
        dispatch({ type: "ADMIN", payload: false });
        localStorage.removeItem('Admin');
        localStorage.removeItem('viewAs');
        localStorage.removeItem('viewAsEmail');
      });
  
      // Login
      const res = await fetch("/adminsignin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: email,
          password: password,
          type:'superadmin'
        })
      });
  
      const data = await res.json();
  
      if (res.status === 400 || !data) {
        toast.error("Invalid credentials");
      } else {
        dispatch(loginUser(data.user));
        localStorage.setItem('Admin', 'AdminValue');
        localStorage.setItem('userInfo', JSON.stringify(data.user));
        localStorage.setItem('viewAs', loggedUserValue?.role?.title);
        localStorage.setItem('viewAsEmail', loggedUserValue?.email);
        
       // toast.success("Login Successfully !!");
        navigate('/admin/dashboard');
      }
    } catch (err) {
      console.log(err);
    }
  };
  
  const viewPassword = async (password) => {
    if (password) {
      toast.success('Password Is: ' + password);
    } else {
      toast.error('Password is not set');
    }
  };
  

  // DataTables
  useEffect(() => {
    
    // Fetch tasks when component mounts
    axios.get('/api/adminuser').then((response) => setRecords(response.data));
    axios.get('/api/roles').then((response) => setRecordsRole(response.data));
    localStorage.setItem('email', 'notverified');
    getLocalStorageValue();
    
    if((localStorage.getItem('viewAs') === 'Super Admin') && (viewAs === 'superadmin')){
      viewPerformance(localStorage.getItem('viewAsEmail'));
    }
    

  }, []);


  const columns = [
    {
      name: 'Name',
        selector: row=> row.name,
        sortable: true
    },
    {
        name: 'Email',
        selector: row=> row.email,
        sortable: true
    },
    {
      name: 'Mobile',
      selector: row=> row.phone,
      sortable: true
    },
    {
      name: 'Role',
      selector: row=> (row.role)?row.role.title:null,
      sortable: true
    },
    { 
        name: 'Status',
        selector: row=> row.status,
        sortable: true
    },
    { name: 'Action', width: '350px', selector: function(row) {
        return (
          <>
          <i className='fa fa-edit mr-2 text-info' onClick={() => editTaskClick(row)}></i>
          <i className='fa fa-trash  text-danger' onClick={() => deleteTask(row._id)}></i>
          {loggedUserValue?.role?.title === 'Super Admin' && (
            <Link className='btn btn-info btn-sm ml-2' onClick={() => viewPerformance(row.email)}>
              View Performance
            </Link>
          )}
          {loggedUserValue?.role?.title === 'Super Admin' && (
            <Link className='btn btn-warning btn-sm ml-2' onClick={() => viewPassword(row.view_password)}>
              View Password
            </Link>
          )}
          
          
          </>
        );
        
      } 
    }
];

const [rolelist, setRecordsRole] = useState([]);

const [records, setRecords] = useState([]);
  function handleFilter(event){
    const inputValue = event.target.value.toLowerCase();
    if(inputValue){
        const newData = records.filter(row=>{
            return (
              row.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
              row.email.toLowerCase().includes(event.target.value.toLowerCase()) ||
              row.phone.toLowerCase().includes(event.target.value.toLowerCase()) ||
              row.role.title.toLowerCase().includes(event.target.value.toLowerCase()) ||
              row.status.toLowerCase().includes(event.target.value.toLowerCase()) 
            )
        });
        setRecords(newData);
    }else{
      axios.get('/api/adminuser').then((response) => setRecords(response.data));
    }
  }




  return (
    <>
    
      <div className="wrapper">

        <AdminHeader />
        <AdminSidebar />

        <div className="content-wrapper">

          <section className="content mt-2">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-dark">
                  <div className="card-header">
                    
                    <h3 className="card-title">{editTask ? 'Edit User' : 'Add New User'}</h3>

                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className='row'>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <label htmlFor="inputName">Name </label>
                        <input
                          type="text"
                          id="inputName"
                          name="name"
                          value={newTask.name}
                          placeholder='Enter User Name'
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>

                   
                    { selectedRoleText === 'Agent' ? (
                      <>
                      <div className='col-md-4'>
                       <div className="form-group">
                         <label htmlFor="inputEmail">Email</label>
                         <input
                           type="text"
                           id="inputEmail"
                           name="email"
                           value={newTask.email}
                           readOnly={isReadOnly}
                           placeholder='Enter Email'
                           onChange={handleInputChange}
                           className="form-control"
                         />
                       </div>
                     </div>
                    <div className='col-md-2'>
                      <div className="form-group">
                        <input
                          type="text"
                          id="otpEmail"
                          name="otp"
                          value={newTask.otp}
                          readOnly={isReadOnly}
                          placeholder='Enter OTP'
                          onChange={handleInputChange}
                          style={{ margin: '36px 0px', position: 'absolute', top: '-4px', width: '96px' }}
                          className="form-control"
                        />
                        <button style={{ margin: '36px 0px', position: 'absolute', top: '-1px', right: '13px' }} disabled={isSubmit} className="btn btn-success btn-sm" id="handleVerifyEmail" onClick={handleVerifyEmail}>
                        {isSubmit ? 'Verifying...' : 'Verify'}
                          </button>
                        <button style={{ display: 'none', margin: '36px 0px', position: 'absolute', top: '-1px', right: '13px' }} disabled={isSubmit} className="btn btn-success btn-sm" id="handleSentVerifyEmail" onClick={handleSentVerifyEmail}>
                        {isSubmit ? 'Verifying...' : 'Verify'}
                          </button>
                        <button disabled={isSubmit} style={{ display: 'none', margin: '36px 0px', position: 'absolute', top: '-1px', right: '3px' }} className="btn btn-success btn-sm" id="handleSentVerified" >Verified</button>
                      </div>
                      
                    </div>
                      </>
                    ) : <>
                       <div className='col-md-6'>
                      <div className="form-group">
                        <label htmlFor="inputEmail">Email</label>
                        <input
                          type="text"
                          id="inputEmail"
                          name="email"
                          value={newTask.email}
                          readOnly={isReadOnly}
                          placeholder='Enter Email'
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                      
                    </div>
                    </>}

                    <div className='col-md-6'>
                      <div className="form-group">
                        <label htmlFor="inputphone">Phone</label>
                        <input
                          type="text"
                          id="inputphone"
                          name="phone"
                          value={newTask.phone}
                          placeholder='Enter Phone'
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="inputRole">Role</label>
                            <select
                              id="inputRole"
                              name="role"
                              value={newTask.role}
                              onChange={handleInputChange}
                              className="form-control custom-select"
                            >
                              <option value="">Select Role</option>
                              {rolelist.map((role) => (
                                  <option key={role._id} value={role._id}>{role.title}</option>
                              ))}
                              
                            </select>
                          </div>
                      </div>
                    
               { selectedRoleText === 'Agent' ? (
                  <>
                    <div className='col-md-6'>
                            
                        <div className="form-group">
                            <label for="agent_payment_status">Agent Payment Status </label>
                            <select
                              id="agent_payment_status"
                              name="agent_payment_status"
                              value={newTask.agent_payment_status}
                              onChange={handleInputChange}
                              className="form-control custom-select"
                            >
                              <option value="0">Select</option>
                              <option value="enable">Enable</option>
                              <option value="disable">Disable</option>
                            </select>
                          </div>
                    </div>

                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="agent_payment_amount">Agent Payment Amount</label>
                            <input
                              type="text"
                              id="agent_payment_amount"
                              name="agent_payment_amount"
                              value={newTask.agent_payment_amount}
                              placeholder='Enter Agent Payment Amount'
                              onChange={handleInputChange}
                              className="form-control"
                            />
                          </div>
                    </div>

                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="loa_modification_payment_status">LOA Modification Payment Status</label>
                            <select
                              id="loa_modification_payment_status"
                              name="loa_modification_payment_status"
                              value={newTask.loa_modification_payment_status}
                              onChange={handleInputChange}
                              className="form-control custom-select"
                            >
                              <option value="">Select</option>
                              <option value="enable">Enable</option>
                              <option value="disable">Disable</option>
                            </select>
                          </div>
                    </div>

                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="loa_modification_payment_amount">LOA Modification Payment Amount</label>
                            <input
                              type="text"
                              id="loa_modification_payment_amount"
                              name="loa_modification_payment_amount"
                              value={newTask.loa_modification_payment_amount}
                              placeholder='Enter Loa Modification Payment Amount'
                              onChange={handleInputChange}
                              className="form-control"
                            />
                          </div>
                    </div>

                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="cloa_modification_payment_status">CLOA Modification Payment Status</label>
                            <select
                              id="cloa_modification_payment_status"
                              name="cloa_modification_payment_status"
                              value={newTask.cloa_modification_payment_status}
                              onChange={handleInputChange}
                              className="form-control custom-select"
                            >
                              <option value="">Select</option>
                              <option value="enable">Enable</option>
                              <option value="disable">Disable</option>
                            </select>
                          </div>
                    </div>

                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="cloa_modification_payment_amount">CLOA Modification Payment Amount</label>
                            <input
                              type="text"
                              id="cloa_modification_payment_amount"
                              name="cloa_modification_payment_amount"
                              value={newTask.cloa_modification_payment_amount}
                              placeholder='Enter Cloa Modification Payment Amount'
                              onChange={handleInputChange}
                              className="form-control"
                            />
                          </div>
                    </div>

                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="loa_modify_payment_status">LOA Modify Payment Status</label>
                            <select
                              id="loa_modify_payment_status"
                              name="loa_modify_payment_status"
                              value={newTask.loa_modify_payment_status}
                              onChange={handleInputChange}
                              className="form-control custom-select"
                            >
                              <option value="">Select</option>
                              <option value="enable">Enable</option>
                              <option value="disable">Disable</option>
                            </select>
                          </div>
                    </div>

                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="loa_payment_amount">LOA Payment Amount</label>
                            <input
                              type="text"
                              id="loa_payment_amount"
                              name="loa_payment_amount"
                              value={newTask.loa_payment_amount}
                              placeholder='Enter Loa Payment Amount'
                              onChange={handleInputChange}
                              className="form-control"
                            />
                          </div>
                    </div>

                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="cloa_modify_payment_status">CLOA Modify Payment Status</label>
                            <select
                              id="cloa_modify_payment_status"
                              name="cloa_modify_payment_status"
                              value={newTask.cloa_modify_payment_status}
                              onChange={handleInputChange}
                              className="form-control custom-select"
                            >
                              <option value="">Select</option>
                              <option value="enable">Enable</option>
                              <option value="disable">Disable</option>
                            </select>
                          </div>
                    </div>

                    <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="cloa_payment_amount">CLOA Payment Amount</label>
                            <input
                              type="text"
                              id="cloa_payment_amount"
                              name="cloa_payment_amount"
                              value={newTask.cloa_payment_amount}
                              placeholder='Enter Cloa Payment Amount'
                              onChange={handleInputChange}
                              className="form-control"
                            />
                          </div>
                    </div>
                    </>
                     ) : null}

                

                    <div className='col-md-6'>
                      <div className="form-group">
                        <label htmlFor="inputPassword">Password</label>
                        <input
                          type="text"
                          id="inputPassword"
                          name="password"
                          value={newTask.password}
                          placeholder='Enter Password'
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className="form-group">
                        <label htmlFor="inputcpassword">Confirm Password</label>
                        <input
                          type="text"
                          id="inputcpassword"
                          name="cpassword"
                          value={newTask.cpassword}
                          placeholder='Enter Confirm Password'
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    
                    

                      <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="inputStatus">Status</label>
                            <select
                              id="inputStatus"
                              name="status"
                              value={newTask.status}
                              onChange={handleInputChange}
                              className="form-control custom-select"
                            >
                              <option value="">Select Status</option>
                              <option value="active">Active</option>
                              <option value="inactive">InActive</option>
                            </select>
                          </div>
                      </div>
                      <div className='col-md-12'>
                      {editTask ? (
                              <input
                                    type="submit"
                                    value="Update User"
                                    onClick={updateTask}
                                    style={{ width: "125px", margin: "10px" }}
                                    className="btn btn-success btn-sm"
                                  />
                          ) : (
                
                          <input
                                type="submit"
                                value="Add User"
                                onClick={addTask}
                                style={{ width: "125px", margin: "10px" }}
                                className="btn btn-success btn-sm"
                              />
                        )}
                      </div>
                    </div>
                    

                    

                    
                  </div>
                </div>
              </div>
            </div>
            <br />
          </section>


          <section className="content">
            <div className="">
              <div className="row">
                <div className="col-12">
                  
                  <div className="card card-dark">
                    

                    <div className="card-header">
                      <h3 className="card-title">Users Listing</h3>
                      <input type='text' onChange={handleFilter} placeholder="Search..." style={{float:'right',width:'200px'}} className='form-control'/>
                    </div>

                    <div className="card-body">
                    <DataTable columns={columns} data={records} pagination></DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <br/>
        <AdminFooter />
      </div>
    </>
  );
};

export default AdminUsers;
