import React, { useState, useEffect } from 'react'
import {useNavigate,Link} from 'react-router-dom';

import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import axios from 'axios';
import toast from 'react-hot-toast';
const LoaCreate = () => {

const navigate = useNavigate();
 
const initialValues = {issue_date: "", email_cloa: "", firstName: "", lastName:"",email:"",dob:"",campus_id:"",unit:"",street_no:"",street_name:"",city:"",state:"",country:"",postal_code:"",study_program_id:"",program_fees:"",prepaid_fee:"",remaining_fees:"",st_date:"",end_year_date:"",expire_date:"",other_info:"", total_score:"",  reading:"",writing:"",listening:"",speaking:"", ten_grade_marks:"",twe_grade_marks:"", uci_number:"",passpost_expiry_date:"",passpost_number:"", status:"inactive",created_by:"",created_date:"",is_created:"created" };
const [formData, setFormValues] = useState(initialValues);
const [formErrors, setFormErrors] = useState({});
const [campusList, setCampusRecords] = useState([]);
const [studyProgramList, setStudyProgramRecords] = useState([]);

const [isSubmit, setIsSubmit] = useState(false);

const capitalizeFirstLetter = (str, name) => {
  const skipCondition = ['email_cloa', 'email','issue_date','dob','st_date','end_year_date','expire_date'];

   if (skipCondition.includes(name)) {
     return str; 
   }
   return str
     .split(' ')
     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
     .join(' ');
 };

const handleInputChange = (e) => {
   const { name, value } = e.target;
  setFormValues((formData) => ({ ...formData, [name]: capitalizeFirstLetter(value, name) }));


   if(name === 'campus_id'){
      if(value){
         getstudyprogram(value);
      }else{
         setStudyProgramRecords([]);
      }
      setFormValues((formData) => ({ ...formData, prepaid_fee: 0,remaining_fees: 0,program_fees: 0, }));
   }
   if(name === 'email_cloa'){
      if(value){
         getCloaData(value);
      }
   }
   

   
  
   if (name === 'st_date') {
      if (value) {
        const newDate = new Date(value);
        newDate.setFullYear(newDate.getFullYear() + 1);
    
        const expireDate = new Date(value);
        expireDate.setMonth(expireDate.getMonth() + 2); // Add one month
        expireDate.setDate(0 - 1); // Subtract one day
    
        setFormValues((formData) => ({
          ...formData,
          end_year_date: newDate.toISOString().split('T')[0],
          expire_date: expireDate.toISOString().split('T')[0]
        }));
      } else {
        setFormValues((formData) => ({ ...formData, end_year_date: '', expire_date: '' }));
      }
    }
    

   


   if(name === 'study_program_id'){
      if(value){
         getstudyprogramDetails(value);
      }else{
         //setStudyProgramDetailsRecords({});
      }

      if(value){
         const selectedProgramId = e.target.value;
         const selectedProgram = studyProgramList.find(program => program._id === selectedProgramId);
         if(selectedProgram.program === 'Medical Office Administration'){
            setFormValues((formData) => ({ ...formData, other_info: 'Successful completion of the medical office administration diploma program qualifies student for exemption from semesters 1 and 2 of Georgian College 2-year office administration – health services diploma program (see Appendix 1).', }));
         }else{
            setFormValues((formData) => ({ ...formData, other_info:'', }));
         }
       }

   }

   if(name === 'prepaid_fee'){
      const remaining_fees = (formData.program_fees - value);
      setFormValues((formData) => ({ ...formData, remaining_fees: remaining_fees }));
   }

 };


 const handleSubmit = (e) => {
   e.preventDefault();

   const errors = validate(formData);
   setFormErrors(errors);

   
   if (Object.keys(errors).length === 0) {
    
      setIsSubmit(true);
     axios.post('/api/loa', formData)
       .then((response) => {
         if(response.data.success){
            toast.success(response.data.success);

               axios.post('/api/activityLog', {
                  log_name: 'Loa Create',
                  log_type: 'loa',
                  log_id: response.data.savedData._id
               });
               // Clear the input fields
               setFormValues(initialValues);
               navigate(`/admin/loa/verify/${response.data.savedData._id}`);

         }else{
            toast.error(response.data.error);
         }
       })
       .catch((error) => {
         console.error('Submission failed', error);
       })
       .finally(() => {
         setIsSubmit(false);
       });
     
   }
 };

 const getstudyprogram = (campus_id) => {
   axios.get(`/api/studyprogram/${campus_id}`).then((response) => {
      setStudyProgramRecords(response.data);
    });
 }

 const getCloaData = (email) => {
   axios.post('/api/cloabyemail', {'email':email}).then((response) => {
      if(response.data){
         setFormValues((formData) => ({ ...formData, issue_date: response.data.issue_date,
            email_cloa:   response.data.email,
            firstName:   response.data.firstName,
            lastName : response.data.lastName,
            email : response.data.email,
            dob : response.data.dob,
            campus_id : response.data.campus_id,
            unit : response.data.unit,
            street_no : response.data.street_no,
            street_name : response.data.street_name,
            city : response.data.city,
            state : response.data.state,
            country : response.data.country,
            postal_code : response.data.postal_code,
            study_program_id : response.data.study_program_id,
            program_fees : response.data.program_fees,
            prepaid_fee : response.data.prepaid_fee,
            remaining_fees : response.data.remaining_fees,
            st_date : response.data.st_date,
            end_year_date : response.data.end_year_date,
            expire_date : response.data.expire_date,
            other_info : response.data.other_info
          }));
      }
      
    });
 }

 

 const getstudyprogramDetails = (id) => {
   axios.get(`/api/study/program/${id}`).then((response) => {
      //setStudyProgramDetailsRecords(response.data);
      const remaining_fees = (response.data.price - formData.prepaid_fee);
      setFormValues((formData) => ({ ...formData, program_fees: response.data.price,remaining_fees: remaining_fees }));
    });
 }

//  function formatDate(dateString) {
//    const date = new Date(dateString);
 
//    const day = date.getDate().toString().padStart(2, '0');
//    const month = (date.getMonth() + 1).toString().padStart(2, '0');
//    const year = date.getFullYear().toString().slice(-2);
 
//    return `${day}-${month}-${year}`;
//  }
 

useEffect(() => {

   axios.get('/api/campus').then((response) => {
      setCampusRecords(response.data);
    });

    const dataFetch = async () => {
      try{
          const data = await ( await fetch("/getAuth",{
                      method: "GET",
                      headers: {
                          Accept: "application/json", "Content-Type": "application/json"
                      },
                      credentials: "include"
                  })).json();

            

            const currentDate = new Date();
            const CookieCurrentDate = `${currentDate.getDate()}/${ currentDate.getMonth() + 1 }/${currentDate.getFullYear()} `;
            
            setFormValues((formData) => ({ ...formData, created_by: data._id,created_date: CookieCurrentDate}));
            

      }catch(err){
              console.log(err);
      }
  };
  dataFetch();


}, []);

 const validate = (values) => {
   const errors = {};
   const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
   if (!values.firstName) {
     errors.firstName = "First Name is required!";
   }

   if (!values.lastName) {
      errors.lastName = "Last Name is required!";
   }

    if (!values.email) {
     errors.email = "Email is required!";
   } else if (!regex.test(values.email)) {
     errors.email = "This is not a valid email format!";
   }

 
   return errors;
 };

return (
<>
<div className="wrapper">
   <AdminHeader />
   <AdminSidebar />
   <div className="content-wrapper">
      <section className="content mt-2">
         <div className="row">
            <div className="col-md-12">
               <div className="card card-dark">
                  <div className="card-header">
                     <h3 className="card-title">Add New LOA</h3>
                     <div className="card-tools">
                        <Link to="/admin/loa" className="btn btn-primary btn-sm"> Back</Link>
                     </div>
                  </div>
                  <div className="card-body">
                        <form onSubmit={handleSubmit}>
                           <div className='row'>

                           <div className="col-md-6">
                                <div className="form-group">
                                <label htmlFor="cloa">Date of Issue</label>
                                <input
                                  name="issue_date"
                                  value={formData.issue_date}
                                  onChange={handleInputChange}
                                  type="date"
                                  className="input form-control"
                                  id="issue_date"
                                  placeholder="Email"
                                />
                                <p className="mt-0 text-danger">
                                  {formErrors.issue_date}
                                </p>
                            </div>
                            </div>
                              
                              <div className="col-md-6">
                                 <div className="form-group">
                                 <label htmlFor="cloa">
                                 Student's e-mail on CLOA (if applicable)
                                 </label>
                                 <input name="email_cloa" value={formData.email_cloa} onChange={handleInputChange} type="text" className="input form-control" id="email_cloa" placeholder="Email" />

                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="firstName">
                                    First Name
                                    </label>
                                    <input name="firstName" value={formData.firstName} onChange={handleInputChange} type="text" className="input form-control" id="firstName" placeholder="Enter First Name" />
                                    <p className='mt-0 text-danger'>{formErrors.firstName}</p>
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="lastName">
                                    Last Name
                                    </label>
                                    <input name="lastName" value={formData.lastName} onChange={handleInputChange} type="text" className="input form-control" id="lastName" placeholder="Enter Last Name" />
                                    <p className='mt-0 text-danger'>{formErrors.lastName}</p>
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="email">
                                    Email
                                    </label>
                                    <input name="email" value={formData.email} onChange={handleInputChange} type="text" className="input form-control" id="email" placeholder="Email" />
                                    <p className='mt-0 text-danger'>{formErrors.email}</p>
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="dob">
                                    Date of Birth
                                    </label>
                                    <input name="dob" value={formData.dob} onChange={handleInputChange} type="date" className="input form-control" id="dob" placeholder="Date of birth" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="campus_id">
                                    Campus
                                    </label>
                                    <select id="campus_id" name="campus_id" value={formData.campus_id} onChange={handleInputChange} className="form-control custom-select">
                                       <option value="">Select</option>
                                       {campusList.map((cam) => (
                                          <option value={cam._id}>{cam.campus}</option>
                                       ))}
                                    </select>
                                 </div>
                              </div>

                              <div className="col-md-12">
                                 <div className="form-group text-info">Address</div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Apt/Unit
                                    </label>
                                    <input name="unit" value={formData.unit} onChange={handleInputChange} type="text" className="input form-control" id="unit" placeholder="Apt/Unit" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Street No
                                    </label>
                                    <input name="street_no" value={formData.street_no} onChange={handleInputChange} type="text" className="input form-control" id="street_no" placeholder="Street No" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Street Name
                                    </label>
                                    <input name="street_name" value={formData.street_name} onChange={handleInputChange} type="text" className="input form-control" id="street_name" placeholder="Street Name" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    City/Town
                                    </label>
                                    <input name="city" value={formData.city} onChange={handleInputChange} type="text" className="input form-control" id="city" placeholder="City/Town" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Province/State
                                    </label>
                                    <input name="state" value={formData.state} onChange={handleInputChange} type="text" className="input form-control" id="state" placeholder="Province/State" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Country 
                                    </label>
                                    <input name="country" value={formData.country} onChange={handleInputChange} type="text" className="input form-control" id="country" placeholder="Country " />
                                 </div>
                              </div>
                              
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Postal Code
                                    </label>
                                    <input name="postal_code" value={formData.postal_code} onChange={handleInputChange} type="text" className="input form-control" id="postal_code" placeholder="Postal Code" />
                                 </div>
                              </div>

                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Study Program
                                    </label>
                                    <select id="inputStudyProgram" name="study_program_id" value={formData.study_program_id} onChange={handleInputChange} className="form-control custom-select">
                                       <option value="">Select</option>
                                       {studyProgramList.map((cam) => (
                                          <option value={cam._id}>{cam.program}</option>
                                       ))}
                                    </select>
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Program Fees
                                    </label>
                                    <input name="program_fees" value={formData.program_fees} onChange={handleInputChange} type="text" className="input form-control" id="program_fees" placeholder="Program Fees" maxLength={13} readOnly />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                       Prepaid Fees
                                    </label>
                                    <input name="prepaid_fee" value={formData.prepaid_fee} onChange={handleInputChange} type="text" className="input form-control" id="prepaid_fee" placeholder="Prepaid Fees" maxLength={13} />
                                 </div>
                              </div>
                             
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Remaining Fees
                                    </label>
                                    <input name="remaining_fees" value={formData.remaining_fees} onChange={handleInputChange} type="text" className="input form-control" id="remaining_fees" placeholder="Remaining Fees" maxLength={13} readOnly/>
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Start Date
                                    </label>
                                    <input
                                       name="st_date"
                                       value={ formData.st_date }
                                       onChange={handleInputChange}
                                       type="date"
                                       className="input form-control"
                                       id="st_date"
                                       placeholder="Start Date"
                                       maxLength={13}
                                       />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    End Date
                                    </label>
                                    <input name="end_year_date" value={formData.end_year_date} onChange={handleInputChange} type="date" className="input form-control" id="end_year_date" placeholder="End Date" maxLength={13} readOnly />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Expiry Date
                                    </label>
                                    <input name="expire_date" value={formData.expire_date} onChange={handleInputChange} type="date" className="input form-control" id="expire_date" placeholder="Expiry Date" maxLength={13} />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Other Info
                                    </label>
                                    <input name="other_info" value={formData.other_info} onChange={handleInputChange} type="text" className="input form-control" id="other_info" placeholder="Other Info" />
                                 </div>
                              </div>
                              
                              
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    IELTS Total Score 
                                    </label>
                                    <input name="total_score" value={formData.total_score} onChange={handleInputChange} type="text" className="input form-control" id="total_score" placeholder="Total Score" />
                                 </div>
                              </div>

                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Reading
                                    </label>
                                    <input name="reading" value={formData.reading} onChange={handleInputChange} type="text" className="input form-control" id="reading" placeholder="Reading" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Writing
                                    </label>
                                    <input name="writing" value={formData.writing} onChange={handleInputChange} type="text" className="input form-control" id="writing" placeholder="Writing" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Listening
                                    </label>
                                    <input name="listening" value={formData.listening} onChange={handleInputChange} type="text" className="input form-control" id="listening" placeholder="Listening" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Speaking
                                    </label>
                                    <input name="speaking" value={formData.speaking} onChange={handleInputChange} type="text" className="input form-control" id="speaking" placeholder="Speaking" />
                                 </div>
                              </div>

                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    10th Grade Marks (GPA)
                                    </label>
                                    <input name="ten_grade_marks" value={formData.ten_grade_marks} onChange={handleInputChange} type="text" className="input form-control" id="ten_grade_marks" placeholder="10th Grade Marks" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    12th Grade Marks (GPA)
                                    </label>
                                    <input name="twe_grade_marks" value={formData.twe_grade_marks} onChange={handleInputChange} type="text" className="input form-control" id="twe_grade_marks" placeholder="12th Grade Marks" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    UCI Number (If Applicable)
                                    </label>
                                    <input name="uci_number" value={formData.uci_number} onChange={handleInputChange} type="text" className="input form-control" id="uci_number" placeholder="UCI Number" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Passpost Expiry Date
                                    </label>
                                    <input name="passpost_expiry_date" value={formData.passpost_expiry_date} onChange={handleInputChange} type="date" className="input form-control" id="passpost_expiry_date" placeholder="Passpost Expiry Date" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Passpost Number
                                    </label>
                                    <input name="passpost_number" value={formData.passpost_number} onChange={handleInputChange} type="text" className="input form-control" id="passpost_number" placeholder="Passpost Number" />
                                 </div>
                              </div>
                              
                              <div className='col-md-12'>
                              <button type="submit" className="btn btn-success btn-sm" disabled={isSubmit}>
                                 {isSubmit ? 'Verifying...' : 'Verify'}
                              </button>
                              </div>

                           </div>
                        </form>
                        <div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
        
      </section>
   </div>
   <br/>
   <AdminFooter />
</div>
</>
);
};
export default LoaCreate;