// store/index.js

//import rootReducer from '../reducer';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import userReducer from '../reducer/userReducer.js';
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension';

const rootReducer = combineReducers({
    user: userReducer,
    // Add other reducers if needed
});

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;

const initialState = {
    user: userInfoFromStorage,
    isAuthenticated: false,
  };

const middleware = [thunk];
const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
