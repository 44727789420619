import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import AdminLogo from '../../images/AdminLTELogo.png';
import { useSelector } from 'react-redux';

const AdminSidebar = () => {
  
  const navigate = useNavigate();
  const [userPermissionsData, setPermissionsData] = useState([]);

  const userData = useSelector((state) => state.user);
  const [viewAs, getviewAs] = useState();
  const [loggedUserValue, setLoggedUserValue] = useState();
  

  useEffect(() => {
    //console.log(userData);
    if(userData && userData.role && userData.role.permissions ){
      setPermissionsData(userData.role.permissions);
    }
    getviewAs(localStorage.getItem('viewAs'));
    getLocalStorageValue();
  }, [navigate,userData]);

  const isValueInArray = (array, value) => array.includes(value);

  const renderNavLink = (to, iconClass, text) => (
    <li className="nav-item">
      <NavLink to={to} className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link inactive')}>
        <i className={`nav-icon ${iconClass}`}></i>
        <p>{text}</p>
      </NavLink>
    </li>
  );
  
   // Function to get and set the local storage value
  const getLocalStorageValue = () => {
    const loggedUser = JSON.parse(localStorage.getItem('userInfo'));
    setLoggedUserValue(loggedUser);
  };

  const permissions = {
    Dashboard: 'Dashboard',
    Role: 'Role',
    User: 'User',
    Campus: 'Campus',
    StudyProgram: 'Study Program',
    Loa: 'Loa',
    Cloa: 'Cloa',
    StudentManagement: 'Student Management',
    LoaPaymentHistory: 'Loa Payment',
    CloaPaymentHistory: 'Cloa Payment',
    StudentPaymentHistory: 'Student Payment',
    LOATravelLetter: 'LOA Travel Letter',
    CLOATravelLetter: 'CLOA Travel Letter',
    STUDENTTravelLetter: 'STUDENT Travel Letter',
    ActivityLog: 'Activity Log',
    StudentRegisterReq: 'Register Request',
    communicationstatus: 'Communication Status'
  };

  return (
    <>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <NavLink to="/admin/dashboard" className="brand-link">
          <img src={AdminLogo} alt="Admin" className="brand-image img-circle elevation-3" />
          <span className="brand-text font-weight-light">Academy of Learning</span>
        </NavLink>
        
        <div className="sidebar">
          <nav className="mt-2">

          

           <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              
              {isValueInArray(userPermissionsData, permissions.Dashboard) && 
              renderNavLink('/admin/dashboard', 'fas fa-tachometer-alt', 'Dashboard')}
              
              {isValueInArray(userPermissionsData, permissions.Role) && 
              renderNavLink('/admin/roles', 'fas fa-tasks', 'Manage Role')}

              {isValueInArray(userPermissionsData, permissions.User) && 
              renderNavLink('/admin/users', 'fas fa-user', 'Manage Users')}

              {isValueInArray(userPermissionsData, permissions.Campus) && 
              renderNavLink('/admin/Campus', 'fas fa-book', 'Manage Campus')}

              {isValueInArray(userPermissionsData, permissions.StudyProgram) && 
              renderNavLink('/admin/study/program', 'fas fa-clipboard', 'Study Program')}

              {isValueInArray(userPermissionsData, permissions.Loa) && 
              renderNavLink('/admin/loa', 'fas fa-user-minus', 'Manage LOA')}

              {isValueInArray(userPermissionsData, permissions.Cloa) && 
              renderNavLink('/admin/cloa', 'fas fa-pen-nib', 'Manage CLOA')}

              {isValueInArray(userPermissionsData, permissions.StudentManagement) && 
              renderNavLink('/admin/student', 'fas fa-clipboard', 'Student Management')}

              {isValueInArray(userPermissionsData, permissions.LOATravelLetter) && 
              renderNavLink('/admin/loa-travel-letter', 'fas fa-clipboard', 'Manage Travel Letter')}

              {/* {isValueInArray(userPermissionsData, permissions.CLOATravelLetter) && 
              renderNavLink('/admin/cloa-travel-letter', 'fas fa-clipboard', 'CLOA Travel Letter')} */}

              {/* {isValueInArray(userPermissionsData, permissions.STUDENTTravelLetter) && 
              renderNavLink('/admin/student-travel-letter', 'fas fa-clipboard', 'STUDENT Travel Letter')} */}
             
             {isValueInArray(userPermissionsData, permissions.StudentRegisterReq) && 
              renderNavLink('/admin/student-register-req', 'fas fa-clipboard', 'Register Request')}
             
             
             
             {isValueInArray(userPermissionsData, permissions.ActivityLog) && 
              renderNavLink('/admin/activity/log', 'fas fa-book', 'Activity Log')}

            {isValueInArray(userPermissionsData, permissions.communicationstatus) && 
              renderNavLink('/admin/communication', 'fas fa-pen-nib', 'Communication Status')}


              {(viewAs==='Super Admin') &&
              renderNavLink('/admin/users?viewAs=superadmin', 'fas fa-undo', 'View As Super Admin')}

            {(loggedUserValue?.role?.title === 'Super Admin') && (
                <li className="nav-item">
                  <a href="https://shorturl.at/ceJT3" className="nav-link" target="_blank" rel="noopener noreferrer">
                    <i className="nav-icon fas fa-external-link-alt"></i>
                    LOA-Old Data
                  </a>
                </li>
              )}
              
              {renderNavLink('/admin/adminlogout', 'fas fa-sign-out-alt', 'Logout')}
              
            </ul> 
            
            
          </nav>
        </div>
      </aside>
    </>
  );
};

export default AdminSidebar;
