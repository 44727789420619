import React, { useState, useEffect } from 'react'
import { useParams,Link,useLocation,useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import axios from 'axios';

const StudentVerifyDownload = () => {

const [dataList, setData] = useState([]);
const params = useParams();
const navigate  = useNavigate();

const location = useLocation();
const searchParams = new URLSearchParams(location.search);
const sessionId = searchParams.get('session_id');
const issendmail = searchParams.get('issendmail');
  //const [showComponentB, setShowComponentB] = useState(false);
 
  const updatepaymentdata = (txn_no,status,amount,student_id) => {
    axios.post('/api/student/paymentupdate', {txn_no:txn_no,status:status,amount:amount,student_id:student_id})
       .then((response) => {
         if(response.data.success){
            toast.success(response.data.success);
          }else{
            //toast.error(response.data.error);
         }
       })
       .catch((error) => {
         console.error('Submission failed', error);
       });
  }

useEffect(() => {
      axios.get(`/api/student/${params.id}`).then((response) => {
        setData(response.data);
        //console.log('HH',response.data);
      });

      if (sessionId) {
        // Fetch payment details using the session_id
        fetch(`/api/get-payment-details?session_id=${sessionId}`)
          .then(response => response.json())
          .then(data => {
           // console.log('Payment details:', data);
            // Handle the payment details as needed (e.g., display to the user)
            updatepaymentdata(data.id,data.payment_status,data.amount_total,params.id);
          })
          .catch(error => {
            console.error('Error fetching payment details:', error);
          });
      }

      if(issendmail){
        navigate(`/admin/student/pdfdownload/${params.id}?pdfType=pdfreceipt`);
      }
      
}, [params.id,sessionId,issendmail,navigate]);

const { REACT_APP_API_URL_LOCAL_SERVER, REACT_APP_API_URL_PRODUCTION_SERVER, REACT_APP_ENV_TYPE } = process.env;
const UPLOADS_URL = REACT_APP_ENV_TYPE === 'production' ? REACT_APP_API_URL_PRODUCTION_SERVER : REACT_APP_API_URL_LOCAL_SERVER;
  

return (
<>
<div className="wrapper">
   <AdminHeader />
   <AdminSidebar />
   <div className="content-wrapper">
      <section className="content mt-2">
         <div className="row">
            <div className="col-md-12">
               <div className="card card-dark">
                  <div className="card-header">
                     <h3 className="card-title">Welcome {dataList.firstName}</h3>
                     <div className="card-tools">
                        <Link to={`/admin/student/edit/${params.id}`} className="btn btn-tool" title="Collapse">Back</Link>
                     </div>
                  </div>
                  <div className="card-body">
                    <div className='row'>
                        <div className='col-md-12 text-center'>
                       
                        {dataList && dataList.form_name ? (
                                    <Link target='_blank' to={UPLOADS_URL+dataList.form_name} className='btn btn-primary mr-2'>
                                    DOWNLOAD STUDENT PDF
                                  </Link>
                            ) : (<></>)}

                            {dataList && dataList.receipt_name ? (
                              <Link target='_blank' to={UPLOADS_URL+dataList.receipt_name} className='btn btn-primary'> DOWNLOAD RECEIPT PDF</Link>
                            ) : (<></>)}
                        </div>
                       
                    </div>
                  <div>
               </div>
            </div>
         </div>
      </div>
   </div>
        
      </section>
   </div>
   <br/>
   <AdminFooter />
</div>
</>
);
};
export default StudentVerifyDownload;