import React , { useContext, useState } from 'react'
//import Logo from "../images/logo.png";
import toast from 'react-hot-toast';
import { Link, useNavigate } from "react-router-dom";

import { UserContext } from "../App";

const Loginform = () => {

    const {dispatch} = useContext(UserContext);

    const navigate  = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const loginUser = async (e) => {
        e.preventDefault();

        const res = await fetch("/signin", {
            method: "POST",
            headers: {
              "Content-Type":"application/json"
            },
            body: JSON.stringify({
              email:email, 
              password:password
            })
        });

        const data = await res.json();
        //console.log(res.status);
        if(res.status === 400 || !data){
            toast.error("Invalid credential");
        }else{
            localStorage.setItem('UserF','UserValue');
            dispatch({type:"USER_FRONT", payload:true});
            toast.success("Login Successfully !!");
            navigate('/profile');
        }

    }

  return (
    <>
        <form className="requires-validation login-form" method='POST'>
            <div className="row">
                
                   <div className="input-box mb--30">
                    <input
                        type="email"
                        className="form-control form-control-lg"
                        name="email"
                        id="email"
                        placeholder="E-mail Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    </div>

                    <div className="input-box mb--30">
                    <input
                        type="password"
                        name="password"
                        className="form-control form-control-lg"
                        id="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                        </div>
                    {/* <div className="valid-feedback">
                        Password field is valid!
                    </div>
                    <div className="invalid-feedback">
                        Password field cannot be blank!
                    </div> */}

                        <button
                        name="signup"
                        id="signup"
                        type="submit"
                        onClick={loginUser}
                        className="rn-btn edu-btn w-100 mb--30"
                        >
                        Sign In
                        </button> <span className="alaccount">Create an account? <Link to="/student-register-request">SignUp</Link></span>

                        

                
                
                
                    
                
            </div>
            </form>
    </>
  )
}

export default Loginform