import React, { useState, useEffect } from 'react'
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import toast from 'react-hot-toast';


const Roles = () => {

  // Create role
  const [tasks, setTasks] = useState([]);
  const [editTask, setEditTask] = useState(null);

  const [formData, setFormData] = useState({ title: '', status: '',
    permissions: [], // For multi-checkboxes
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      // Handle checkbox change
      if (checked) {
        //console.log('value - ',value);
        setFormData({ ...formData, [name]: [...formData[name], value] });
        console.log('formData - ',formData);

      } else {
        setFormData({ ...formData, [name]: formData[name].filter((item) => item !== value) });
      }

    } else {
        // Handle other input changes
        setFormData({ ...formData, [name]: value });
    }
  };

  // Insert Role
  const addTask = () => {
    axios.post('/api/role', formData).then((response) => {
      //console.log('Axios Response:', response.data);
      
        if(response.data.success){
          toast.success(response.data.success);
            // Fetch tasks when component mounts
            axios.get('/api/roles').then((response2) => setRecords(response2.data));

            // Clear the input fields
            setFormData({ title: '', status: '' , permissions: []});
        }else{
          toast.error(response.data.error);
        }
      
      // Update the state with the new task  //setTasks([...tasks, response.data]);
    });
  };

  // Update Role
  const updateTask = () => {
    axios.put(`/api/role/${editTask._id}`, formData).then((response) => {
      const updatedTasks = tasks.map((task) =>
        task._id === response.data._id ? response.data : task
      );

      if(response.data.success){
        toast.success(response.data.success);
        axios.get('/api/roles').then((response2) => setRecords(response2.data));
      }else{
        toast.error(response.data.error);
      }

      setTasks(updatedTasks);
      setFormData({ title: '', status: '',permissions:'' });
      setEditTask(null);
    });
  };

  // Edit Role
  const editTaskClick = (task) => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Add smooth scrolling behavior
    });
   // console.log('task',task);
    setEditTask(task);
    setFormData({ title: task.title, status: task.status,permissions:task.permissions });
  };

  // Delete Role
  const deleteTask = (id) => {

    const isConfirmed = window.confirm('Are you sure you want to delete this item?');
    if (isConfirmed) {
    
    axios.delete(`/api/role/${id}`).then((response) => {

      if(response.data.success){
        toast.success(response.data.success);
        axios.get('/api/roles').then((response2) => setRecords(response2.data));
      }else{
        toast.error(response.data.error);
      }

      setTasks(tasks.filter((task) => task._id !== id));
    });

    }

  };


  // DataTables

  useEffect(() => {
    // Fetch tasks when component mounts
    axios.get('/api/roles').then((response) => setRecords(response.data));
  }, []);

  const columns = [
    {
      name: 'Title',
        selector: row=> row.title,
        sortable: true
    },
    { 
        name: 'Status',
        selector: row=> row.status,
        sortable: true
    },
    { name: 'Action', selector: function(row) {
        return (
          <>
          <i className='fa fa-edit mr-2 text-info' onClick={() => editTaskClick(row)}></i>
          <i className='fa fa-trash  text-danger' onClick={() => deleteTask(row._id)}></i>
          
          </>
        );
        
      } 
    }
    
    
  ];

  const permission = ['Dashboard','Study Program','User','Role','Reports','LOA','CLOA','Campus','Student Management','LOA Payment','CLOA Payment','Student Payment','Activity Log','LOA Travel Letter','CLOA Travel Letter','STUDENT Travel Letter','Register Request','Communication Status'];



  const [records, setRecords] = useState([]);
  function handleFilter(event){
    const inputValue = event.target.value.toLowerCase();
    if(inputValue){
        const newData = records.filter(row=>{
            return (
              row.title.toLowerCase().includes(event.target.value.toLowerCase()) ||
              row.status.toLowerCase().includes(event.target.value.toLowerCase()) 
            )
        });
        setRecords(newData);
    }else{
      axios.get('/api/roles').then((response) => setRecords(response.data));
    }
  }


  return (
    <>
    
      <div className="wrapper">
        <AdminHeader />

        <AdminSidebar />

        <div className="content-wrapper">

        <section className="content mt-2">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-dark">
                  <div className="card-header">
                    
                    <h3 className="card-title">{editTask ? 'Edit Role' : 'Add New Role'}</h3>

                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className='row'>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <label htmlFor="inputName">Role</label>
                        <input
                          type="text"
                          id="inputName"
                          name="title"
                          value={formData.title}
                          placeholder='Enter Role Title'
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    
                      <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="inputStatus">Status</label>
                            <select
                              id="inputStatus"
                              name="status"
                              value={formData.status}
                              onChange={handleInputChange}
                              className="form-control custom-select"
                            >
                              <option value="">Select Status</option>
                              <option value="active">Active</option>
                              <option value="inactive">InActive</option>
                            </select>
                          </div>
                      </div>

                      <div className='col-md-6'>
                        <label>Permissions</label>
                      <ul>

                      {permission.map((per, index) => (
                        <p key={index} className='m-0'>
                          <label htmlFor={`checkbox_${index}`}> 

                            <input type='checkbox'
                              id={`checkbox_${index}`}
                              name="permissions"
                              value={per}
                              checked={formData && formData.permissions && formData.permissions.includes(per)}
                              onChange={handleInputChange}
                            /> 
                            { ' '+per }
                          </label>
                        </p>
                      ))}
                      </ul>
                      </div>
                      <div className='col-md-12'>
                      {editTask ? (
                              <input
                                    type="submit"
                                    value="Update Role"
                                    onClick={updateTask}
                                    style={{ width: "125px", margin: "10px" }}
                                    className="btn btn-success btn-sm"
                                  />
                          ) : (
                
                          <input
                                type="submit"
                                value="Add Role"
                                onClick={addTask}
                                style={{ width: "125px", margin: "10px" }}
                                className="btn btn-success btn-sm"
                              />
                        )}
                      </div>
                    </div>
                    

                    

                    
                  </div>
                </div>
              </div>
            </div>
            <br />
          </section>


          <section className="content">
            <div className="">
              <div className="row">
                <div className="col-12">
                  
                  <div className="card card-dark">
                    

                    <div className="card-header">
                      <h3 className="card-title">Roles Listing</h3>
                      <input type='text' onChange={handleFilter} style={{float:'right',width:'200px'}} placeholder="Search..." className='form-control'/>
                    </div>

                    <div className="card-body">
                    <DataTable columns={columns} data={records} pagination></DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <br/>
        <AdminFooter />
      </div>
    </>
  );
};

export default Roles;