import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import axios from 'axios';
import toast from 'react-hot-toast';

const CloaUpload = () => {

   const { REACT_APP_API_URL_LOCAL_SERVER, REACT_APP_API_URL_PRODUCTION_SERVER, REACT_APP_ENV_TYPE } = process.env;
   const UPLOADS_URL = REACT_APP_ENV_TYPE === 'production' ? REACT_APP_API_URL_PRODUCTION_SERVER : REACT_APP_API_URL_LOCAL_SERVER;
   

  const navigate = useNavigate();
  const params = useParams();

const [isSubmit, setIsSubmit] = useState(false);

const initialValues = { AcademicDoc:"",Trf:"", VisaCopy:""  };
const [formData, setFormValues] = useState(initialValues);

const [AcademicDoc, setAcademicDoc] = useState(null);
const [Trf, setTrf] = useState(null);
const [passport_number, setPassportNumber] = useState('');
const [passportCopy, setPassportCopy] = useState('');
const [paymentreceipt, setPaymentreceipt] = useState('');

const [visa_number, setVisaNumber] = useState('');
const [FlightTicketUpload, setFlightTicketUpload] = useState('');
const [VisaCopy, setVisaCopy] = useState(null);
const [image, setImage] = useState(null);

const handlePassportChange = (e) => {
   setPassportNumber(e.target.value);
};

 const handleVisaChange = (e) => {
   setVisaNumber(e.target.value);
 };

 const handleFileChangeFlightTicketUpload = (e) => {
   setFlightTicketUpload(e.target.files[0]);
 };

const handleFileChangeAcademicDoc = (e) => {
   setAcademicDoc(e.target.files[0]);
};
const handleFileChangeTrf = (e) => {
   setTrf(e.target.files[0]);
};
const handleFileChangePassport = (e) => {
   setPassportCopy(e.target.files[0]);
};
const handleFileChangeVisaCopy = (e) => {
   setVisaCopy(e.target.files[0]);
};

const handleFileChangePaymentreceipt = (e) => {
   setPaymentreceipt(e.target.files[0]);
};

const handlePhotoChange = (e) => {
   setImage(e.target.files[0]);
};

 const handleUpload = async () => {

   setIsSubmit(true);

      const formData = new FormData();
         formData.append("AcademicDoc", AcademicDoc);
         formData.append("FlightTicketUpload", FlightTicketUpload);
         formData.append("Trf", Trf);
         formData.append('passportCopy', passportCopy);
         formData.append('paymentreceipt', paymentreceipt);
         formData.append('passport_number', passport_number);
         formData.append("VisaCopy", VisaCopy);
         formData.append('visa_number', visa_number);
         formData.append("profile_photo", image);
         formData.append("upload_type", 'cloa');
   try {

      // Upload file using axios
         const uploadResponse = await axios.post(`/api/student/upload/${params.id}`,formData,{
            headers: { "Content-Type": "multipart/form-data" },
            }
         );
         if (uploadResponse.data.success) {
            setIsSubmit(false); 
            // Log an activity using axios
            axios.post('/api/activityLog', {
               log_name: 'Student Document Uploads',
               log_type: 'student',
               log_id: params.id
            });
            
            toast.success(uploadResponse.data.success);
            navigate(`/admin/cloa`); 

         } else {
            setIsSubmit(false); 
            toast.error(uploadResponse.data.error); 
         }
     
   } catch (error) {
      setIsSubmit(false);
      toast.error(error.message);
      console.error('Error uploading files:', error.message);
   }
 };

 useEffect(() => {

   axios.get(`/api/cloa/${params.id}`).then((response) => {
      //console.log(response.data);
      if (response.data && response.data.visa_number) {
         setVisaNumber(response.data.visa_number);
      }else{
         setVisaNumber('0');
      }
      if (response.data && response.data.passport_number) {
         setPassportNumber(response.data.passport_number);
      }else{
         setVisaNumber('0');
      }
      
      setFormValues(response.data);
   });

}, [params.id]);

return (
<>
<div className="wrapper">
   <AdminHeader />
   <AdminSidebar />
   <div className="content-wrapper">
      <section className="content mt-2">
         <div className="row">
            <div className="col-md-12">
               <div className="card card-dark">
                  <div className="card-header">
                     <h3 className="card-title">Upload Documents</h3>
                     <div className="card-tools">
                     <Link to="/admin/cloa" className='btn btn-primary text-white'> Back</Link>
                     </div>
                  </div>
                  <div className="card-body">
                       
                           <div className='row'>
                              <div className="col-md-6">
                                <div className="form-group">
                                   <label htmlFor="LastName">
                                   Academic Document/ECA Submission
                                   </label>
                                   <input name="academic_doc" onChange={handleFileChangeAcademicDoc} type="file" className="input form-control" id="academic_doc" />
                                   {formData && formData.AcademicDoc ? (
                                   <Link download target='_blank' to={UPLOADS_URL+formData.AcademicDoc}>Download</Link>
                                   ) : (<></>)}
                                </div>
                             </div>
                             
                             <div className="col-md-6">
                                <div className="form-group">
                                   <label htmlFor="LastName">
                                   TRF
                                   </label>
                                   <input name="trf" onChange={handleFileChangeTrf} type="file" className="input form-control" id="trf" />
                                   {formData && formData.Trf ? (
                                <Link download target='_blank' to={UPLOADS_URL+formData.Trf}>Download</Link>
                                ) : (<></>)}
                                </div>
                             </div>
                             <div className="col-md-6">
                                <div className="form-group">
                                   <label htmlFor="LastName">
                                   Passport Copy
                                   </label>
                                   <input name="passport" onChange={handleFileChangePassport} type="file" className="input form-control" id="passport" />
                                   {formData && formData.passportCopy ? (
                                <Link download target='_blank' to={UPLOADS_URL+formData.passportCopy}>Download</Link>
                                ) : (<></>)}
                                </div>
                             </div>

                             

                             <div className="col-md-6">
                                <div className="form-group">
                                   <label htmlFor="LastName">
                                   Passport Number (If changed or not submitted before or modified)
                                   </label>
                                   
                                   <input name="passport_number" value={passport_number} onChange={handlePassportChange} type="text" className="input form-control" id="passport_number" placeholder="Passport number" />
                                </div>
                             </div>
                             <div className="col-md-6">
                                <div className="form-group">
                                   <label htmlFor="LastName">
                                   Profile Photo
                                   </label>
                                   <input name="profile_photo"  type="file" className="input form-control" id="profile_photo" onChange={handlePhotoChange} placeholder="Profile Photo" />
                                   {formData && formData.profile_photo ? (
                                      <img width={100} src={UPLOADS_URL+formData.profile_photo} alt='profile' />
                                      ) : (<></>)}
                                </div>
                             </div>
                             
                             <div className="col-md-6">
                                <div className="form-group">
                                   <label htmlFor="LastName">
                                   Payment Transfer Receipt
                                   </label>
                                   <input name="paymentreceipt" onChange={handleFileChangePaymentreceipt} type="file" className="input form-control" id="paymentreceipt" />
                                   {formData && formData.paymentreceipt ? (
                                <Link download target='_blank' to={UPLOADS_URL+formData.paymentreceipt}>Download</Link>
                                ) : (<></>)}
                                </div>
                             </div>
                             
                             <div className="col-md-6">
                                <div className="form-group">
                                   <label htmlFor="LastName">
                                   Visa/UCI Number
                                   </label>
                                   <input name="visa_number"  value={visa_number} onChange={handleVisaChange} type="text" className="input form-control" id="visa_number" placeholder="Visa number" />
                                   
                                </div>
                             </div> 

                             <div className="col-md-6">
                                <div className="form-group">
                                   <label htmlFor="LastName">
                                   Visa Copy
                                   </label>
                                   <input name="visa_copy" onChange={handleFileChangeVisaCopy} type="file" className="input form-control" id="visa_copy" />
                                   {formData && formData.VisaCopy ? (
                                <Link download target='_blank' to={UPLOADS_URL+formData.VisaCopy}>Download</Link>
                                ) : (<></>)}
                                </div>
                             </div>
                             
                             
                             

                             <div className="col-md-6">
                                <div className="form-group">
                                   <label htmlFor="LastName">
                                   Flight Ticket upload
                                   </label>
                                   <input name="flight_ticket_upload" onChange={handleFileChangeFlightTicketUpload} type="file" className="input form-control" id="flight_ticket_upload" />
                                   {formData && formData.FlightTicketUpload ? (
                                   <Link download target='_blank' to={UPLOADS_URL+formData.FlightTicketUpload}>Download</Link>
                                   ) : (<></>)}
                                </div>
                             </div>
                             

                             
                             

                             
                             
                             <div className='col-md-12'>
                             <button onClick={handleUpload} type="submit" className="btn btn-success btn-sm" disabled={isSubmit}>
                                {isSubmit ? 'Verifying...' : 'Upload'}
                             </button>
                             </div>
                          </div>
                      
                  <div>
               </div>
            </div>
         </div>
      </div>
   </div>
        
      </section>
   </div>
   <br/>
   <AdminFooter />
</div>
</>
);
};
export default CloaUpload;