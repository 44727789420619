import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import axios from 'axios';
import toast from 'react-hot-toast';

const CloaTravelLetterEdit = () => {

const initialValues = { travel_letter_approved_rejected: "", travel_letter_message: "" };
const [formData, setFormValues] = useState(initialValues);
const [isSubmit, setIsSubmit] = useState(false);

const params = useParams();
const navigate = useNavigate();


const handleInputChange = (e) => {
   const { name, value } = e.target;
   setFormValues((formData) => ({ ...formData, [name]: value }));
};

 const handleSubmit = (e) => {
   e.preventDefault();
   setIsSubmit(true);
   axios.put(`/api/cloatravelreqUpdate/${params.id}`, formData)
     .then((response) => {
       if(response.data.success){
          toast.success(response.data.success);

          axios.post('/api/activityLog', {
             log_name: 'Cloa Approved Travel Letter Request',
             log_type: 'loa',
             log_id: params.id
          });
             // Clear the input fields
             setFormValues(initialValues);
             
            navigate(`/admin/cloa-travel-letter`);

       }else{
          toast.error(response.data.error);
       }
     })
     .catch((error) => {
       console.error('Submission failed', error);
     })
     .finally(() => {
       setIsSubmit(false);
     });
   

 };

useEffect(() => {  
      axios.get(`/api/cloa/${params.id}`).then((response) => {
         setFormValues(response.data);
      });
}, [params.id]);



return (
<>
<div className="wrapper">
   <AdminHeader />
   <AdminSidebar />
   <div className="content-wrapper">
      <section className="content mt-2">
         <div className="row">
            <div className="col-md-12">
               <div className="card card-dark">
                  <div className="card-header">
                     <h3 className="card-title">Approved Travel Letter Request</h3>
                     <div className="card-tools">
                        <button
                           type="button"
                           className="btn btn-tool"
                           data-card-widget="collapse"
                           title="Collapse"
                           >
                        <i className="fas fa-minus"></i>
                        </button>
                     </div>
                  </div>
                  <div className="card-body">
                        <form onSubmit={handleSubmit}>
                           <div className='row'>

                          
                           <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="campus_id">
                                    Travel Letter Request
                                    </label>
                                    <select id="travel_letter_approved_rejected" name="travel_letter_approved_rejected" value={formData.travel_letter_approved_rejected} onChange={handleInputChange} className="form-control custom-select">
                                       <option value="">Select</option>
                                       <option value="1">Approved</option>
                                       <option value="2">Rejected</option>
                                    </select>
                                 </div>
                              </div>


                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                     Remark 
                                    </label>
                                    <input name="travel_letter_message" value={formData.travel_letter_message} onChange={handleInputChange} type="text" className="input form-control" id="travel_letter_message" placeholder="Remark (Optional)" />
                                 </div>
                              </div>
                             
                             

                              <div className='col-md-12'>
                              <button type="submit" className="btn btn-success btn-sm" disabled={isSubmit}>
                                 {isSubmit ? 'Waiting...' : 'Submit'}
                              </button>
                              </div>
                           </div>
                        </form>
                  <div>
               </div>
            </div>
         </div>
      </div>
   </div>
        
      </section>
   </div>
   <br/>
   <AdminFooter />
</div>
</>
);
};
export default CloaTravelLetterEdit;