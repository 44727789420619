import React, { useState, useEffect } from 'react'
import { useParams,Link,useNavigate } from "react-router-dom";
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import axios from 'axios';
import toast from 'react-hot-toast';

const StudentVerify = () => {

const [dataList, setData] = useState([]);
const [isSubmit, setIsSubmit] = useState(false);
const params = useParams();
const navigate = useNavigate();
const [verify_opt, setVerifyOpt] = useState('');

const { REACT_APP_API_URL_LOCAL, REACT_APP_API_URL_PRODUCTION, REACT_APP_ENV_TYPE } = process.env;
const baseEndpoint = REACT_APP_ENV_TYPE === 'production' ? REACT_APP_API_URL_PRODUCTION : REACT_APP_API_URL_LOCAL;

const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    
    const agent_payment_amount = dataList.created_by ? dataList.created_by.agent_payment_amount : null;
    const agent_payment_status = dataList.created_by ? dataList.created_by.agent_payment_status : null;

    const verifyOTP = verify_opt;
    const db_otp = dataList ? dataList.randomNumber : null;

    if(db_otp !== verifyOTP){
        setIsSubmit(false);
         toast.error('OTP is not verified');
         
    }else{

    if(agent_payment_status === 'enable'){
        fetch("/api/create-checkout-session", {
            method:"POST",
            headers:{
              "Content-Type":"application/json"
            },
            mode:"cors",
            body: JSON.stringify({
              items: [
                {id:1, quantity: 1, price: agent_payment_amount, name: 'Student Payment'}
              ],
             
             success_url: `${baseEndpoint}/admin/student/verifydownload/${params.id}?issendmail=true`,
             cancel_url: `${baseEndpoint}/admin/student/verify/${params.id}`
    
            })
          })
          .then(res => {
            setIsSubmit(false);
            if (res.ok) return res.json()
            return res.json().then(json => Promise.reject(json))
          })
          .then(({url})=>{
            window.location = url;
            setIsSubmit(false);
          })
          .catch(e => {
            setIsSubmit(false);
            console.log(e.error)
          })
    }else{
        axios.put(`/api/student/${params.id}`, {'verify':'1','status':'active','email':dataList.email})
        .then((response) => {
            if(response.data.success){
                toast.success(response.data.success);
                // Clear the input fields
                navigate(`/admin/student/verifydownload/${params.id}?issendmail=true`);
    
            }else{
                toast.error(response.data.error);
            }
        })
        .catch((error) => {
            console.error('Submission failed', error);
        })
        .finally(() => {
            setIsSubmit(false);
        });
    }
 
    }
    
};

useEffect(() => {
      axios.get(`/api/studentverify/${params.id}`).then((response) => {
        setData(response.data);
      });
 }, [params.id]);


return (
<>
<div className="wrapper">
   <AdminHeader />
   <AdminSidebar />
   <div className="content-wrapper">
      <section className="content mt-2">
         <div className="row">
            <div className="col-md-12">
               <div className="card card-dark">
                  <div className="card-header">
                     <h3 className="card-title">Verify Details</h3>
                     <div className="card-tools">
                        <Link to={`/admin/student/edit/${params.id}`} className="btn btn-tool" title="Collapse">Back</Link>
                     </div>
                  </div>
                  <div className="card-body">
                    
                    <div className="row">
                        <div className="col-md-3">
                            <label className="" htmlFor="FirstName">
                            First Name
                            </label>
                        </div>
                        <div className="col-md-9">{dataList.firstName}</div>
                        <div className="col-md-3">
                            <label className="" htmlFor="LastName">
                            Last Name
                            </label>
                        </div>
                        <div className="col-md-9">
                            {dataList.lastName}
                        </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="Emaiil">
                            Email
                            </label>
                        </div>
                        <div className="col-md-9">
                            {dataList.email}
                        </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="DateOfBirth" style={{textTransform: 'none'}}>
                            Date of Birth
                            </label>
                        </div>
                        <div className="col-md-9"> {dataList.dob} </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="Address">
                            Address
                            </label>
                        </div>
                        <div className="col-md-9">
                            <p>                           {dataList.unit}<br />{dataList.street_no},<br />
                            {dataList.street_name},<br />{dataList.city},<br />{dataList.state},<br />{dataList.country},<br />{dataList.postal_code} 
                            </p>
                        </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="StudyProgram">
                            Study Program
                            </label>
                        </div>
                        <div className="col-md-9">
                        {dataList.study_program_id ? (
                            
                            <p>{dataList.study_program_id.program}</p>
                            
                        ) : (
                            <p>N/A</p>
                        )}
                        </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="PrepaidFees">
                            Prepaid Fees
                            </label>
                        </div>
                        <div className="col-md-9">
                            {dataList.prepaid_fee}
                        </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="StDate">
                            Start Date
                            </label>
                        </div>
                        <div className="col-md-9">
                            {dataList.st_date}
                        </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="StDate">
                            End Date
                            </label>
                        </div>
                        <div className="col-md-9">
                            {dataList.end_year_date}
                        </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="StDate">
                            Expiration Date
                            </label>
                        </div>
                        <div className="col-md-9">
                            {dataList.expire_date}
                        </div>
                        <div className="col-md-3">
                            <label className="" htmlFor="StDate">
                            Payment Status
                            </label>
                        </div>
                        <div className="col-md-9">
                        {dataList.created_by ? (
                            
                            <p>{dataList.created_by.agent_payment_status}</p>
                            
                        ) : (
                            <p>N/A</p>
                        )}
                        </div>
                        
                        <div className="col-md-6 mb-10" style={{marginBottom: '10px'}}>
                        <input
                            type="text"
                            id="verify_opt"
                            name="verify_opt"
                            value={verify_opt}
                            placeholder='Verify opt'
                            onChange={({ target }) => setVerifyOpt(target.value)}
                            className="form-control"
                        />
                        </div>
                        <div className='col-md-12'>
                            <button type="submit" className="btn btn-success btn-sm" onClick={handleSubmit} disabled={isSubmit}>
                                {isSubmit ? 'Wait...' : 'Submit'}
                            </button>
                        </div>
                    </div>
                  <div>
               </div>
            </div>
         </div>
      </div>
   </div>
        
      </section>
   </div>
   <br/>
   <AdminFooter />
</div>
</>
);
};
export default StudentVerify;