import React, { useState, useEffect,useCallback  } from 'react'
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';


const CommunicationStatus = () => {
  const userData = useSelector((state) => state.user);
  // Create role
  const [tasks, setTasks] = useState([]);
  const [editTask, setEditTask] = useState(null);

  const initialValues = {
    date_applied: "",
    first_name: "",
    last_name: "",
    dob: "",
    campus_id: "",
    study_program_id: "",
    status: "",
    created_by: "",
    agent_remark: "",
    admin_officer_remark: "",
    prepaid_fee: "",
    paid_fee: "",
  };
  const [formData, setFormData] = useState(initialValues);

  const getcommlist = useCallback(() => {
    const user_id = btoa(userData._id);
    const authtype = btoa(userData.authtype);
    axios.get(`/api/comm/${user_id}/${authtype}`).then((response) => {
      setRecords(response.data);
    });

    axios.get(`/api/comm/countByStatus/${user_id}/${authtype}`).then((response) => {
      setCountRecords(response.data);
    });


  }, [userData._id, userData.authtype]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    if (name === 'campus_id') {
      if (value) {
        getstudyprogram(value);
      } else {
        setStudyProgramRecords([]);
      }
    }
  };

  const addTask = () => {
    axios.post('/api/comm', formData).then((response) => {
      if (response.data.success) {
        toast.success(response.data.success);
        getcommlist();
        // Clear the input fields
        setFormData(initialValues);
      } else {
        toast.error(response.data.error);
      }
    });
  }

  // Update Role
  const updateTask = () => {
    axios.put(`/api/comm/${editTask._id}`, formData).then((response) => {
      const updatedTasks = tasks.map((task) =>
        task._id === response.data._id ? response.data : task
      );

      if(response.data.success){
        toast.success(response.data.success);
        getcommlist();
      }else{
        toast.error(response.data.error);
      }

      setTasks(updatedTasks);
      setFormData(initialValues);
      setEditTask(null);
    });
  };

  // Edit Role
  const editTaskClick = (task) => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Add smooth scrolling behavior
    });
   // console.log('task',task);
    setEditTask(task);
    getstudyprogram(task.campus_id);

    setFormData({
      ...task,
      study_program_id: task.study_program_id._id, // Assuming task.study_program_id is an object with an _id property
    });
  };

  // Delete Role
  const deleteTask = (id) => {

    const isConfirmed = window.confirm('Are you sure you want to delete this item?');
    if (isConfirmed) {
    
    axios.delete(`/api/comm/${id}`).then((response) => {

      if(response.data.success){
        toast.success(response.data.success);
        getcommlist();
      }else{
        toast.error(response.data.error);
      }

      setTasks(tasks.filter((task) => task._id !== id));
    });

    }

  };


  // DataTables

  useEffect(() => {
    const dataFetch = async () => {
      try{
          const data = await ( await fetch("/getAuth",{
                      method: "GET",
                      headers: {
                          Accept: "application/json", "Content-Type": "application/json"
                      },
                      credentials: "include"
                  })).json();

            
                  setFormData((formData) => ({ ...formData, created_by: data._id}));
            

      }catch(err){
              console.log(err);
      }
  };
  dataFetch();
 
    // Fetch tasks when component mounts
    axios.get('/api/campus').then((response) => {
        setCampusRecords(response.data);
      });

      getcommlist();
      
  }, [getcommlist]);



 
 
  const columns = [
    {
      name: 'Date Applied',
        selector: row=> row.date_applied,
        sortable: true
    },
    { 
        name: 'First Name',
        selector: row=> row.first_name,
        sortable: true
    },
    { 
        name: 'Last Name',
        selector: row=> row.last_name,
        sortable: true
    },
    { 
        name: 'DOB',
        selector: row=> row.dob,
        sortable: true
    },
    { 
        name: 'Status',
        selector: row=> row.status,
        sortable: true
    },
    { 
        name: 'Created By',
        selector: row=> row.created_by_name,
        sortable: true
    },
    { 
        name: 'First Name',
        selector: row=> row.first_name,
        sortable: true
    },
    { 
        name: 'Agent Remark',
        selector: row=> row.agent_remark,
        sortable: true
    },
    { 
        name: 'Admin Officer Remark',
        selector: row=> row.admin_officer_remark,
        sortable: true
    },
    { 
        name: 'Prepaid Fee',
        selector: row=> row.prepaid_fee,
        sortable: true
    },
    { 
        name: 'Paid Fee',
        selector: row=> row.paid_fee,
        sortable: true
    },
    { 
        name: 'Program',
        selector: row=> row.program,
        sortable: true
    },
    { name: 'Action', selector: function(row) {
        return (
          <>
          <i className='fa fa-edit mr-2 text-info' onClick={() => editTaskClick(row)}></i>
          <i className='fa fa-trash  text-danger' onClick={() => deleteTask(row._id)}></i>
          
          </>
        );
        
      } 
    }
    
    
  ];


  const [records, setRecords] = useState([]);
  const [statusCounts, setCountRecords] = useState([]);
  
  const [campusList, setCampusRecords] = useState([]);
  const [studyProgramList, setStudyProgramRecords] = useState([]);
  
  const getstudyprogram = (campus_id) => {
    axios.get(`/api/studyprogram/${campus_id}`).then((response) => {
       setStudyProgramRecords(response.data);
     });
  }
 


  function handleFilter(event){
    const inputValue = event.target.value.toLowerCase();
    if(inputValue){
      const newData = records.filter(row => {
        return (
          row.date_applied.toLowerCase().includes(inputValue) ||
          row.first_name.toLowerCase().includes(inputValue) ||
          row.last_name.toLowerCase().includes(inputValue) ||
          row.dob.toLowerCase().includes(inputValue) ||
          row.status.toLowerCase().includes(inputValue) ||
          row.created_by_name.toLowerCase().includes(inputValue) ||
          row.agent_remark.toLowerCase().includes(inputValue) ||
          row.admin_officer_remark.toLowerCase().includes(inputValue) ||
          row.prepaid_fee.toLowerCase().includes(inputValue) ||
          row.paid_fee.toLowerCase().includes(inputValue) ||
          row.program.toLowerCase().includes(inputValue)
        );
      });
      setRecords(newData);

    }else{
      getcommlist();
    }
  }


  return (
    <>
    
      <div className="wrapper">
        <AdminHeader />

        <AdminSidebar />

        <div className="content-wrapper">

        <section className="content mt-2">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-dark">
                  <div className="card-header">
                    
                    <h3 className="card-title">{editTask ? 'Edit Communication Status' : 'Add Communication Status'}</h3>

                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className='row'>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <label htmlFor="inputName">Date Applied</label>
                        <input
                          type="date"
                          id="inputName"
                          name="date_applied"
                          value={formData.date_applied}
                          placeholder='Enter Date Applied'
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className="form-group">
                        <label htmlFor="inputName">First Name</label>
                        <input
                          type="text"
                          id="inputName"
                          name="first_name"
                          value={formData.first_name}
                          placeholder='Enter First Name'
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <label htmlFor="inputName">Last Name</label>
                        <input
                          type="text"
                          id="inputName"
                          name="last_name"
                          value={formData.last_name}
                          placeholder='Enter Last Name'
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className="form-group">
                        <label htmlFor="inputName">DOB</label>
                        <input
                          type="date"
                          id="inputName"
                          name="dob"
                          value={formData.dob}
                          placeholder='Enter DOB'
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                        <label htmlFor="campus_id">
                        Campus
                        </label>
                        <select id="campus_id" name="campus_id" value={formData.campus_id} onChange={handleInputChange} className="form-control custom-select">
                            <option value="">Select</option>
                            {campusList.map((cam) => (
                                <option value={cam._id}>{cam.campus}</option>
                            ))}
                        </select>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                        <label htmlFor="LastName">
                        Study Program
                        </label>
                        <select id="inputStudyProgram" name="study_program_id" value={formData.study_program_id} onChange={handleInputChange} className="form-control custom-select">
                            <option value="">Select</option>
                            {studyProgramList.map((cam) => (
                                <option value={cam._id}>{cam.program}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    
                      <div className='col-md-6'>
                        <div className="form-group">
                            <label htmlFor="inputStatus">Status</label>
                            <select
                              id="inputStatus"
                              name="status"
                              value={formData.status}
                              onChange={handleInputChange}
                              className="form-control custom-select"
                            >
                                <option value="">Select Status</option>
                                <option value="APPLIED">APPLIED</option>
                                <option value="CLOA ISSUED">CLOA ISSUED</option>
                                <option value="DOCUMENTATION COMPLETE">DOCUMENTATION COMPLETE</option>
                                <option value="LOA ISSUED">LOA ISSUED</option>
                                <option value="LETTER OF TRAVEL ISSUED">LETTER OF TRAVEL ISSUED</option>
                                <option value="VISA APPLIED">VISA APPLIED</option>
                                <option value="VISA DECLINE">VISA DECLINE</option>
                            </select>
                          </div>
                      </div>

                    <div className='col-md-6'>
                      <div className="form-group">
                        <label htmlFor="inputName">Remarks From Agent</label>
                        <input
                          type="text"
                          id="inputName"
                          name="agent_remark"
                          value={formData.agent_remark}
                          placeholder='Enter Remarks From Agent'
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <label htmlFor="inputName">Remarks By Admission Officer</label>
                        <input
                          type="text"
                          id="inputName"
                          name="admin_officer_remark"
                          value={formData.admin_officer_remark}
                          placeholder='Enter Remarks By Admission Officer'
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <label htmlFor="inputName">Prepaid Fee</label>
                        <input
                          type="number"
                          id="inputName"
                          name="prepaid_fee"
                          value={formData.prepaid_fee}
                          placeholder='Enter Prepaid Fee'
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <label htmlFor="inputName">Paid Fee</label>
                        <input
                          type="number"
                          id="inputName"
                          name="paid_fee"
                          value={formData.paid_fee}
                          placeholder='Enter Paid Fee'
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                      <div className='col-md-12'>
                      {editTask ? (
                              <input
                                    type="submit"
                                    value="Update Status"
                                    onClick={updateTask}
                                    style={{ width: "125px", margin: "10px" }}
                                    className="btn btn-success btn-sm"
                                  />
                          ) : (
                
                          <input
                                type="submit"
                                value="Add Status"
                                onClick={addTask}
                                style={{ width: "125px", margin: "10px" }}
                                className="btn btn-success btn-sm"
                              />
                        )}
                      </div>
                    </div>
                    

                    

                    
                  </div>
                </div>
              </div>
            </div>
            <br />
          </section>


          <section className="content">
            <div className="">
              <div className="row">
                <div className="col-12">
                  
                  <div className="card card-dark">
                    

                    <div className="card-header">
                      <h3 className="card-title">Communication Status Listing</h3>
                      <input type='text' onChange={handleFilter} style={{float:'right',width:'200px'}} placeholder="Search..." className='form-control'/>
                    </div>

                    <div className="card-body">
                    <DataTable columns={columns} data={records} pagination></DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="">
              <div className="row">
                <div className="col-12">
                  
                  <div className="card card-dark">
                    

                    <div className="card-header">
                      <h3 className="card-title">Status Count</h3>
                    </div>

                    <div className="card-body">
                            <table className='table table-responsive table-strip'>
                            {Object.entries(statusCounts).map(([status, count]) => (
                              <tr key={status}>
                                <th>{status}</th>
                                <td>{count}</td>
                              </tr>
                            ))}
                            </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
        <br/>
        <AdminFooter />
      </div>
    </>
  );
};

export default CommunicationStatus;