import React , { useState } from 'react'
import { useNavigate, Link } from "react-router-dom";
import "../../admin_layout/vendor/fontawesome-free/css/all.min.css";
import "../../admin_layout/css/sb-admin-2.min.css";
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../actions/userActions';
import Logo from "../../images/loa-logo.png";

import axios from 'axios';

const AdminLoginform = () => {

    const dispatch = useDispatch();

    const navigate  = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const AdminloginUser = async (e) => {
        e.preventDefault();

        const res = await fetch("/adminsignin", {
            method: "POST",
            headers: {
              "Content-Type":"application/json"
            },
            body: JSON.stringify({
              email:email, 
              password:password
            })
        });

        const data = await res.json();
        
        if(res.status === 400 || !data){
            toast.error("Invalid credential");
        }else{

            if (data && data.user && data.user._id) {
            axios.post('/api/activityLog', {
                log_name: 'Admin Login',
                log_type: 'user',
                log_id: data.user._id
             });
            }

            dispatch(loginUser(data.user));
            localStorage.setItem('Admin','AdminValue');
            localStorage.setItem('userInfo',JSON.stringify(data.user));
            toast.success("Login Successfully !!");
            navigate('/admin/dashboard');
        }
    

    }

    return (
    <>
        <div className="">
            <div className="container">

                <div className="row justify-content-center">

                    <div className="col-xl-10 col-lg-12 col-md-9">
                        <div className="o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">

                                    <div className="col-lg-6 d-none d-lg-block img_left">
                                    <Link target='_blank' to="/"><img alt="logo.jpg" src={Logo}/></Link>
                                    </div>

                                    <div className="col-lg-6" style={{ background: '#fff'}} >
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">Welcome to Administrator!</h1>
                                            </div>
                                            <form className="user" method='POST'>
                                                <div className="form-group">
                                                    
                                                        <input
                                                            className="form-control form-control-user"
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            placeholder="Enter Email Address..."
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            required
                                                        />
                                                </div>
                                                <div className="form-group">
                                                    
                                                    <input
                                                        className="form-control form-control-user"
                                                        type="password"
                                                        name="password"
                                                        id="password"
                                                        placeholder="Password"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        required
                                                    />
                                                </div><br/>
                                              
                                                <button
                                                    name="signup"
                                                    id="signup"
                                                    type="submit"
                                                    onClick={AdminloginUser}
                                                    className="btn btn-facebook btn-user btn-block"
                                                    >
                                                    <i className="fab fa-user fa-fw"></i> Login
                                                </button>
                                                
                                                
                                            </form>
                                            
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

            </div>
    </>
  )
}

export default AdminLoginform