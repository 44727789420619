import React, { useState, useEffect } from 'react'
import { useParams,Link,useLocation,useNavigate  } from "react-router-dom";
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import "./Cloapdf.css";
import axios from 'axios';
import html2pdf from 'html2pdf.js'
import numeral from 'numeral';
import toast from 'react-hot-toast';

const CloaPdfView = () => {

const [dataList, setData] = useState([]);
const params = useParams();
const navigate = useNavigate();


const location = useLocation();
const searchParams = new URLSearchParams(location.search);
const pdfType = searchParams.get('pdfType');

const { REACT_APP_API_URL_LOCAL, REACT_APP_API_URL_PRODUCTION, REACT_APP_ENV_TYPE } = process.env;
const baseEndpoint = REACT_APP_ENV_TYPE === 'production' ? REACT_APP_API_URL_PRODUCTION : REACT_APP_API_URL_LOCAL;
const prefix = 'A';
const randomPart = Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
const invoiceNumber =  `${prefix}${randomPart}`;
const downloadPdf = (data) => {
    // Check if data is available and has the required properties
    if (data && data.cloa_id && data.firstName) {
      const pdfname = data.cloa_id+'_'+data.firstName+'_CLOA_Form.pdf';
      const element = document.getElementById('cloapdfdownload');

      // Configuration options for html2pdf
      const options = {
        filename: pdfname,
        margin: 10,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      html2pdf().set(options).from(element).toPdf().get('pdf').then((pdf) => {
         const pdfData = btoa(pdf.output());
         fetch(`${baseEndpoint}/save-pdf`, {
           method: 'POST',
           headers: {
             'Content-Type': 'application/json',
           },
           body: JSON.stringify({alldata:data,id:params.id,data: pdfData,pdfname: pdfname,type:'cloa',field_type:'form_field' }),
         })
           .then((response) => {
             if (response.ok) {
               toast.success('File saved on server');
               navigate(`/admin/cloa/verifydownload/${params.id}`);
               // send code here
               
             } else {
               toast.error('Failed to save file on server');
             }
           })
           .catch((error) => {
             toast.error('Error saving file on server');
             console.error('Error saving file on server:', error);
           });
   
        });

      // Navigate back to the previous page
     // navigate(-1);
    } else {
     // console.error('Invalid data format or data not available:', data);
      // Handle the case where data is not in the expected format or not available
    }
  };

  useEffect(() => {
   let isMounted = true;  // Flag to check if the component is still mounted
 
   const fetchData = async () => {
     try {
       const response = await axios.get(`/api/cloa/${params.id}`);
       if (isMounted) {
         setData(response.data);
 
         // Set a timeout for 5 seconds after data fetching
         const timeoutId = setTimeout(() => {
           // Your code to run after 5 seconds
           if (pdfType === 'pdfform') {
             // downloadPdf(response.data);
           }
           if (pdfType === 'pdfreceipt') {
             downloadPdfReceipt(response.data);
           }
         }, 100);
 
 
         // Cleanup function to clear the timeout in case the component unmounts
         return () => {
           clearTimeout(timeoutId);
         };
       }
     } catch (error) {
       console.error('Error fetching data:', error);
     }
   };
 
   fetchData();
 
   // Cleanup function to set the isMounted flag to false when the component unmounts
   return () => {
     isMounted = false;
   };
 }, [params.id, pdfType, navigate]);


 const downloadPdfReceipt = (data) =>{

   if (data && data.cloa_id && data.firstName) {
   const pdfname_receipt = data.cloa_id+'_'+data.firstName+'_CLOA_Receipt.pdf';
   const element_receipt = document.getElementById('loareceipt');

   // Configuration options for html2pdf
   const options_receipt = {
     filename: pdfname_receipt,
     margin: 10,
     image: { type: 'jpeg', quality: 0.98 },
     html2canvas: { scale: 2 },
     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
   };
 
   html2pdf().set(options_receipt).from(element_receipt).toPdf().get('pdf').then((pdf) => {
      const pdfData = btoa(pdf.output());
      fetch(`${baseEndpoint}/save-pdf`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ alldata:data,id:params.id,data: pdfData,pdfname: pdfname_receipt,type:'cloa',field_type:'receipt_field' }),
      })
      .then((response) => {
        if (response.ok) {
          // Second form download
          downloadPdf(data);
        } else {
          toast.error('Failed to save file on server');
        }
      })
      .catch((error) => {
        toast.error('Error saving file on server');
        console.error('Error saving file on server:', error);
      });

     });
   
   }
   
   // Navigate back to the previous page
  // navigate(-1);
   
 }


 const formattedDate_issue_date = typeof dataList.issue_date === 'string' ? dataList.issue_date.replace(/-/g, '/') : '';
 const formattedDate_created_date = typeof dataList.created_date === 'string' ? dataList.created_date.replace(/-/g, '/') : '';
 const formattedDate_st_date = typeof dataList.st_date === 'string' ? dataList.st_date.replace(/-/g, '/') : '';
 const formattedDate_end_year_date = typeof dataList.end_year_date === 'string' ? dataList.end_year_date.replace(/-/g, '/') : '';
 const formattedDate_expire_date = typeof dataList.expire_date === 'string' ? dataList.expire_date.replace(/-/g, '/') : '';
 const formattedDOB = typeof dataList.dob === 'string' ? dataList.dob.replace(/-/g, '/') : '';
 

const ucfirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const formatted_program_Fees = dataList.campus_id ? numeral(dataList.program_fees).format('0,0.00') : null;

const formatted_prepaid_fee = dataList.campus_id ? numeral(dataList.prepaid_fee).format('0,0.00') : null;
const formatted_remaining_fees = dataList.campus_id ? numeral(dataList.remaining_fees).format('0,0.00') : null;



return (
<>
<div className="wrapper">
   <AdminHeader />
   <AdminSidebar />
   <div className="content-wrapper">
      <section className="content mt-2">
         <div className="row">
            <div className="col-md-12">
               <div className="card card-dark">
                  <div className="card-header">
                     <h3 className="card-title">Welcome {dataList.firstName}</h3>
                     <div className="card-tools">
                        <Link to={`/admin/cloa/edit/${params.id}`} className="btn btn-tool" title="Collapse">Back</Link>
                     </div>
                  </div>
                  <div className="card-body">
                    <div className='row mb-5'>
                        
                    </div>
           
                     {/* {pdfType === 'pdfform' ? ( */}
                     <div id="cloapdfdownload">
               {/* CLOA start */}
               <div className="page-wrapper">
                  <div className="page page-appendix-header">
                     <table border={0} cellPadding={0} cellSpacing={0} style={{padding: 15, width: '100%', marginLeft: 0, marginRight: 0, paddingBottom: 15}} align="center">
                     <tbody>
                        <tr>
                           <td width="50%">
                           <a href="javascript:void(0)" style={{outline: 'none'}} tabIndex={-1} target="_blank"> 
                              <img align="center" alt="Logo" border={0} src="/front/img/academy_of_learning_logo.png" style={{textDecoration: 'none', msInterpolationMode: 'bicubic', height: 'auto', border: 0, width: 250, display: 'block'}}/>
                           </a>
                           </td>
                           <td className="header-text-wrap" width="25%" style={{textAlign: 'right', paddingBottom: 35}}>
                           <h4 style={{margin: 0}}><strong>Mississauga West Campus</strong></h4>
                           <p>500-3660 Hurontario Street </p>
                           <p>Mississauga, ON L5B 3C4</p>
                           <p>T: (905) 306-0666</p>
                           <p>F: (647) 948-5571</p>
                           </td>
                           <td className="header-text-wrap" width="25%" style={{textAlign: 'right', paddingBottom: 35}}>
                           <h4 style={{margin: 0}}><strong>Kingston Campus</strong></h4>
                           <p>1469 Princess Street</p>
                           <p>Kingston, ON K7M 3E9</p>
                           <p>T: (613) 544-8973</p>
                           <p>F: (613) 544-8980</p>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{margin: 'auto'}}>
                     <tbody>
                        <tr>
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td>
                                    <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 17, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'center', msoLineHeightAlt: 17, textTransform: 'uppercase'}}>
                                       <strong>CONDITIONAL OFFER OF ADMISSION</strong>                                   
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 10, paddingRight: 15, paddingLeft: 15, margin: 'auto'}}>
                     <tbody>
                        <tr>
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td>
                                    <p style={{marginBottom:'10px',fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'center', msoLineHeightAlt: 17, fontStyle: 'italic', color: '#FF1E1E'}}>
                                       **NOTE: This letter is NOT to be used for Study Permit application purposes.** 
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <table style={{width: '100%', marginLeft: 0, marginRight: 0, paddingTop: 10, paddingBottom: 15}} border={0} cellPadding={0} cellSpacing={0} align="center">
                     <tbody>
                        <tr>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Date of Issue: </strong>
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                           { formattedDate_issue_date }
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Student Name:</strong>
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                           {dataList.firstName+' '+dataList.lastName}
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>DOB:</strong> <span style={{fontSize: 10, fontStyle: 'italic'}}> (YYYY/MM/DD)</span>
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                               {formattedDOB}
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Address:</strong>
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                           {dataList.unit+' '+dataList.street_no+' '+dataList.street_name ? dataList.street_name : null} 
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>City: </strong>
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                           {dataList.city ? ucfirst(dataList.city) : null}
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Province/State:</strong>
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              {dataList.state ? dataList.state : null}
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Country:</strong>
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                           {dataList.country}
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Postal Code:</strong>
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                           {dataList.postal_code}
                           </p>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <table style={{width: '100%', marginLeft: 0, marginRight: 0}} border={0} cellPadding={0} cellSpacing={0} align="center">
                     <tbody>
                        <tr>
                           <td width={35}>
                           <p style={{ marginTop: 10,marginBottom: 10,fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              Dear 
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                           {dataList.firstName}<span>,</span>
                           </p>
                           </td>
                        </tr>
                     </tbody>                      
                     </table>
                     <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 10, paddingBottom: 10, paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0}}>
                     <tbody>
                        <tr>
                           <td>
                           <p style={{marginTop: 10,marginBottom: 10,fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17, color: '#000000'}}>
                              Congratulations! Based on the assessment of your application, we are pleased to offer you <strong style={{textTransform: 'uppercase'}}>CONDITIONAL</strong> admission into the 
                              following full-time program at Academy of Learning Career College: 
                           </p>
                           </td>
                        </tr>
                     </tbody>
                     </table>            
                     
                     <table style={{width: '100%', marginLeft: 0, marginRight: 0, paddingTop: 15, paddingBottom: 0}} border={0} cellPadding={0} cellSpacing={0} align="center">
                     <tbody>
                        <tr bgcolor="#DDDDDD">
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td>
                                    <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'center', msoLineHeightAlt: 17, textTransform: 'uppercase'}}>
                                       <strong>PROGRAM INFORMATION</strong>
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <table style={{marginTop: 10,marginBottom:10,width: '100%', marginLeft: 0, marginRight: 0, paddingTop: 15, paddingBottom: 0}} border={0} cellPadding={0} cellSpacing={0} align="center">
                     <tbody>
                        <tr>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Program Name:</strong> 
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                           {dataList.study_program_id ? dataList.study_program_id.program : null}
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Campus Location:</strong>
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                           {dataList.campus_id ? dataList.campus_id.campus : null} 
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Level of Study:</strong>
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              Diploma
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Type of Program:</strong> 
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              Academic 
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Academic Status: </strong>
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              Full-Time
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Weekly Hours of Instruction:</strong>
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              25
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Program Start Date: </strong>
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                           {formattedDate_st_date}
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Expected Completion Date:</strong>
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                           {formattedDate_end_year_date}
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Total Fees (CAD): </strong>
                           </p>
                           </td>
                           <td>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              ${formatted_program_Fees}
                           </p>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 15, paddingBottom: 15, paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0}}>
                     <tbody>
                        <tr bgcolor="#DDDDDD">
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td>
                                    <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'center', msoLineHeightAlt: 17, textTransform: 'uppercase'}}>
                                       <strong>INSTITUTION INFORMATION</strong>
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                        <tr>
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td>
                                    <p style={{marginTop:10,marginBottom:10,fontFamily: '"Roboto", sans-serif', fontSize: 14, marginTop: 15, lineHeight: '1.4', wordBreak: 'break-word', msoLineHeightAlt: 17, textAlign: 'justify'}}>
                                       <span style={{}}><strong> Academy of Learning Career College</strong></span> is registered with the Ministry of Colleges and Universities in the Province of 
                                       Ontario. It is listed on Service Ontario as a Private Career College in good standing, and it is a Designated Learning 
                                       Institution (DLI #O19303790322). 
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 15, paddingBottom: 15, paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0}}>
                     <tbody>
                        <tr bgcolor="#D2001A">
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td>
                                    <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'center', color: '#fff', msoLineHeightAlt: 17, textTransform: 'uppercase'}}>
                                       <strong>IMPORTANT NOTE TO STUDENTS </strong>
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                        <tr bgcolor="#FFE4C0">
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td>
                                    <p style={{marginTop: 10,marginBottom:10,fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', msoLineHeightAlt: 17, textAlign: 'justify'}}>
                                       Please be reminded that you MUST have a valid study permit as issued to you by Immigration, Refugees and 
                                       Citizenship Canada (IRCC), as well as have valid medical and health insurance in place for the entire duration of 
                                       your study program. A copy of your valid study permit, as well as a copy of your valid medical and health insurance 
                                       policy, must be provided to Academy of Learning Career College  <span style={{}}><strong> before</strong> </span>you begin your studies.
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <table border={0} cellPadding={0} cellSpacing={0} align="center">
                     <tbody>
                        <tr>
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td>
                                    <p style={{marginTop: 10,marginBottom:10,fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', msoLineHeightAlt: 17, textAlign: 'justify'}}>
                                       To proceed with the enrolment process toward a full offer, you must meet all admission requirements for the above 
                                       program, review and sign the "Declaration and Signatures" section, and submit your fee deposit via wire transfer to 
                                       the following financial institution: 
                                    </p>
                                 </td>
                                 </tr>
                                 <tr>
                                 <td>
                                    <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', msoLineHeightAlt: 17, textAlign: 'right', fontStyle: 'italic'}}>
                                       (Continued)
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                  </div>
                  <div className="footer-badge-wrap" style={{pageBreakAfter: 'always'}}>
                     <table width="100%" border={0} cellPadding={0} cellSpacing={0} align="center" style={{margin: 0, width: '100%', borderCollapse: 'collapse', borderWidth: 0, padding: 0}}>
                     <tbody>
                        <tr>
                           <td width="33.333333%" bgcolor="#aacd3f" style={{padding: 15}}>
                           </td>
                           <td width="33.333333%" bgcolor="#ea7023" style={{padding: 15}}>
                           </td>
                           <td width="33.333333%" bgcolor="#5ac7db" style={{padding: 15}}>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                  </div>
               </div>
               <div className="page-wrapper">
                  <div className="page page-appendix-header">
                     <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0, padding: 15, paddingBottom: 15}} align="center">
                     <tbody>
                        <tr>
                        <td width="50%">
                           <a href="javascript:void(0)" style={{outline: 'none'}} tabIndex={-1} target="_blank"> 
                              <img align="center" alt="Logo" border={0} src="/front/img/academy_of_learning_logo.png" style={{textDecoration: 'none', msInterpolationMode: 'bicubic', height: 'auto', border: 0, width: 250, display: 'block'}} />
                           </a>
                           </td>
                           <td className="header-text-wrap" width="25%" style={{textAlign: 'right', paddingBottom: 35}}>
                           <h4 style={{margin: 0}}><strong>Mississauga West Campus</strong></h4>
                           <p>500-3660 Hurontario Street </p>
                           <p>Mississauga, ON L5B 3C4</p>
                           <p>T: (905) 306-0666</p>
                           <p>F: (647) 948-5571</p>
                           </td>
                           <td className="header-text-wrap" width="25%" style={{textAlign: 'right', paddingBottom: 35}}>
                           <h4 style={{margin: 0}}><strong>Kingston Campus</strong></h4>
                           <p>1469 Princess Street</p>
                           <p>Kingston, ON K7M 3E9</p>
                           <p>T: (613) 544-8973</p>
                           <p>F: (613) 544-8980</p>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 0, paddingBottom: 0, paddingRight: 15, paddingLeft: 15, margin: 'auto'}}>
                     <tbody>
                        <tr>
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td>
                                    <p style={{marginTop: 10,marginBottom:10,fontFamily: '"Roboto", sans-serif', lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'center', msoLineHeightAlt: 17}}>
                                       <strong>
                                       <span style={{fontSize: 15}}>INSTITUTION INFORMATION</span> <span style={{fontSize: 14}}>(continued) </span>
                                       </strong>
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                     </tbody>
                     </table>               
                     <table style={{width: '100%', marginLeft: 0, marginRight: 0, paddingTop: 15, paddingBottom: 15}} border={0} cellPadding={0} cellSpacing={0} align="center">
                     <tbody>
                        <tr>
                           <td style={{borderTop: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Beneficiary Name</strong>
                           </p>
                           </td>
                           <td style={{borderTop: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              Academy of Learning Career College
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td style={{borderTop: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Beneficiary Address</strong>
                           </p>
                           </td>
                           <td style={{borderTop: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              3660 Hurontario St, Suite 500, Mississauga, ON L5B 3C4
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td style={{borderTop: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Bank Name</strong> 
                           </p>
                           </td>
                           <td style={{borderTop: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              Bank of Montreal 
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td style={{borderTop: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Branch Address</strong> 
                           </p>
                           </td>
                           <td style={{borderTop: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              1841 Walkers Line, Burlington, Ontario, Canada L7M 0H6
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td style={{borderTop: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Bank Telephone</strong>
                           </p>
                           </td>
                           <td style={{borderTop: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              (905) 336-3375
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td style={{borderTop: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Bank Code</strong> 
                           </p>
                           </td>
                           <td style={{borderTop: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              001
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td style={{borderTop: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Transit Number</strong>
                           </p>
                           </td>
                           <td style={{borderTop: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              38712
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td style={{borderTop: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}> 
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>Account Number</strong>
                           </p>
                           </td>
                           <td style={{borderTop: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              1983318
                           </p>
                           </td>
                        </tr>
                        <tr>
                           <td style={{borderTop: '1px solid #000', borderBottom: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              <strong>SWIFT BIC Code</strong>
                           </p>
                           </td>
                           <td style={{borderTop: '1px solid #000', borderBottom: '1px solid #000', borderRight: '1px solid #000', paddingLeft: 10}}>
                           <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, color: '#000000'}}>
                              BOFMCAM2
                           </p>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 10, paddingBottom: 0, paddingRight: 15, paddingLeft: 15, margin: 'auto'}}>
                     <tbody>
                        <tr>
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%',marginTop: 10,marginBottom:10, marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td>
                                    <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'center', msoLineHeightAlt: 17, textTransform: 'uppercase'}}>
                                       <strong>DECLARATION AND SIGNATURES</strong>
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 15, paddingBottom: 0, paddingRight: 15, paddingLeft: 0, margin: 'auto', marginRight: 'auto'}}>
                     <tbody>
                        <tr>
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td>
                                    <p style={{marginTop: 10,marginBottom:10,fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17, fontStyle: 'italic'}}>
                                       Please carefully review and sign the Declaration section below. Once signed, this complete document is to be 
                                       submitted to the college. 
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <table style={{width: '100%', paddingTop: 15, paddingBottom: 15, paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0}} border={0} cellPadding={0} cellSpacing={0} align="center">
                     <tbody>
                        <tr>
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td>
                                    <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}>
                                       I, <span>{dataList.firstName}</span> , understand, acknowledge, and accept the following terms and 
                                       conditions: 
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <table style={{width: '100%', marginLeft: 0, marginRight: 0}} border={0} cellPadding={0} cellSpacing={0} align="center">
                     <tbody>
                        <tr>
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td style={{width: 20, position: 'relative', verticalAlign: 'top', paddingTop: 5}}><span style={{}}><img src="/front/img/solid-dot.png" style={{width: 5}} alt="Dots" /></span> </td>
                                 <td>
                                    <p style={{margin: '0 0 1em', fontFamily: '"Roboto", sans-serif', fontSize: 13, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}> The program into which I am enrolling as provided above is, at present, NOT eligible for the Post-Graduate Work 
                                       Permit (PGWP) following the completion of studies.</p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '95%', marginLeft: 20, marginRight: 0, paddingRight: 20}} align="center"> 
                              <tbody><tr>
                                 <td style={{width: 20, margin: 'auto', position: 'relative', verticalAlign: 'top'}}> <span style={{}}>
                                       <img src="/front/img/circle-dot-2.png" style={{width: 5}} alt="Dots" />
                                    </span></td>
                                 <td>
                                    <p style={{margin: '0 0 1em', fontFamily: '"Roboto", sans-serif', fontSize: 13, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}> If the program into which I am being admitted is the Medical Office Administration diploma program, and I 
                                       intend to proceed to the Office Administration – Health Services (OFAH) program at Georgian College 
                                       through the Transfer Credit Articulation Agreement,
                                       <span style={{fontStyle: 'italic',fontFamily: '"Roboto", sans-serif', fontSize: 13}}> upon successful completion of both programs AND 
                                       satisfaction of any other criteria as required by Immigration, Refugees and Citizenship Canada (IRCC),  </span>                                     
                                       may qualify for the Post-Graduate Work Permit (PGWP).</p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td style={{width: 20, margin: 'auto', position: 'relative', verticalAlign: 'top', paddingTop: 7}}> <span style={{}}><img src="/front/img/solid-dot.png" style={{width: 5}} alt="Dots" /></span></td>
                                 <td>
                                    <p style={{margin: '0 0 1em', fontFamily: '"Roboto", sans-serif', fontSize: 13, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}>
                                       The primary purpose of my travel to and stay in Canada is for full-time study, NOT for work or employment. 
                                       Should I choose to pursue work or employment, I will work within the legal limits as permitted by IRCC, federal, 
                                       provincial, and municipal law, and place first priority on my studies.
                                    </p>
                                 </td>
                                 </tr>
                                 <tr>
                                 <td style={{width: 20, margin: 'auto', position: 'relative', verticalAlign: 'top', paddingTop: 7}}><span style={{}}><img src="/front/img/solid-dot.png" style={{width: 5}} alt="Dots" /></span></td>
                                 <td>
                                    <p style={{margin: '0 0 1em', fontFamily: '"Roboto", sans-serif', fontSize: 13, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}>
                                       I have done my due diligence and research on Academy of Learning Career College, and Georgian College as 
                                       applicable to me. I am fully aware of the location of the college(s). I understand that it is my responsibility to 
                                       arrange and cover all costs for travel to and accommodation in Canada for the entire duration of my study 
                                       period, including any scheduled breaks and study gaps as permissible by IRCC. 
                                    </p>
                                 </td>
                                 </tr>
                                 <tr>
                                 <td style={{width: 20, margin: 'auto', position: 'relative', verticalAlign: 'top', paddingTop: 7}}><span style={{}}><img src="/front/img/solid-dot.png" style={{width: 5}} alt="Dots" /></span></td>
                                 <td>
                                    <p style={{margin: '0 0 1em', fontFamily: '"Roboto", sans-serif', fontSize: 13, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}>
                                       I am 18 years of age or over and shall take full responsibility of my actions and decisions. I understand that 
                                       upon arrival in Canada and the issuance of my Study Permit by IRCC, I must represent myself for any student 
                                       affairs as they relate to Academy of Learning Career College, and I cannot be represented by a spouse, family 
                                       member, relative, guardian, or friend.  
                                    </p>
                                 </td>
                                 </tr>
                                 <tr>
                                 <td style={{width: 20, margin: 'auto', position: 'relative', verticalAlign: 'top', paddingTop: 7}}> <span style={{}}><img src="/front/img/solid-dot.png" style={{width: 5}} alt="Dots" /></span></td>
                                 <td>
                                    <p style={{margin: '0 0 1em', fontFamily: '"Roboto", sans-serif', fontSize: 13, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}>
                                       I acknowledge that Academy of Learning Career College, and Georgian College as applicable to me, may have 
                                       limited seating capacity for any given intake, and I am fully committed to completing my study program in its 
                                       entirety. In case I decide not to continue the program, I acknowledge and accept that fees will be refunded or 
                                       retained per the College's Fee Refund Policy. 
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td>
                                    <p style={{fontFamily: '"Roboto", sans-serif', fontSize: 13, lineHeight: '1.4', wordBreak: 'break-word', msoLineHeightAlt: 17, textAlign: 'right', fontStyle: 'italic'}}>
                                       (Continued)
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                     </tbody>
                     </table>           
                  </div>  
                  <div className="footer-badge-wrap" style={{pageBreakAfter: 'always'}}>
                     <table width="100%" border={0} cellPadding={0} cellSpacing={0} align="center" style={{margin: 0, width: '100%', borderCollapse: 'collapse', borderWidth: 0, padding: 0}}>
                     <tbody>
                        <tr>
                           <td width="33.333333%" bgcolor="#aacd3f" style={{padding: 15}}>
                           </td>
                           <td width="33.333333%" bgcolor="#ea7023" style={{padding: 15}}>
                           </td>
                           <td width="33.333333%" bgcolor="#5ac7db" style={{padding: 15}}>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                  </div>
               </div>
               <div className="page-wrapper">
                  <div className="page page-appendix-header">
                     <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0, padding: 15, paddingBottom: 15}} align="center">
                     <tbody>
                        <tr>
                           <td width="33.333333%">
                           <a href="javascript:void(0)" style={{outline: 'none'}} tabIndex={-1} target="_blank"> 
                              <img align="center" alt="Logo" border={0} src="/front/img/academy_of_learning_logo.png" style={{textDecoration: 'none', msInterpolationMode: 'bicubic', height: 'auto', border: 0, width: 250,marginTop:'-50', display: 'block'}} width={200} />
                           </a>
                           </td>
                           <td className="header-text-wrap" width="33.333333%" style={{textAlign: 'right', paddingBottom: 35}}>
                           <h4 style={{margin: 0}}><strong>Mississauga West Campus</strong></h4>
                           <p>500-3660 Hurontario Street </p>
                           <p>Mississauga, ON L5B 3C4</p>
                           <p>T: (905) 306-0666</p>
                           <p>F: (647) 948-5571</p>
                           </td>
                           <td className="header-text-wrap" width="33.333333%" style={{textAlign: 'right', paddingBottom: 35}}>
                           <h4 style={{margin: 0}}><strong>Kingston Campus</strong></h4>
                           <p>1469 Princess Street</p>
                           <p>Kingston, ON K7M 3E9</p>
                           <p>T: (613) 544-8973</p>
                           <p>F: (613) 544-8980</p>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 0, paddingBottom: 0, paddingRight: 15, paddingLeft: 15, margin: 'auto'}}>
                     <tbody>
                        <tr>
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td>
                                    <p style={{marginTop: 10,marginBottom:10,fontFamily: '"Roboto", sans-serif', fontSize: 15, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'center', msoLineHeightAlt: 17, textTransform: 'uppercase'}}>
                                       <strong>
                                       DECLARATION AND SIGNATURES <span style={{fontSize: 14, textTransform: 'lowercase'}}>(continued)</span>
                                       </strong>
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 15, paddingBottom: 15, paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0}}>
                     <tbody>
                        <tr>
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td>
                                    <p style={{marginTop: 10,marginBottom:10,fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}>
                                       I, <span>{dataList.firstName}</span> , understand, acknowledge, and accept the terms and conditions as 
                                       provided above, and shall abide by and adhere to them for the duration of my study period at Academy of Learning 
                                       Career College.  
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <table style={{width: '100%', marginLeft: 0, marginRight: 0, paddingTop: 15, paddingBottom: 15}} border={0} cellPadding={0} cellSpacing={0} align="center">                     
                     <tbody>
                        <tr>
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table style={{width: '100%',marginTop:'40px',marginBottom:'40px'}} border={0} cellPadding={0} cellSpacing={0} align="center">
                              <tbody><tr>
                                 <td>
                                    <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%'}} align="center">
                                       <tbody><tr>
                                          <td style={{verticalAlign: 'middle'}} valign="middle">
                                             <p style={{marginTop: 10,marginBottom:10,fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, borderTop: '1px solid #000', marginRight: 15, paddingLeft: 15, paddingTop: 2}}>
                                             <span style={{fontStyle: 'italic'}}>Applicant Signature</span>
                                             </p>
                                          </td>
                                       </tr>
                                       </tbody></table>
                                 </td>
                                 <td>
                                    <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%'}} align="center">
                                       <tbody><tr>
                                          <td style={{verticalAlign: 'middle'}} valign="middle">
                                             <p style={{marginTop: 10,marginBottom:10,fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, borderTop: '1px solid #000', paddingTop: 2, paddingLeft: 15}}>
                                             <span style={{fontStyle: 'italic'}}>                                                            Date Signed</span>
                                             </p>
                                          </td>
                                       </tr>
                                       </tbody></table>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                        <tr>
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 50, width: '100%',marginTop:'40px',marginBottom:'40px'
,marginTop:'40px',marginBottom:'40px'}}>
                              <tbody><tr>
                                 <td>
                                    <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%'}} align="left">
                                       <tbody><tr>
                                          <td style={{verticalAlign: 'middle'}} valign="middle">
                                             <p style={{marginTop: 10,marginBottom:10,fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, borderTop: '1px solid #000', marginRight: 15, paddingLeft: 15, paddingTop: 2}}>
                                             <span style={{fontStyle: 'italic'}}>Witness Signature </span>
                                             </p>
                                          </td>
                                       </tr>
                                       </tbody></table>
                                 </td>
                                 <td>
                                    <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%'}} align="left">
                                       <tbody><tr>
                                          <td style={{verticalAlign: 'middle'}} valign="middle">
                                             <p style={{marginTop: 10,marginBottom:10,fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'left', msoLineHeightAlt: 17, borderTop: '1px solid #000', paddingTop: 2, paddingLeft: 15}}>
                                             <span style={{fontStyle: 'italic'}}>                                                            Date Signed</span>
                                             </p>
                                          </td>
                                       </tr>
                                       </tbody></table>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <table border={0} cellPadding={0} cellSpacing={0} align="center" style={{paddingTop: 15, paddingBottom: 0, paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0}}>
                     <tbody>
                        <tr>
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0, paddingBottom: 20}}>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td>
                                    <p style={{marginTop: 10,marginBottom:10,fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}>
                                       <strong>As noted above, this letter is NOT to be used for Study Permit application purposes.</strong>
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                        <tr>
                           <td style={{width: '100%', marginLeft: 0, marginRight: 0}}>
                           <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0}} align="center">
                              <tbody><tr>
                                 <td style={{paddingBottom: 40}}>
                                    <p style={{marginTop: 10,marginBottom:10,fontFamily: '"Roboto", sans-serif', fontSize: 14, lineHeight: '1.4', wordBreak: 'break-word', textAlign: 'justify', msoLineHeightAlt: 17}}>
                                       For inquiries regarding admission and enrolment, please contact us at <a href="/#" style={{color: '#000', textDecoration: 'none'}} target="_blank"><strong> admissions@aolccollege.ca.</strong></a>   
                                    </p>
                                 </td>
                                 </tr>
                              </tbody></table>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                     <div>
                     <p style={{paddingBottom: '3pt', paddingLeft: '0pt', textIndent: '0pt', textAlign: 'left', fontFamily: '"Roboto", sans-serif', fontSize: 14}}>Sincerely,</p>
                     <p style={{paddingLeft: '6pt', textIndent: '0pt', textAlign: 'left'}}>
                        <span> </span>
                     </p>
                     <table border={0} cellSpacing={0} cellPadding={0}>
                        <tbody>
                           <tr>
                           <td>
                              <img width={159} height={59} src="/front/img/signature.jpg" />
                           </td>
                           </tr>
                        </tbody>
                     </table>
                     <p />

                     
                     
                     <p style={{paddingLeft: '0pt', textIndent: '0pt', lineHeight: '12pt', textAlign: 'left', fontFamily: '"Roboto", sans-serif', fontSize: 14}}>Tara Teeling</p>
                     <p style={{paddingLeft: '0pt', textIndent: '0pt', lineHeight: '12pt', textAlign: 'left', fontFamily: '"Roboto", sans-serif', fontSize: 14}}>Academic Director</p>

                     <p style={{paddingLeft: '0pt', textIndent: '0pt', lineHeight: '12pt', textAlign: 'left', fontFamily: '"Roboto", sans-serif', fontSize: 14}}>Academy of Learning Career
                        College</p>
                     <p style={{paddingLeft: '0pt', textIndent: '0pt', lineHeight: '12pt', textAlign: 'left', fontFamily: '"Roboto", sans-serif', fontSize: 14}}>Kingston &amp; Mississauga West</p>
                     <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                     {/* <p class="s6" style="padding-top: 5pt; padding-left: 0pt; text-indent: 0pt; font-style: italic; text-align: left;">Encl.: Letter of
                                       Acceptance</p> */}
                     </div>
                     {/* CLOA end */}
                  </div>

                  
                  
                  {dataList.study_program_id && dataList.study_program_id.program === "Medical Office Administration" ? (
                     <div className="footer-badge-wrap" style={{ pageBreakAfter: 'always' }}>
                        <table width="100%" border={0} cellPadding={0} cellSpacing={0} align="center" style={{ marginTop: 100, width: '100%', borderCollapse: 'collapse', borderWidth: 0, padding: 0 }}>
                           {/* Content for Medical Office Administration */}
                           <tbody>
                              <tr>
                                 <td width="33.333333%" bgcolor="#aacd3f" style={{padding: 15}}>
                                 </td>
                                 <td width="33.333333%" bgcolor="#ea7023" style={{padding: 15}}>
                                 </td>
                                 <td width="33.333333%" bgcolor="#5ac7db" style={{padding: 15}}>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     ) : (
                     <div className="footer-badge-wrap" style={{ position: 'relative', marginTop: '200px' }}>
                        <table width="100%" border={0} cellPadding={0} cellSpacing={0} align="center" style={{ margin: 0, width: '100%', borderCollapse: 'collapse', borderWidth: 0, padding: 0 }}>
                           {/* Content for other cases */}
                           <tbody>
                                 <tr>
                                    <td width="33.333333%" bgcolor="#aacd3f" style={{padding: 15}}>
                                    </td>
                                    <td width="33.333333%" bgcolor="#ea7023" style={{padding: 15}}>
                                    </td>
                                    <td width="33.333333%" bgcolor="#5ac7db" style={{padding: 15}}>
                                    </td>
                                 </tr>
                              </tbody>
                        </table>
                     </div>
                     )}

               </div>
               {/* COLA end */}
               {/* Appendix start */}
                {/* Appendix start */}
                {dataList.study_program_id && dataList.study_program_id.program === "Medical Office Administration" ? (
                                  <>
                                    <table border={0} cellPadding={0} cellSpacing={0} style={{width: '100%', marginLeft: 0, marginRight: 0, paddingLeft: 33, paddingRight: 50, paddingTop: 10, paddingBottom: 70}} align="center">
                                  <tbody>
                                    <tr>
                                      <td width="33%" align="left">
                                        <a href="javascript:void(0);" style={{outline: 'none'}} tabIndex={-1} target="_blank">
                                          <img align="left" alt="Logo" border={0} src="/front/img/Aolcc-new.jpg" style={{textDecoration: 'none', msInterpolationMode: 'bicubic', height: 'auto', border: 0, display: 'block'}} width="159px" />
                                        </a>
                                      </td>
                                      <td width="50%" style={{textAlign: 'center'}} align="center">
                                      </td>
                                      <td width="33%" align="right">
                                       
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div className="page page-appendix-header">
                                  <div>
                                    <h4 style={{textAlign: 'center', marginTop: 0, textTransform: 'uppercase', fontFamily: '"calibri", sans-serif'}}>
                                      <strong>APPENDIX 1</strong></h4>
                                    <h4 style={{textAlign: 'center', textTransform: 'uppercase', fontFamily: '"calibri", sans-serif', marginBottom: 0}}>
                                      <strong>TRANSFER CREDIT ARTICULATION</strong></h4>
                                  </div>
                                  <div>
                                    <h4 style={{textAlign: 'justify', fontFamily: '"calibri", sans-serif',fontSize:'14px'}}>
                                      <strong>Medical Office Administration Diploma at Academy of Learning Career College </strong>
                                    </h4>
                                    <h4 style={{textAlign: 'justify', fontFamily: '"calibri", sans-serif',fontSize:'14px'}}>
                                      Students who have successfully completed the one-year <strong> Medical Office Administration Diploma
                                        with a minimum
                                        cumulative average of 75% </strong> will be given exemption from specific courses of the 2-year
                                      Office Administration –
                                      Health Services Diploma from Georgian College as indicated below.
                                    </h4>
                                    <h4 style={{textAlign: 'justify', fontFamily: '"calibri", sans-serif',fontSize:'14px'}}>
                                      Diploma Pathway Progression for Georgian College Office Administration – Health Services for
                                      students who
                                      have completed Academy of Learning Career College Medical Office Administration
                                    </h4>
                                  </div>
                                  <div className="table-box table-box-pathway">
                                        <table cellPadding={0} cellSpacing={0}>
                                          <tbody>
                                            <tr className="heading" style={{fontFamily: '"calibri", sans-serif'}}>
                                              <td style={{fontFamily: '"calibri", sans-serif'}}><strong>Georgian
                                                  College
                                                  Course Code</strong></td>
                                              <td style={{fontFamily: '"calibri", sans-serif'}}><strong>Georgian College Course</strong></td>
                                              <td style={{fontFamily: '"calibri", sans-serif'}}><strong>AOLCC Graduate
                                                  Pathway
                                                  Semester</strong>
                                              </td>
                                              <td style={{fontFamily: '"calibri", sans-serif'}}><strong>Georgian
                                                  College
                                                  Standard
                                                  Semester</strong></td>
                                            </tr>
                                            <tr className="item">
                                              <td>COMP 1113 </td>
                                              <td>Business Documentation and Text Processing </td>
                                              <td>EXEMPT</td>
                                              <td>1</td>
                                            </tr>
                                            <tr className="item">
                                              <td>COMP 1013</td>
                                              <td>Advanced Business Documentation</td>
                                              <td>EXEMPT</td>
                                              <td>2</td>
                                            </tr>
                                            <tr className="item">
                                              <td>OFAD 1001</td>
                                              <td>Office Procedures and Strategies </td>
                                              <td>EXEMPT</td>
                                              <td>1</td>
                                            </tr>
                                            <tr className="item">
                                              <td>OFAD 1022 </td>
                                              <td>Professional Development and Employability 1</td>
                                              <td>EXEMPT</td>
                                              <td>1</td>
                                            </tr>
                                            <tr className="item">
                                              <td>OFAD 1023</td>
                                              <td>Professional Development and Employability 2 </td>
                                              <td>EXEMPT</td>
                                              <td>2</td>
                                            </tr>
                                            <tr className="item">
                                              <td>OFAD 1006</td>
                                              <td>Introduction to Medical Terminology</td>
                                              <td>EXEMPT</td>
                                              <td>2</td>
                                            </tr>
                                            <tr className="item">
                                              <td>MATH 1034</td>
                                              <td>Office Math Applications</td>
                                              <td>EXEMPT</td>
                                              <td>2</td>
                                            </tr>
                                            <tr className="item">
                                              <td>OFAD 1007</td>
                                              <td>Computer Applications 1 for the Office</td>
                                              <td>EXEMPT</td>
                                              <td>1</td>
                                            </tr>
                                            <tr className="item">
                                              <td>OFAD 1020</td>
                                              <td>Computer Applications 2 for the Office</td>
                                              <td>EXEMPT</td>
                                              <td>2</td>
                                            </tr>
                                            <tr className="item">
                                              <td>OFAD 2000</td>
                                              <td>Anatomy for Health Services 1</td>
                                              <td>EXEMPT</td>
                                              <td>3</td>
                                            </tr>
                                            <tr className="item">
                                              <td>OFAD 2031</td>
                                              <td>Health Services Transcription</td>
                                              <td>EXEMPT</td>
                                              <td>3</td>
                                            </tr>
                                            <tr className="item">
                                              <td>OFAD 2006</td>
                                              <td>Anatomy for Health Services 2</td>
                                              <td>EXEMPT</td>
                                              <td>4</td>
                                            </tr>
                                            <tr className="item">
                                              <td>OFAD 2014 </td>
                                              <td>Bookkeeping for the Office Professional</td>
                                              <td>EXEMPT</td>
                                              <td>3</td>
                                            </tr>
                                          </tbody>
                                        </table>

                                  </div>
                                </div>
                                <div className="page page-appendix-header" style={{paddingTop: 0}}>
                                  <div>
                                    <h4 style={{textAlign: 'justify', fontFamily: '"calibri", sans-serif',fontSize:'14px'}}>In total, Academy of Learning Career College
                                      students who successfully complete the Medical Office Administration will receive credit for up to
                                      12 courses towards the Office Administration – Health Services Diploma at Georgian College, which
                                      provides them
                                      exemption from Semester 1 and 2 of Georgian College Office Administration - Health Services Diploma.
                                    </h4>
                                    <h4 style={{textAlign: 'justify', fontFamily: '"calibri", sans-serif',fontSize:'14px'}}>
                                      Students will pursue the following courses towards Semester 3 and 4 at Georgian College for completing the 2-year Office Administration – Health Services.
                                    </h4>
                                  </div>
                                  <div className="table-box">
                                    <table cellPadding={0} cellSpacing={0}>
                                      <tbody>
                                        <tr className="heading" style={{fontFamily: '"calibri", sans-serif'}}>
                                          <td style={{fontFamily: '"calibri", sans-serif'}}><strong>Georgian
                                              College
                                              Course Code</strong></td>
                                          <td style={{fontFamily: '"calibri", sans-serif'}}><strong>Georgian College Course</strong></td>
                                          <td style={{fontFamily: '"calibri", sans-serif'}}><strong>AOLCC Graduate
                                              Pathway
                                              Semester</strong></td>
                                          <td style={{fontFamily: '"calibri", sans-serif'}}><strong>Georgian
                                                  College Standard Semester</strong></td>
                                        </tr>
                                        <tr className="item">
                                          <td>COMM*</td>
                                          <td>Communications Course</td>
                                          <td style={{textAlign: 'center'}}>3</td>
                                          <td>1</td>
                                        </tr>
                                        <tr className="item">
                                          <td>GNED*</td>
                                          <td>General Education course</td>
                                          <td style={{textAlign: 'center'}}>3</td>
                                          <td>1</td>
                                        </tr>
                                        <tr className="item">
                                          <td>GNED*</td>
                                          <td>General Education course</td>
                                          <td style={{textAlign: 'center'}}>3</td>
                                          <td>3</td>
                                        </tr>
                                        <tr className="item">
                                          <td>GNED*</td>
                                          <td>General Education course</td>
                                          <td style={{textAlign: 'center'}}>3</td>
                                          <td>4</td>
                                        </tr>
                                        <tr className="item">
                                          <td>COMP 2026</td>
                                          <td>Integrated Office Simulations</td>
                                          <td style={{textAlign: 'center'}}>3</td>
                                          <td>3</td>
                                        </tr>
                                        <tr className="item">
                                          <td>OFAD 2025</td>
                                          <td>Electronic Medical Office</td>
                                          <td style={{textAlign: 'center'}}>3</td>
                                          <td>3</td>
                                        </tr>
                                        <tr className="item">
                                          <td>COMP 1114</td>
                                          <td>Multimedia Communications</td>
                                          <td style={{textAlign: 'center'}}>4</td>
                                          <td>2</td>
                                        </tr>
                                        <tr className="item">
                                          <td>COMP 2027</td>
                                          <td>Computerized Administrative Simulations</td>
                                          <td style={{textAlign: 'center'}}>4</td>
                                          <td>4</td>
                                        </tr>
                                        <tr className="item">
                                          <td>OFAD 2032</td>
                                          <td>Health Services Practical Experience and Clinical Applications</td>
                                          <td style={{textAlign: 'center'}}>4</td>
                                          <td>4</td>
                                        </tr>
                                        <tr className="item">
                                          <td>OFAD 2026</td>
                                          <td>OHIP for Health Services</td>
                                          <td style={{textAlign: 'center'}}>4</td>
                                          <td>4</td>
                                        </tr>
                                        <tr className="item">
                                          <td>OFAD 2033 </td>
                                          <td>Foundations in Health Records and Information Management</td>
                                          <td style={{textAlign: 'center'}}>4</td>
                                          <td>4</td>
                                        </tr>
                                        <tr className="item">
                                          <td>COMM*</td>
                                          <td>Communications Course</td>
                                          <td style={{textAlign: 'center'}}>4</td>
                                          <td>2</td>
                                        </tr>
                                      </tbody>
                                    </table>

                                  </div>
                                </div>
                                <div className="footer-badge-wrap" style={{position: 'relative',marginTop: '30px'}}>
                                  <table width="100%" border={0} cellPadding={0} cellSpacing={0} align="center" style={{margin: 0, width: '100%', borderCollapse: 'collapse', borderWidth: 0, padding: 0}}>
                                    <tbody>
                                      <tr>
                                        <td width="33.333333%" bgcolor="#aacd3f" style={{padding: 15}}>
                                        </td>
                                        <td width="33.333333%" bgcolor="#ea7023" style={{padding: 15}}>
                                        </td>
                                        <td width="33.333333%" bgcolor="#5ac7db" style={{padding: 15}}>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                  </>

                                  ) : (
                                    null
                                  )}
                                {/* Appendix end */}
                  
                     </div>
                     {/* ) : (
                     null
                     )} */}
                     {/* {pdfType === 'pdfreceipt' ? ( */}
                        <div id="loareceipt">
                        
                        <table style={{width: '100%', top: 10}}>
                                       <tr>
                                          
                                          <td style={{width: '40%',textAlign: 'left'}}>
                                          <img width={230} src="/front/img/Academt.jpeg" />
                                          </td>
                                          <td style={{width: '60%', textAlign: 'right'}}>
                                          <h3 style={{fontSize: '18px',fontWeight: '800'}}>FULL TIME INTERNATIONAL FEE RECEIPT</h3>
                                          </td>
                                       </tr>
                                    </table>


                                    <table style={{width: '100%', top: 10}}>
                                       <tr>
                                          
                                          <td style={{width: '45%', padding: 10, textAlign: 'left'}}>
                                          <p>Student ID: {dataList.cloa_id ? dataList.cloa_id : null}</p>
                                          <p>Program: {dataList.study_program_id ? dataList.study_program_id.program : null}</p>
                                          <p>Start Date: {formattedDate_st_date}</p>
                                          <p>Expected Completion Date: {formattedDate_end_year_date}</p>
                                          <br/>
                                          <p>{dataList.firstName+' '+dataList.lastName}</p>

                                          {dataList.unit ? dataList.unit : null} {dataList.street_no ? dataList.street_no : null},
                                                
                                          <p>{dataList.street_name ? dataList.street_name : null},</p>
                                          <p>{dataList.city ? dataList.city : null}, {dataList.state ? dataList.state : null}, {dataList.country ? dataList.country : null}, {dataList.postal_code ? dataList.postal_code : null}</p>
                                          </td>

                                          <td style={{width: '55%', padding: 5, textAlign: 'justify',float:'right'}}>
                                          <h4 style={{margin: 0}}><strong>Mississauga West Campus</strong></h4>
                                          <p>500-3660 Hurontario Street </p>
                                          <p>Mississauga, ON L5B 3C4</p>
                                          <p>T: (905) 306-0666</p>
                                          <p>F: (647) 948-5571</p> <br/>

                                          <h4 style={{fontFamily: '"calibri", sans-serif', margin: 0}}><strong>Kingston Campus</strong></h4>
                                          <p>1469 Princess Street</p>
                                          <p>Kingston, ON K7M 3E9</p>
                                          <p>T: (613) 544-8973</p>
                                          <p>F: (613) 544-8980</p><br/>

                                          <p>DLI# O19303790322</p>
                                          <p>Invoice: {invoiceNumber}   </p>
                                          <p>Date:  {formattedDate_issue_date}</p>

                                          
                                          </td>
                                       </tr>
                                    </table>


                                    <table style={{tableLayout: 'fixed', width: '100%', borderCollapse: 'collapse', borderTop: 0}}>
                                          <tbody>
                                          <tr style={{padding: '10px 0'}}>
                                                <td style={{width: '25%', padding: 5, borderBottom: '1px solid black', textAlign: 'left'}}>
                                                <h4 style={{margin: 0}}><strong>Description</strong></h4>
                                                </td>
                                                <td style={{width: '75%', padding: 5 , borderBottom: '1px solid black', textAlign: 'left'}}>
                                                {/* <span>{dataList.firstName+' '+dataList.lastName}</span> */}
                                                <h4 style={{margin: 0}}><strong>Amount</strong></h4>
                                                </td>
                                          </tr>
                                          <tr style={{padding: '10px 0'}}>
                                                <td style={{width: '25%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                                <span>Total Fees:</span>
                                                </td>
                                                <td style={{width: '75%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                                <span>
                                                ${formatted_program_Fees}
                                                </span>
                                                </td>
                                          </tr>
                                          <tr style={{padding: '10px 0'}}>
                                                <td style={{width: '25%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                                <span>Amount Receivable:</span>
                                                </td>
                                                <td style={{width: '75%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                                <span>${formatted_program_Fees}</span>
                                                </td>
                                          </tr>
                                          <tr style={{padding: '10px 0'}}>
                                                <td style={{width: '25%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                                <span>Payment Received:</span>
                                                </td>
                                                <td style={{width: '75%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                                <span>${formatted_prepaid_fee}</span>
                                                </td>
                                          </tr>
                                          <tr style={{padding: '10px 0'}}>
                                                <td style={{width: '25%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                                <span>Total Payment Received:</span>
                                                </td>
                                                <td style={{width: '75%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                                <span>${formatted_prepaid_fee}</span>
                                                </td>
                                          </tr>

                                          <tr style={{padding: '10px 0'}}>
                                                <td style={{width: '25%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                                <span>Balance Due:</span>
                                                </td>
                                                <td style={{width: '75%', padding: 8, borderBottom: '1px solid black', textAlign: 'left'}}>
                                                <span>${formatted_remaining_fees}</span>
                                                </td>
                                          </tr>

                                          </tbody>
                                          </table>
                                    <table style={{width: '100%', marginTop: '10px'}}>
                                       <tr>
                                          <td style={{width: '100%', padding: 10,paddingBottom:0, textAlign: 'left',marginBottom:'10px'}}>
                                          <h4 style={{marginTop: 50,marginBottom: 5,float:'left',color:'grey'}}><strong>PAYMENT OPTIONS: </strong>The following payment options are accepted:</h4>

                                          <p style={{textAlign: 'right',float:'right'}}><i>All fees listed are in Canadian Dollars</i></p><br/>
                                          </td>
                                       </tr>
                                    </table>
                                    <table style={{width: '100%', top: 10,color:'grey'}}>
                                       <tr>
                                          
                                          <td style={{width: '50%', padding: 10, textAlign: 'left'}}>
                                          <h4 style={{margin: 0,color:'grey'}}><strong>Outside Canada</strong></h4>
                                          <h4 style={{margin: 0,color:'grey'}}><strong>Option 1:  Bank Wire Transfer </strong></h4>
                                          <p style={{fontSize: '12px'}}>Beneficiary Name: Academy of Learning Career College</p>
                                          <p style={{fontSize: '11px'}}>Beneficiary Address: 500-3660 Hurontario St, Mississauga ON. L5B 3C4</p>
                                          <p style={{fontSize: '12px'}}>Bank Name: Bank of Montreal</p>
                                          <p style={{fontSize: '12px'}}>Branch Address: 1841 Walkers Line, Burlington, ON, Canada L7M 0H6</p>
                                          <p style={{fontSize: '12px'}}>Bank Telephone: (905) 336-3375</p>
                                          <p style={{fontSize: '12px'}}>Bank Code: 001</p>
                                          <p style={{fontSize: '12px'}}>Transit Number: 38712</p>
                                          <p style={{fontSize: '12px'}}>Account Number: 1983318</p>
                                          <p style={{fontSize: '12px'}}>SWIFT BIC Code: BOFMCAM2</p>
                                          <p style={{height:'131px'}}></p>
                                        
                                          </td>

                                          <td style={{width: '50%', padding: 10, textAlign: 'left'}}>
                                          <h4 style={{margin: 0,color:'grey'}}><strong>In Canada</strong></h4>
                                          <h6 style={{margin: 0,color:'grey'}}><strong>Option 1:  Interac </strong></h6>
                                          <p style={{fontSize: '12px'}}>All e-transfer payments to be directed to pay.m@aolccollege.ca</p><br/>
                                          <h6 style={{margin: 0,color:'grey'}}><strong>Option 2: Cheques and Bank Draft</strong></h6>
                                          <p style={{fontSize: '12px'}}>Please make all cheques and bank drafts payable to “Academy of Learning Career College“. Ensure that your Student ID Number as noted on your Letter of Acceptance from Academy of Learning Career College, is clearly noted on the cheque or bank draft.</p>
                                          <p style={{fontSize: '12px'}}>For cheques, please ensure that there are sufficient funds in the bank account from which the cheque will draw funds. Students shall be responsible for all fees and expenses that result from insufficient funds or related reasons.</p>
                                          <br/>
                                          <h6 style={{margin: 0,color:'grey'}}><strong>Option 3: Credit Card</strong></h6>
                                          <p style={{fontSize: '12px'}}>Payments may be made by Credit Card at the Campus. An additional service fee will apply. This fee is billed at 2.75% of the fee payment to cover the fees of the service provider.</p>
                                          
                                          </td>
                                       </tr>
                                    </table>

                          </div>
                     {/* ) : (
                        null
                     )} */}
                  <div>
               </div>
            </div>
         </div>
      </div>
   </div>
        
      </section>
   </div>
   <br/>
   <AdminFooter />
</div>
</>
);
};
export default CloaPdfView;