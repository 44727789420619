import React from "react";
import Loginform from "./Loginform";
import Navebar from "./Navebar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <>
      <div className="main-wrapper website">
        <Navebar />
        <div className="pv-shop-area commonpage">        
     
                  
     <div className="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
       <div className="container eduvibe-animated-shape">
           <div className="row">
               <div className="col-lg-12">
                   <div className="breadcrumb-inner text-start">
                       <div className="page-title">
                           <h3 className="title">Login</h3>
                       </div>
                       <nav className="edu-breadcrumb-nav">
                           <ol className="edu-breadcrumb d-flex justify-content-start liststyle">
                               <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                               <li className="separator"><i className="ri-arrow-drop-right-line"></i></li>
                               <li className="breadcrumb-item active" aria-current="page">Login</li>
                           </ol>
                       </nav>
                   </div>
               </div>
           </div>

           <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
               <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                   <div className="shape-image shape-image-1">
                       <img src="/front/images/shapes/shape-11-07.png" alt="Shape Thumb" />
                   </div>
                   <div className="shape-image shape-image-2">
                       <img src="/front/images/shapes/shape-01-02.png" alt="Shape Thumb" />
                   </div>
                   <div className="shape-image shape-image-3">
                       <img src="/front/images/shapes/shape-03.png" alt="Shape Thumb" />
                   </div>
                   <div className="shape-image shape-image-4">
                       <img src="/front/images/shapes/shape-13-12.png" alt="Shape Thumb" />
                   </div>
                   <div className="shape-image shape-image-5">
                       <img src="/front/images/shapes/shape-36.png" alt="Shape Thumb" />
                   </div>
                   <div className="shape-image shape-image-6">
                       <img src="/front/images/shapes/shape-05-07.png" alt="Shape Thumb" />
                   </div>
               </div>
           </div>
       </div>
   </div>


          <div id="about-us" className="edu-about-area about-style-1 edu-section-gap bg-color-white">
              <div className="container">
                  <div className="row g-5">
                    <div class="login-register-page-wrapper edu-section-gap bg-color-white">
                      <div className="login-form-box">
                          <Loginform />
                        </div>
                    </div>
                  </div>
              </div>
          </div>
     

</div>          
        <Footer />
      </div>
    </>
  );
};

export default Login;
