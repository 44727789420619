import React, { useState, useEffect } from 'react'
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { useParams,Link } from "react-router-dom";

const StudentPaymentHistoryListing = () => {

    const params = useParams();
  // DataTables

  useEffect(() => {
    // Fetch tasks when component mounts
    axios.get(`/api/studentpaymentlist/${params.id}`).then((response) => {
      setRecords(response.data);
    });
    

  }, [params.id]);

 
  const columns = [
    
    {
      name: 'Transaction No',
        selector: row=> row.txn_no,
        sortable: true
    },
    {
        name: 'Status',
          selector: row=> row.status,
          sortable: true
    },
    {
    name: 'Amount',
        selector: row=> row.amount,
        sortable: true
    },
    {
      name: 'Name',
        selector:  row=> row.firstName+' '+row.lastName,
        sortable: true
    }
    
    
  ];




const data = [];
const [records, setRecords] = useState(data);
function handleFilter(event){
    const newData = records.filter(row=>{
        return row.firstName.toLowerCase().includes(event.target.value.toLowerCase())
    });
    
    setRecords(newData);
}


  return (
    <>
    
      <div className="wrapper">
        <AdminHeader />

        <AdminSidebar />

        <div className="content-wrapper mt-2">
          <section className="content">
            <div className="">
              <div className="row">
                <div className="col-12">
                   
                    <div className="card card-dark">
                    <div className="card-header">
                      <h3 className="card-title">Student Payment History</h3>
                      <input type='text' onChange={handleFilter} style={{float:'right',width:'200px'}} className='form-control'/>
                      <Link to="/admin/student" className='btn btn-primary text-white mr-2' style={{float:'right'}} > Back</Link>

                    </div>

                    <div className="card-body">
                    <DataTable columns={columns} data={records} pagination></DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <br/>
        <AdminFooter />
      </div>
    </>
  );
};

export default StudentPaymentHistoryListing;