import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { UserContext } from "../App";
import Logo from "../images/loa-logo.png";


import '../front_layout/assets/css/vendor/remixicon.css';
import '../front_layout/assets/css/vendor/eduvibe-font.css';
import '../front_layout/assets/css/vendor/magnifypopup.css';
import '../front_layout/assets/css/vendor/slick.css';
import '../front_layout/assets/css/vendor/lightbox.css';
import '../front_layout/assets/css/vendor/animation.css';
import '../front_layout/assets/css/vendor/jqueru-ui-min.css';

const Navebar = () => {

    const {state} = useContext(UserContext);
    
    const RenderMenu = () => {
        if(state){
            return(
            <>
                <div class="mr-2">
                    <Link className="edu-btn btn-medium header-purchase-btn" to="/profile" >Account</Link>
                </div>
                <div class="">
                     <Link className="edu-btn btn-medium header-purchase-btn" to="/logout">Logout</Link>
                </div>
               
            </>
            )
        }else{
            return(
            <>
               
               
                <div class="mr-2">
                     <Link className="edu-btn btn-medium header-purchase-btn" to="/admin/login" target="_blank">Admin </Link>
                </div>
                <div class="mr-2">
                    <Link className="edu-btn btn-medium header-purchase-btn" target="_blank" to="/agent/login" >Agent </Link>
                </div>
                <div class="mr-2">
                    <Link className="edu-btn btn-medium header-purchase-btn" to="/login" >Student </Link>
                </div>
                <div class="mr-2">
                    <Link className="edu-btn btn-medium header-purchase-btn" to="/student-register-request" > Register </Link>
                </div>
               
                <div class="hamberger quote-icon d-block d-xl-none">
                    <button class="hamberger-button"><i class="ri-menu-line"></i></button>
                </div>

            </>
            )
        }

    }

  return (
    <>
        <header className="edu-header  header-sticky header-transparent header-style-2 header-default">
            
            <div className="container">
                <div className="row align-items-center">
                    <div class="col-lg-6 col-xl-2 col-md-6 col-6">
                        <div className="logo">
                            <Link to="/">
                                <img className="logo-light" src={Logo} alt="Site"/>
                            </Link>
                        </div>
                    </div>

                    <div class="col-lg-8 d-none d-xl-block">
                        <nav className="mainmenu-nav d-none d-lg-block">
                            <ul className="mainmenu">
                                <li>
                                    <Link to="/">Home </Link>
                                </li>
                            
                                <li>
                                    <Link to="/about">About</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact</Link>
                                </li>

                                <li>
                                    <Link to="https://aolccollege.ca/" target='_blank'>Visit Our Website</Link>
                                </li>



                            </ul>
                        </nav>
                    </div>

                   <div className="col-lg-6 col-xl-2 col-md-6 col-6">
                        <div className="header-right d-flex justify-content-end">
                            <div className="header-quote mt-2" style={{ alignItems: 'stretch' }}>
                                 <RenderMenu/>
                            </div>
                        </div>
                    </div>


                   
                </div>
            </div>

            
        </header>
    
    </>
  )
}

export default Navebar