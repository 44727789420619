import React from 'react'
import { Link } from 'react-router-dom';
import "./Common.css";
//import { UserContext } from "../../App";

const AdminHeader = () => {

            return (
                <>
                <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link" data-widget="pushmenu" to="#" role="button"><i className="fas fa-bars"></i></Link>
                  </li>
                  
                </ul>
            
                
              </nav>
              </>
            )
  
}

export default AdminHeader