import React, { useState, useEffect } from 'react'
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';


const CampusListing = () => {

  // Delete Role
  const deleteTask = (id) => {

    const isConfirmed = window.confirm('Are you sure you want to delete this item?');
    if (isConfirmed) {
    
    axios.delete(`/api/campus/${id}`).then((response) => {

      if(response.data.success){
        toast.success(response.data.success);
        axios.get('/api/campus').then((response2) => setRecords(response2.data));
      }else{
        toast.error(response.data.error);
      }
    });

    }

  };


  // DataTables

  useEffect(() => {
    // Fetch tasks when component mounts
    axios.get('/api/campus').then((response) => setRecords(response.data));
  }, []);

  const columns = [
    {
      name: 'Campus',
        selector: row=> row.campus,
        sortable: true
    },
    {
      name: 'Contact Name',
        selector: row=> row.name_of_contact,
        sortable: true
    },
    {
      name: 'Position',
        selector: row=> row.position,
        sortable: true
    },
    {
      name: 'Email',
        selector: row=> row.email,
        sortable: true
    },
    { 
        name: 'Status',
        selector: row=> (row.status === 'active' ? 'Active' : ''),
        sortable: true
    },
    { name: 'Action', selector: function(row) {
        return (
          <>
            <Link to={ `/admin/campus/edit/${row._id}`}><i className='fa fa-edit mr-2 text-info'></i></Link>
          <i className='fa fa-trash  text-danger' onClick={() => deleteTask(row._id)}></i>
          
          </>
        );
      } 
    }
  ];


  const [records, setRecords] = useState([]);
  function handleFilter(event){
  
    const inputValue = event.target.value.toLowerCase();
    if(inputValue){
        const newData = records.filter(row=>{
            return (
              row.campus.toLowerCase().includes(event.target.value.toLowerCase()) ||
              row.name_of_contact.toLowerCase().includes(event.target.value.toLowerCase()) ||
              row.position.toLowerCase().includes(event.target.value.toLowerCase()) ||
              row.email.toLowerCase().includes(event.target.value.toLowerCase()) 

            )
        });
        setRecords(newData);
    }else{
      axios.get('/api/campus').then((response) => setRecords(response.data));
    }
  }


  return (
    <>
      <div className="wrapper">
        <AdminHeader />

        <AdminSidebar />

        <div className="content-wrapper mt-2">
          <section className="content">
            <div className="">
              <div className="row">
                <div className="col-12">
                   
                    <div className="card card-dark">
                    <div className="card-header">
                      <h3 className="card-title">Campus Listing</h3>
                      <input type='text' onChange={handleFilter} style={{float:'right',width:'200px'}} placeholder="Search..." className='form-control'/>

                      <Link to="/admin/campus/create" className='btn btn-primary text-white mr-2' style={{float:'right'}} > Create Campus</Link>

                    </div>

                    <div className="card-body">
                    <DataTable columns={columns} data={records} pagination></DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <br/>
        <AdminFooter />
      </div>
    </>
  );
};

export default CampusListing;