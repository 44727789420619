import React , { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Navebar from './Navebar'
import Footer from './Footer'
import Loader from './Loader';
import './Loader.css';
import '../front_layout/assets/css/style.css';

const Home = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000); 
      }, []);


  return (
    
    <>

{loading ? (
        <p><Loader /></p>
      ) : (
        
    <>
    <div className="main-wrapper website">

    

        <Navebar />

        <div className="popup-mobile-menu">
            <div className="inner">
                <div className="header-top">
                    <div className="logo">
                        <Link to="/">
                            <img src="/front/images/logo/logo.png" alt="Site Logo"/>
                        </Link>
                    </div>
                    <div className="close-menu">
                        <button className="close-button">
                            <i className="ri-close-line"></i>
                        </button>
                    </div>
                </div>
                <ul className="mainmenu">
                    <li>
                        <Link to="#intro">Intro </Link>
                    </li>
                    <li>
                        <Link to="#faq">FAQ</Link>
                    </li>
                    <li>
                        <Link to="/about">About</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contact</Link>
                    </li>
                    <li>
                        <Link to="/logout">Logout</Link>
                    </li>
                    <li>
                        <Link to="/admin/login">Admin Login</Link>
                    </li>
                </ul>
            </div>
        </div>

        
        <div className="slider-area eduvibe-landing-banner bg-image" id="intro">
           <Link to="https://aolccollege.ca/" target='_blank'>
            <div className="d-flex align-items-center height-800">
            
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5 align-items-center">
                    
                        <div className="col-lg-6">
                            <div className="inner">
                                <div className="content text-start">
                                    <h1 className="title">
                                    Welcome to AOLCC
                                    </h1>
                                    <p>Empower Your Future Today!</p>
                                    <p>At AOLCC, we transform lives through education. Our flexible, quality programs are designed to fit your life and fuel your career success.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="banner-image">
                                <div className="banner-main-image">
                                <img className="landing-banner-hero-img" src="/front/images/banner/banner-Aolcc.png" alt="Tablet & Mobile View" />
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <div className="shape-wrapper">
                        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/front/images/shapes/shape-11-08.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/front/images/shapes/shape-03.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-3">
                                <img src="/front/images/shapes/shape-23-02.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            </Link>
        </div>
        
       
        <div className="l_career">
            <div className="container eduvibe-animated-shape">
                <div className="row row--50 align-items-center">
                    <div className="col-lg-12 col-lg-offset-1">
                        <div className="container eduvibe-animated-shape">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="inner">
                                <div className="content text-start">
                                    <h2 className="title">
                                    Academy of Learning Career College
                                    </h2>
                                    <h4>Canada’s Largest Career College Network</h4>
                                    <p>At Academy of Learning, we attribute our rapid growth to our success in identifying and filling the gap between the formal education currently available in Canada and the realities of the working world. We provide learners the solutions they need by offering customized, convenient and affordable programs.</p>
                                    <p>In today’s business world, where quality training is not only important but essential to career success, Academy of Learning Career College stands out as a training provider relied on by students, employers and government agencies. Students can choose from over 30 diploma and certificate programs offered at Academy of Learning Career College.</p>
                                
                                    <Link class="elementor-button elementor-button-link elementor-size-sm" to="/about">
                                         ABOUT AOLCC
                                    </Link>
                                
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="l_career-image">
                                    <img src="/front/images/banner/slider2.webp" alt="Tablet & Mobile View" />
                            </div>
                        </div>
                    </div>

                   
                </div>
                    </div>
                 
                </div>
              
            </div>
        </div>

      
        <div className="sercice-area eduvibe-service-four edu-section-gap bg-color-white">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center" >
                            <h3 className="title">OUR PROGRAMS</h3>
                        </div>
                    </div>
                </div>
                <div className="row g-5 mt--25">

                    <div className="col-lg-3 col-md-6 col-sm-6 col-12" >
                        <div className="service-card service-card-4">
                            <div className="inner">
                                <div className="icon">
                                    <i className="icon-student-read"></i>
                                </div>
                                <div className="content">
                                    <h6 className="title"><Link to="/">ACCOUNTING</Link></h6>
                                    <p className="description">In every business there is always a need for accounting professionals. Since every type of business or organization needs to..</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12" >
                        <div className="service-card service-card-4">
                            <div className="inner">
                                <div className="icon">
                                    <i className="icon-lab"></i>
                                </div>
                                <div className="content">
                                    <h6 className="title"><Link to="/">BUSINESS</Link></h6>
                                    <p className="description">If you have outstanding organizational skills, why not expand your capabilities with a diploma or certificate in Business?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12"  >
                        <div className="service-card service-card-4">
                            <div className="inner">
                                <div className="icon">
                                    <i className="icon-compass"></i>
                                </div>
                                <div className="content">
                                    <h6 className="title"><Link to="/">HEALTHCARE</Link></h6>
                                    <p className="description">The Healthcare Services sector of the economy is booming and producing employment opportunities at an unprecedented rate.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                 
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12"  >
                        <div className="service-card service-card-4">
                            <div className="inner">
                                <div className="icon">
                                    <i className="icon-calculator"></i>
                                </div>
                                <div className="content">
                                    <h6 className="title"><Link to="/">INFORMATION TECHNOLOGY</Link></h6>
                                    <p className="description">If you’re looking for a career on the cutting edge of the digital frontier, then IT may be just what you’re looking for.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12"  >
                        <div className="service-card service-card-4">
                            <div className="inner">
                                <div className="icon">
                                    <i className="icon-microscopes"></i>
                                </div>
                                <div className="content">
                                    <h6 className="title"><Link to="/">WEB AND GRAPHIC DESIGN</Link></h6>
                                    <p className="description">If you are a design-oriented person who loves technology? Are you an original thinker with creative flair and an eye for detail?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12" >
                        <div className="service-card service-card-4">
                            <div className="inner">
                                <div className="icon">
                                    <i className="icon-calculator"></i>
                                </div>
                                <div className="content">
                                    <h6 className="title"><Link to="/">HOME INSPECTION</Link></h6>
                                    <p className="description">Home Inspection is a young and growing professional consulting service aimed at helping home buyers make the biggest financial decision.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12" >
                        <div className="service-card service-card-4">
                            <div className="inner">
                                <div className="icon">
                                    <i className="icon-pen-tool"></i>
                                    <span className="subtitle">23 Courses</span>
                                </div>
                                <div className="content">
                                    <h6 className="title"><Link to="/">INTERNATIONAL HOSPITALITY MANAGEMENT</Link></h6>
                                    <p className="description">The International Hospitality Management Diploma from Academy of Learning College prepares students for careers in the demanding.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12" >
                        <div className="service-card service-card-4">
                            <div className="inner">
                                <div className="icon">
                                    <i className="icon-science"></i>
                                </div>
                                <div className="content">
                                    <h6 className="title"><Link to="/">PROJECT ADMINISTRATION</Link></h6>
                                    <p className="description">Project Management is a function of business that is growing in relevance, recognition and scope.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/front/images/shapes/shape-11-02.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/front/images/shapes/shape-02-03.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/front/images/shapes/shape-20.png" alt="Shape Thumb" />
                    </div>
                </div>

            </div>
        </div>
       

       
        
        <Footer />

    </div>

    
    </>
    
)}


<div className="rn-progress-parent">
        <svg className="rn-back-circle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
    </div>
    </>
  )
}

export default Home