import React, { useState, useEffect } from 'react'
import { useNavigate, useParams,Link } from "react-router-dom";
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import axios from 'axios';
import toast from 'react-hot-toast';
const CampusEdit = () => {

const initialValues = { campus: "", name_of_contact: "", position: "", email:"",po_box:"",unit:"",street_no:"",street_name:"",city:"",country:"",state:"",postal_code:"",telephone_number:"",fax_no:"",dli_no:"",pcc_id:"",status:"" };
const [formData, setFormValues] = useState(initialValues);
const [formErrors, setFormErrors] = useState({});
const [isSubmit, setIsSubmit] = useState(false);

const params = useParams();
const navigate = useNavigate();


const handleInputChange = (e) => {
   const { name, value } = e.target;
   setFormValues({ ...formData, [name]: value });
 };

 const handleSubmit = (e) => {
   e.preventDefault();
    const errors = validate(formData);
   setFormErrors(errors);
   
   if (Object.keys(errors).length === 0) {
    
      setIsSubmit(true);
      axios.put(`/api/campus/${params.id}`, formData)
       .then((response) => {
         if(response.data.success){
            toast.success(response.data.success);

               axios.post('/api/activityLog', {
                  log_name: 'Campus Update',
                  log_type: 'campus',
                  log_id: params.id
               });

               // Clear the input fields
               setFormValues(initialValues);
               navigate('/admin/campus');

         }else{
            toast.error(response.data.error);
         }
       })
       .catch((error) => {
         console.error('Submission failed', error);
       })
       .finally(() => {
         setIsSubmit(false);
       });
     
   }

 };

 useEffect(() => {
   axios.get(`/api/campus/${params.id}`).then((response) => {
     setFormValues(response.data);
   });
 }, [params.id]); 


 const validate = (values) => {
   const errors = {};
   const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
   if (!values.campus) {
     errors.campus = "Campus Name is required!";
   }

   if (!values.name_of_contact) {
      errors.name_of_contact = "Contact Name is required!";
   }

   if (!values.position) {
      errors.position = "Position is required!";
   }

   if (!values.email) {
     errors.email = "Email is required!";
   } else if (!regex.test(values.email)) {
     errors.email = "This is not a valid email format!";
   }

   // if (!values.password) {
   //   errors.password = "Password is required";
   // } else if (values.password.length < 4) {
   //   errors.password = "Password must be more than 4 characters";
   // } else if (values.password.length > 10) {
   //   errors.password = "Password cannot exceed more than 10 characters";
   // }
   return errors;
 };

return (
<>
<div className="wrapper">
   <AdminHeader />
   <AdminSidebar />
   <div className="content-wrapper">
      <section className="content mt-2">
         <div className="row">
            <div className="col-md-12">
               <div className="card card-dark">
                  <div className="card-header">
                     <h3 className="card-title">Edit Campus</h3>
                     <div className="card-tools">
                        <Link to="/admin/campus" className="btn btn-primary btn-sm"> Back</Link>
                     </div>
                  </div>
                  <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        <div className='row'>
                           <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="LastName">
                                Campus
                                </label>
                                <input name="campus" value={formData.campus} onChange={handleInputChange} type="campus" className="input form-control" id="campus" placeholder="Campus" />
                                <p className='mt-0 text-danger'>{formErrors.campus}</p>

                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                  <label htmlFor="LastName">
                                  Name of Contact
                                  </label>
                                  <input name="name_of_contact" value={formData.name_of_contact} onChange={handleInputChange} type="text" className="input form-control" id="name_of_contact" placeholder="Name of Contact" />
                                  <p className='mt-0 text-danger'>{formErrors.name_of_contact}</p>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                  <label htmlFor="LastName">
                                  Position
                                  </label>
                                  <input name="position" value={formData.position} onChange={handleInputChange} type="text" className="input form-control" id="position" placeholder="Position" />
                                  <p className='mt-0 text-danger'>{formErrors.position}</p>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                  <label htmlFor="LastName">
                                  Email
                                  </label>
                                  <input name="email" value={formData.email} onChange={handleInputChange} type="text" className="input form-control" id="email" placeholder="Email" />
                                  <p className='mt-0 text-danger'>{formErrors.email}</p>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                  <label htmlFor="LastName">
                                  PO Box
                                  </label>
                                  <input name="po_box" value={formData.po_box} onChange={handleInputChange} type="text" className="input form-control" id="po_box" placeholder="PO Box" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                  <label htmlFor="LastName">
                                  Apt/Unit
                                  </label>
                                  <input name="unit" value={formData.unit} onChange={handleInputChange} type="text" className="input form-control" id="unit" placeholder="Apt/Unit" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                  <label htmlFor="LastName">
                                  Street No
                                  </label>
                                  <input name="street_no" value={formData.street_no} onChange={handleInputChange} type="text" className="input form-control" id="street_no" placeholder="Street No" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                  <label htmlFor="LastName">
                                  Street Name
                                  </label>
                                  <input name="street_name" value={formData.street_name} onChange={handleInputChange} type="text" className="input form-control" id="street_name" placeholder="Street Name" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                  <label htmlFor="LastName">
                                  City/Town
                                  </label>
                                  <input name="city" value={formData.city} onChange={handleInputChange} type="text" className="input form-control" id="city" placeholder="City/Town" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                  <label htmlFor="LastName">
                                  Country 
                                  </label>
                                  <input name="country" value={formData.country} onChange={handleInputChange} type="text" className="input form-control" id="country" placeholder="Country " />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                  <label htmlFor="LastName">
                                  Province/State
                                  </label>
                                  <input name="state" value={formData.state} onChange={handleInputChange} type="text" className="input form-control" id="state" placeholder="Province/State" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                  <label htmlFor="LastName">
                                  Postal Code
                                  </label>
                                  <input name="postal_code" value={formData.postal_code} onChange={handleInputChange} type="text" className="input form-control" id="postal_code" placeholder="Postal Code" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                  <label htmlFor="LastName">
                                  Telephone Number
                                  </label>
                                  <input name="telephone_number" value={formData.telephone_number} onChange={handleInputChange} type="text" className="input form-control" id="telephone_number" placeholder="Telephone Number" maxLength={13} />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                  <label htmlFor="LastName">
                                  Fax Number
                                  </label>
                                  <input name="fax_no" value={formData.fax_no} onChange={handleInputChange} type="text" className="input form-control" id="fax_no" placeholder="Fax Number" maxLength={13} />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                  <label htmlFor="LastName">
                                  DLI Number
                                  </label>
                                  <input name="dli_no" value={formData.dli_no} onChange={handleInputChange} type="text" className="input form-control" id="dli_no" placeholder="DLI Number" maxLength={13} />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                  <label htmlFor="LastName">
                                  PCC ID
                                  </label>
                                  <input name="pcc_id" value={formData.pcc_id} onChange={handleInputChange} type="text" className="input form-control" id="pcc_id" placeholder="PCC ID" maxLength={13} />
                                </div>
                              </div>
                           <div className="col-md-6">
                              <div className="form-group">
                                 <label htmlfor="inputStatus">Status</label>
                                 <select id="inputStatus" name="status" value={formData.status} onChange={handleInputChange} className="form-control custom-select">
                                    <option value>Select Status</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">InActive</option>
                                 </select>
                              </div>
                           </div>
                           <div className='col-md-12'>
                           <button type="submit" className="btn btn-success btn-sm" disabled={isSubmit}>
                           {isSubmit ? 'Updatting...' : 'Update'}
                           </button>
                        </div>
                        </div>
                     </form>
                        <div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
        
      </section>
   </div>
   <br/>
   <AdminFooter />
</div>
</>
);
};
export default CampusEdit;