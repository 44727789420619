import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import UserIcon from "../images/user_icon.png";
// import Userpic from "../images/userpic.png";
import Navebar from "./Navebar";
import Footer from "./Footer";
import axios from 'axios';
import toast from 'react-hot-toast';


const Profile = () => {
    const navigate  = useNavigate();
    const params = useParams();
    const [isSubmit, setIsSubmit] = useState(false);
    const [studentData, setStudentData] = useState(0);

    const initValues = { email_cloa: "", firstName: "", lastName:"",email:"",dob:"",campus_id:"",unit:"",street_no:"",street_name:"",city:"",state:"",country:"",postal_code:"",study_program_id:"",program_fees:"",prepaid_fee:"",remaining_fees:"",st_date:"",end_year_date:"",expire_date:"",other_info:"",status:"inactive",password:"",phone:"",is_travel_letter_request:""  };
   const [SformData, setSFormValues] = useState(initValues);
   const [formErrors, setFormErrors] = useState({});
   const [campusList, setCampusRecords] = useState([]);
   const [studyProgramList, setStudyProgramRecords] = useState([]);
   //const [studyProgramDetails, setStudyProgramDetailsRecords] = useState({});
   
   const { REACT_APP_API_URL_LOCAL_SERVER, REACT_APP_API_URL_PRODUCTION_SERVER, REACT_APP_ENV_TYPE } = process.env;
   const UPLOADS_URL = REACT_APP_ENV_TYPE === 'production' ? REACT_APP_API_URL_PRODUCTION_SERVER : REACT_APP_API_URL_LOCAL_SERVER;
  
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSFormValues((SformData) => ({ ...SformData, [name]: value }));
        if(name === 'campus_id'){
           if(value){
              getstudyprogram(value);
           }else{
              setStudyProgramRecords([]);
           }
           setSFormValues((SformData) => ({ ...SformData, prepaid_fee: 0,remaining_fees: 0,program_fees: 0, }));
        }
     
        if (name === 'st_date') {
           if (value) {
             const newDate = new Date(value);
             newDate.setFullYear(newDate.getFullYear() + 1);
         
             const expireDate = new Date(value);
             expireDate.setMonth(expireDate.getMonth() + 2); // Add one month
             expireDate.setDate(0 - 1); // Subtract one day
         
             setSFormValues((SformData) => ({
               ...SformData,
               end_year_date: newDate.toISOString().split('T')[0],
               expire_date: expireDate.toISOString().split('T')[0]
             }));
           } else {
            setSFormValues((SformData) => ({ ...SformData, end_year_date: '', expire_date: '' }));
           }
         }
         
        if(name === 'study_program_id'){
           if(value){
              getstudyprogramDetails(value);
           }else{
              //setStudyProgramDetailsRecords({});
              setSFormValues((SformData) => ({ ...SformData, prepaid_fee: 0,remaining_fees: 0,program_fees: 0, }));
           }

           if(value){
            const selectedProgramId = e.target.value;
            const selectedProgram = studyProgramList.find(program => program._id === selectedProgramId);
            
            if(selectedProgram.program === 'Medical Office Administration'){
                setSFormValues((SformData) => ({ ...SformData, other_info: 'Successful completion of the medical office administration diploma program qualifies student for exemption from semesters 1 and 2 of Georgian College 2-year office administration – health services diploma program (see Appendix 1).' }));
            }else{
                setSFormValues((SformData) => ({ ...SformData, other_info:'' }));
            }
          }
          
         
        }
     
        if(name === 'prepaid_fee'){
           const remaining_fees = (SformData.program_fees - value);
           setSFormValues((SformData) => ({ ...SformData, remaining_fees: remaining_fees }));
        }
     
      };

      const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.firstName) {
          errors.firstName = "First Name is required!";
        }
     
        if (!values.lastName) {
           errors.lastName = "Last Name is required!";
        }
     
         if (!values.email) {
          errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
          errors.email = "This is not a valid email format!";
        }
     
      
        return errors;
      };


      const getstudyprogram = (campus_id) => {
        axios.get(`/api/studyprogram/${campus_id}`).then((response) => {
           setStudyProgramRecords(response.data);
         });
      }
     
      const getstudyprogramDetails = (id) => {
        axios.get(`/api/study/program/${id}`).then((response) => {
           //setStudyProgramDetailsRecords(response.data);
     
           const remaining_fees = (response.data.price - SformData.prepaid_fee);
           setSFormValues((SformData) => ({ ...SformData, program_fees: response.data.price,remaining_fees: remaining_fees }));
     
         });
      }


      const handleSubmit = (e) => {
        e.preventDefault();
     
        const errors = validate(SformData);
        setFormErrors(errors);
     
        
        if (Object.keys(errors).length === 0) {
          
        if(SformData.is_travel_letter_request === 1){
            if (!formData?.VisaCopy) {
                toast.error("Please Upload Visa Copy");
                return false;
            }
        
            if (!formData?.FlightTicketUpload) {
                toast.error("Please Upload Flight Ticket upload");
                return false;
            }
        
            if (!formData?.visa_number) {
                toast.error("Please Upload Visa/UCI Number");
                return false;
            }
        }
              

            
        setIsSubmit(true);
          axios.put(`/api/student/${studentData.data._id}`, SformData)
            .then((response) => {
              if(response.data.success){
                 toast.success(response.data.success);
                 //navigate(`/admin/student/verify/${params.id}`);
                    // Clear the input fields
                    // setFormValues(initialValues);
              }else{
                 toast.error(response.data.error);
              }
            })
            .catch((error) => {
              console.error('Submission failed', error);
            })
            .finally(() => {
              setIsSubmit(false);
            });
        }
      };


    // Document Upload
    const initialValues = { AcademicDoc:"",Trf:"", VisaCopy:""  };
    const [formData, setFormValues] = useState(initialValues);

    const [AcademicDoc, setAcademicDoc] = useState(null);
    const [Trf, setTrf] = useState(null);
    const [passport_number, setPassportNumber] = useState('');
    const [passportCopy, setPassportCopy] = useState('');
    const [visa_number, setVisaNumber] = useState('');
    const [paymentreceipt, setPaymentreceipt] = useState('');
    const [VisaCopy, setVisaCopy] = useState(null);
    const [image, setImage] = useState(null);
    const [FlightTicketUpload, setFlightTicketUpload] = useState('');

    const handlePassportChange = (e) => {
    setPassportNumber(e.target.value);
    };

    const handleFileChangeFlightTicketUpload = (e) => {
        setFlightTicketUpload(e.target.files[0]);
    };

    const handleVisaChange = (e) => {
    setVisaNumber(e.target.value);
    };

    const handleFileChangeAcademicDoc = (e) => {
    setAcademicDoc(e.target.files[0]);
    };
    const handleFileChangeTrf = (e) => {
    setTrf(e.target.files[0]);
    };
    const handleFileChangePassport = (e) => {
    setPassportCopy(e.target.files[0]);
    };
    const handleFileChangeVisaCopy = (e) => {
    setVisaCopy(e.target.files[0]);
    };

    const handlePhotoChange = (e) => {
    setImage(e.target.files[0]);
    };

    const handleFileChangePaymentreceipt = (e) => {
        setPaymentreceipt(e.target.files[0]);
     };

     
    const handleUpload = async () => {

    setIsSubmit(true);

    const formData = new FormData();
        formData.append("AcademicDoc", AcademicDoc);
        formData.append("FlightTicketUpload", FlightTicketUpload);
        formData.append("Trf", Trf);
        formData.append('passportCopy', passportCopy);
        formData.append('paymentreceipt', paymentreceipt);
        formData.append('passport_number', passport_number);
        formData.append("VisaCopy", VisaCopy);
        formData.append('visa_number', visa_number);
        formData.append("profile_photo", image);
    try {
        // Upload file using axios
        if (studentData && studentData.data && studentData.data._id) {
            const uploadResponse = await axios.post(`/api/student/upload/${studentData.data._id}`,formData,{
                headers: { "Content-Type": "multipart/form-data" },
                }
            );

            if (uploadResponse.data.success) {
                setIsSubmit(false); 
                // Log an activity using axios
                axios.post('/api/activityLog', {
                log_name: 'Student Document Uploads',
                log_type: 'student',
                log_id: params.id
                });
                toast.success(uploadResponse.data.success);
                setTimeout(() => {
                    window.location.reload();
                  }, 1000);
            } else {
                setIsSubmit(false); 
                toast.error(uploadResponse.data.error); 
            }
        }
            
    } catch (error) {
        setIsSubmit(false);
        toast.error(error.message);
        console.error('Error uploading files:', error.message);
    }
    };


    useEffect(() => {

        axios.get('/api/campus').then((response) => {
            setCampusRecords(response.data);
         });
        axios.get('/api/study/program').then((response) => setStudyProgramRecords(response.data));

        const dataFetch = async () => {
            try{
                const data = await ( await fetch("/profile",{
                            method: "GET",
                            headers: {
                                Accept: "application/json", "Content-Type": "application/json"
                            },
                            credentials: "include"
                        })).json();

                        const getidbyemail = await axios.post('/api/getidbyemail', {
                            user_email: data.email,
                            user_id: data._id
                        });
                        
                setStudentData(getidbyemail);
                //setData(data);
            }catch(err){
                navigate('/login')
                    console.log(err);
            }
        };
        dataFetch();


    }, [navigate]);


    useEffect(() => {
        // Check if studentData and studentData.data are defined
        if (studentData && studentData.data && studentData.data._id) {
          axios.get(`/api/student/${studentData.data._id}`).then((response) => {
            if (response.data && response.data.visa_number) {
              setVisaNumber(response.data.visa_number);
            } else {
              setVisaNumber('0');
            }
            if (response.data && response.data.passport_number) {
              setPassportNumber(response.data.passport_number);
            } else {
              setPassportNumber('0'); // Fixed this line, was using setVisaNumber before
            }
            setFormValues(response.data);
          });


          axios.get(`/api/student/${studentData.data._id}`).then((response) => {
            setSFormValues(response.data);
            setSFormValues((SformData) => ({ ...SformData, status: 'inactive' }));
         });


        }
      }, [studentData]);
      

  return (
    <>
     <div className="main-wrapper website">
    <Navebar />
    <div className="pv-shop-area commonpage">        
     
                  
                  <div className="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
                    <div className="container eduvibe-animated-shape">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-start">
                                    <div className="page-title">
                                        <h3 className="title">My Account</h3>
                                    </div>
                                    <nav className="edu-breadcrumb-nav">
                                        <ol className="edu-breadcrumb d-flex justify-content-start liststyle">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="separator"><i className="ri-arrow-drop-right-line"></i></li>
                                            <li className="breadcrumb-item active" aria-current="page">My Account</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
       
        </div>
    <div className="pv-shop-area bg-image bg-image--36 mt-5">        
       <div className="container eduvibe-animated-shape"> 
            
                <div className="row">   
                    <div class="col-md-12">
                        

                        {SformData.travel_letter_downloads < 1 && SformData.travel_letter_approved_rejected === 1 && (
                            <div class="alert alert-success">
                                <strong>{SformData.travel_letter_message}</strong> <br/>
                                <Link to={`/student-travel-letter-download/${SformData._id}`}>
                                <i className='fas fa-download mr-2 text-success'></i> DOWNLOAD TRAVEL LETTER
                                </Link>
                            </div>
                        )}

                    </div>
                    <div className="col-md-3">
                        
                    </div>
                    <div className="col-md-9">
                        <div className="profile-head">
                
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                                        Profile</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                                        Documents</a>
                                </li>
                            </ul>
                        </div>
                    </div>
             
                    
                </div>
                <div className="row">

                    <div className="col-md-3">
                       <div className="profile-img"> 
                                {formData.profile_photo ? (
                                 <img  src={UPLOADS_URL+formData.profile_photo} alt='profile' />
                                 ) : (<></>)}
                            {/* <img src={ userData.name === 'Test' ? Userpic : UserIcon} alt=""/> */}
                            <div className="file btn btn-lg btn-primary">
                                Profile Photo
                                {/* <input type="file" name="file"/> */}
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-9">
                        <div className="tab-content profile-tab" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    
                                <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    
                                
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="firstName">
                                            First Name
                                            </label>
                                            <input name="firstName" value={SformData.firstName} onChange={handleInputChange} type="text" className="input form-control" id="firstName" placeholder="Enter First Name" />
                                            <p className='mt-0 text-danger'>{formErrors.firstName}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="lastName">
                                            Last Name
                                            </label>
                                            <input name="lastName" value={SformData.lastName} onChange={handleInputChange} type="text" className="input form-control" id="lastName" placeholder="Enter Last Name" />
                                            <p className='mt-0 text-danger'>{formErrors.lastName}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="email">
                                            Email
                                            </label>
                                            <input name="email" value={SformData.email} onChange={handleInputChange} type="text" className="input form-control" id="email" placeholder="Email" readOnly/>
                                            <p className='mt-0 text-danger'>{formErrors.email}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="phone">
                                            Phone
                                            </label>
                                            <input
                                            type="text"
                                            id="inputphone"
                                            name="phone"
                                            value={SformData.phone}
                                            placeholder='Enter phone'
                                            onChange={handleInputChange}
                                            className="form-control"
                                            />  
                                        <p className='mt-0 text-danger'>{formErrors.phone}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="password">
                                            Password
                                            </label>
                                            <input name="password" value={SformData.password} onChange={handleInputChange} type="text" className="input form-control" id="password" placeholder="Password" />
                                            <p className='mt-0 text-danger'>{formErrors.password}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="dob">
                                            Date of Birth
                                            </label>
                                            <input name="dob" value={SformData.dob} onChange={handleInputChange} type="date" className="input form-control" id="dob" placeholder="Date of birth" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="campus_id">
                                            Campus
                                            </label>
                                            <select id="campus_id" name="campus_id" value={SformData.campus_id._id} onChange={handleInputChange} className="form-control custom-select">
                                            <option value="">Select</option>
                                            {campusList.map((cam) => (
                                                <option key={cam._id} value={cam._id}>{cam.campus}</option>
                                            ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group text-info">Address</div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="LastName">
                                            Apt/Unit
                                            </label>
                                            <input name="unit" value={SformData.unit} onChange={handleInputChange} type="text" className="input form-control" id="unit" placeholder="Apt/Unit" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="LastName">
                                            Street No
                                            </label>
                                            <input name="street_no" value={SformData.street_no} onChange={handleInputChange} type="text" className="input form-control" id="street_no" placeholder="Street No" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="LastName">
                                            Street Name
                                            </label>
                                            <input name="street_name" value={SformData.street_name} onChange={handleInputChange} type="text" className="input form-control" id="street_name" placeholder="Street Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="LastName">
                                            City/Town
                                            </label>
                                            <input name="city" value={SformData.city} onChange={handleInputChange} type="text" className="input form-control" id="city" placeholder="City/Town" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="LastName">
                                            Province/State
                                            </label>
                                            <input name="state" value={SformData.state} onChange={handleInputChange} type="text" className="input form-control" id="state" placeholder="Province/State" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="LastName">
                                            Country 
                                            </label>
                                            <input name="country" value={SformData.country} onChange={handleInputChange} type="text" className="input form-control" id="country" placeholder="Country " />
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="LastName">
                                            Postal Code
                                            </label>
                                            <input name="postal_code" value={SformData.postal_code} onChange={handleInputChange} type="text" className="input form-control" id="postal_code" placeholder="Postal Code" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="LastName">
                                            Study Program
                                            </label>
                                            <select id="inputStudyProgram" name="study_program_id" value={SformData.study_program_id._id} onChange={handleInputChange} className="form-control custom-select">
                                            <option value="">Select</option>
                                            {studyProgramList.map((cam) => (
                                                <option key={cam._id} value={cam._id}>{cam.program}</option>
                                            ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="LastName">
                                            Program Fees
                                            </label>
                                            <input name="program_fees" value={SformData.program_fees} readOnly onChange={handleInputChange} type="text" className="input form-control" id="program_fees" placeholder="Program Fees" maxLength={13}  />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="LastName">
                                            Prepaid Fees
                                            </label>
                                            <input name="prepaid_fee" value={SformData.prepaid_fee} readOnly onChange={handleInputChange} type="text" className="input form-control" id="prepaid_fee" placeholder="Prepaid Fees" maxLength={13} />
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="LastName">
                                            Remaining Fees
                                            </label>
                                            <input name="remaining_fees" value={SformData.remaining_fees} readOnly onChange={handleInputChange} type="text" className="input form-control" id="remaining_fees" placeholder="Remaining Fees" maxLength={13} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="LastName">
                                            Start Date
                                            </label>
                                            <input name="st_date" value={SformData.st_date} onChange={handleInputChange} type="date" className="input form-control" id="st_date" placeholder="Start Date" maxLength={13} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="LastName">
                                            End Date
                                            </label>
                                            <input name="end_year_date" value={SformData.end_year_date} onChange={handleInputChange} type="date" className="input form-control" id="end_year_date" placeholder="End Date" maxLength={13} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="LastName">
                                            Expiry Date
                                            </label>
                                            <input name="expire_date" value={SformData.expire_date} onChange={handleInputChange} type="date" className="input form-control" id="expire_date" placeholder="Expiry Date" maxLength={13} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="LastName">
                                            Other Info
                                            </label>
                                            <input name="other_info" value={SformData.other_info} onChange={handleInputChange} type="text" className="input form-control" id="other_info" placeholder="Other Info" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="campus_id">
                                            Travel Letter Request
                                            </label>
                                            <select id="is_travel_letter_request" name="is_travel_letter_request" value={SformData.is_travel_letter_request} onChange={handleInputChange} className="form-control">
                                            <option value="0">Select</option>
                                            <option value="1">Send Request</option>
                                            </select>
                                        </div>
                                    </div>

                                    
                                    <div className='col-md-12'>
                                    <button type="submit" className="btn btn-success btn-sm" disabled={isSubmit}>
                                        {isSubmit ? 'Verifying...' : 'Submit'}
                                    </button>
                                    </div>
                                </div>
                                </form>

                                      
                            </div>

                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                        
                              <div className="card-body">
                              <div className='row'>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="LastName">
                                        Academic Document/ECA Submission
                                        </label>
                                        <input name="academic_doc" onChange={handleFileChangeAcademicDoc} type="file" className="input form-control" id="academic_doc" />
                                        {formData.AcademicDoc ? (
                                    <Link download target='_blank' to={UPLOADS_URL+formData.AcademicDoc}>Download</Link>
                                        
                                    ) : (<></>)}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    TRF
                                    </label>
                                    <input name="trf" onChange={handleFileChangeTrf} type="file" className="input form-control" id="trf" />
                                    {formData.Trf ? (
                                 <Link download target='_blank' to={UPLOADS_URL+formData.Trf}>Download</Link>
                                 ) : (<></>)}
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Passport Copy
                                    </label>
                                    <input name="passport" onChange={handleFileChangePassport} type="file" className="input form-control" id="passport" />
                                    {formData.passportCopy ? (
                                 <Link download target='_blank' to={UPLOADS_URL+formData.passportCopy}>Download</Link>
                                 ) : (<></>)}
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Passport Number (If changed or not submitted before or modified)
                                    </label>
                                    
                                    <input name="passport_number" value={passport_number} onChange={handlePassportChange} type="text" className="input form-control" id="passport_number" placeholder="Passport number" />
                                 </div>
                              </div>

                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Profile Photo
                                    </label>
                                    <input name="profile_photo"  type="file" className="input form-control" id="profile_photo" onChange={handlePhotoChange} placeholder="Profile Photo" />
                                    
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Payment Transfer Receipt
                                    </label>
                                    <input name="paymentreceipt" onChange={handleFileChangePaymentreceipt} type="file" className="input form-control" id="paymentreceipt" />
                                    {formData && formData.paymentreceipt ? (
                                 <Link download target='_blank' to={UPLOADS_URL+formData.paymentreceipt}>Download</Link>
                                 ) : (<></>)}
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Visa/UCI Number
                                    </label>
                                    <input name="visa_number"  value={visa_number} onChange={handleVisaChange} type="text" className="input form-control" id="visa_number" placeholder="Visa number" />
                                    
                                 </div>
                              </div> 
                              
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Visa Copy
                                    </label>
                                    <input name="visa_copy" onChange={handleFileChangeVisaCopy} type="file" className="input form-control" id="visa_copy" />
                                    {formData.VisaCopy ? (
                                 <Link download target='_blank' to={UPLOADS_URL+formData.VisaCopy}>Download</Link>
                                 ) : (<></>)}
                                 </div>
                              </div>

                              

                              <div className="col-md-6">
                                 <div className="form-group">
                                    <label htmlFor="LastName">
                                    Flight Ticket upload
                                    </label>
                                    <input name="flight_ticket_upload" onChange={handleFileChangeFlightTicketUpload} type="file" className="input form-control" id="flight_ticket_upload" />
                                    {formData.FlightTicketUpload ? (
                                    <Link download target='_blank' to={UPLOADS_URL+formData.FlightTicketUpload}>Download</Link>
                                    ) : (<></>)}
                                 </div>
                              </div>
   
                              

                                <div className='col-md-12'>
                                <button onClick={handleUpload} type="submit" className="btn btn-success btn-sm" disabled={isSubmit}>
                                    {isSubmit ? 'Verifying...' : 'Upload'}
                                </button>
                                </div>

                              </div>           
                              </div>         
   
                            </div>
                        </div>
                    </div>
                </div>
                     
            </div>
                  </div>
      <Footer />
      </div>
    </>
  );
    
};

export default Profile;
