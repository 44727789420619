import React, { createContext, useReducer } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import PrivateRoutes from './utils/PrivateRoutes'

import Home from './components/Home';
import About from './components/About';
import Profile from './components/Profile';
import Contact from './components/Contact';
import Login from './components/Login';
import AdminLogin from './components/admin/Login';
import AdminDashboard from './components/admin/Dashboard';
import AdminLogout from './components/admin/AdminLogout';

import AdminUsers from './components/admin/users/AdminUsers'
import StudyProgram from './components/admin/study/Program'

import Roles from './components/admin/roles/Roles';

import Signup from './components/Signup';
import StudentRegisterRequest from './components/StudentRegisterRequest';


import Logout from './components/Logout';

import { initialState, reducer } from './reducer/useReducer';
import { Toaster  } from 'react-hot-toast';

import CampusListing from './components/admin/campus/CampusListing';
import CampusCreate from './components/admin/campus/CampusCreate';
import CampusEdit from './components/admin/campus/CampusEdit';

import ActivityLogListing from './components/admin/log/LogListing';

import LoaListing from './components/admin/loa/LoaListing';
import LoaCreate from './components/admin/loa/LoaCreate';
import LoaEdit from './components/admin/loa/LoaEdit';
import LoaVerify from './components/admin/loa/LoaVerify';
import LoaView from './components/admin/loa/LoaView';
import LoaVerifyDownload from './components/admin/loa/LoaVerifyDownload';
import LoaPdfView from './components/admin/loa/LoaPdfView';
import PaymentHistoryListing from './components/admin/loa/PaymentHistoryListing';

import StudentListing from './components/admin/student/StudentListing';
import StudentCreate from './components/admin/student/StudentCreate';
import StudentEdit from './components/admin/student/StudentEdit';

import StudentUpload from './components/admin/student/StudentUpload';
import LoaUpload from './components/admin/loa/LoaUpload';
import CloaUpload from './components/admin/cloa/CloaUpload';

import StudentVerify from './components/admin/student/StudentVerify';
import StudentView from './components/admin/student/StudentView';
import StudentVerifyDownload from './components/admin/student/StudentVerifyDownload';
import StudentPaymentHistoryListing from './components/admin/student/StudentPaymentHistoryListing';
import StudentPdfView from './components/admin/student/StudentPdfView';



import CloaListing from './components/admin/cloa/CloaListing';
import CloaCreate from './components/admin/cloa/CloaCreate';
import CloaEdit from './components/admin/cloa/CloaEdit';
import CloaVerify from './components/admin/cloa/CloaVerify';
import CloaView from './components/admin/cloa/CloaView';
import CloaVerifyDownload from './components/admin/cloa/CloaVerifyDownload';
import CloaPaymentHistoryListing from './components/admin/cloa/CloaPaymentHistoryListing';
import CloaPdfView from './components/admin/cloa/CloaPdfView';

import LoaTravelLetterList from './components/admin/travelletter/LoaTravelLetterList';
import CloaTravelLetterList from './components/admin/travelletter/CloaTravelLetterList';
import StudentTravelLetterList from './components/admin/travelletter/StudentTravelLetterList';

import LoaTravelLetterEdit from './components/admin/travelletter/LoaTravelLetterEdit';
import CloaTravelLetterEdit from './components/admin/travelletter/CloaTravelLetterEdit';
import StudentTravelLetterEdit from './components/admin/travelletter/StudentTravelLetterEdit';

import LoaTravelLetterPDF from './components/admin/travelletter/LoaTravelLetterPDF';
import CloaTravelLetterPDF from './components/admin/travelletter/CloaTravelLetterPDF';
import StudentTravelLetterPDF from './components/admin/travelletter/StudentTravelLetterPDF';

import StudentRegisterReq from './components/admin/studentregreq/StudentRegisterReq';




import StudentTravelLetterFromFront from './components/StudentTravelLetterFromFront';
import CommunicationStatus from './components/admin/communication/CommunicationStatus';







export const UserContext = createContext();

const Routing = () => {
    return (
      <Router>
        <Routes>
                <Route exact={true} path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/student-travel-letter-download/:id" element={<StudentTravelLetterFromFront />} />

                <Route path="/contact" element={<Contact />} />
                <Route path="/login" element={<Login />} /> 
                <Route path="/student-register-request" element={<StudentRegisterRequest />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/logout" element={<Logout />} />
                
                {/* <Route path="/admin/student/documents/upload/:id" element={<StudentUpload />} /> */}
          </Routes>
        </Router>
    )
}

const AdminRouting = () => {
  
  return (
    <Router>
      <Routes>
              <Route path="/admin/login" element={<AdminLogin utype="admin" />} />
              <Route path="/agent/login" element={<AdminLogin utype="agent" />} />

              <Route element={<PrivateRoutes />}>
                <Route path="/admin/dashboard" element={<AdminDashboard />} />
                <Route path="/admin/roles" element={<Roles />} />
                <Route path="/admin/communication" element={<CommunicationStatus />} />
                <Route path="/admin/users" element={<AdminUsers />} />
                <Route path="/admin/study/program" element={<StudyProgram />} />
                <Route path="/admin/campus" element={<CampusListing />} />
                
                <Route path="/admin/campus/create" element={<CampusCreate />} />
                <Route path="/admin/campus/edit/:id" element={<CampusEdit />} />
                <Route path="/admin/activity/log" element={<ActivityLogListing />} />

                <Route path="/admin/loa" element={<LoaListing />} />
                <Route path="/admin/loa/create" element={<LoaCreate />} />
                <Route path="/admin/loa/edit/:id" element={<LoaEdit />} />
                <Route path="/admin/loa/view/:id" element={<LoaView />} />
                <Route path="/admin/loa/verify/:id" element={<LoaVerify />} />
                <Route path="/admin/loa/verifydownload/:id" element={<LoaVerifyDownload />} />
                <Route path="/admin/loa/paymentlist/:id" element={<PaymentHistoryListing />} />
                <Route path="/admin/loa/pdfdownload/:id" element={<LoaPdfView />} />

                <Route path="/admin/student" element={<StudentListing />} />
                <Route path="/admin/student/create" element={<StudentCreate />} />
                <Route path="/admin/student/edit/:id" element={<StudentEdit />} />
                <Route path="/admin/student/view/:id" element={<StudentView />} />
                <Route path="/admin/student/documents/upload/:id" element={<StudentUpload />} />
                <Route path="/admin/loa/documents/upload/:id" element={<LoaUpload />} />
                <Route path="/admin/cloa/documents/upload/:id" element={<CloaUpload />} />

                <Route path="/admin/student/verify/:id" element={<StudentVerify />} />
                <Route path="/admin/student/verifydownload/:id" element={<StudentVerifyDownload />} />
                <Route path="/admin/student/paymentlist/:id" element={<StudentPaymentHistoryListing />} />
                <Route path="/admin/student/pdfdownload/:id" element={<StudentPdfView />} />

                <Route path="/admin/cloa" element={<CloaListing />} />
                <Route path="/admin/cloa/create" element={<CloaCreate />} />
                <Route path="/admin/cloa/edit/:id" element={<CloaEdit />} />
                <Route path="/admin/cloa/view/:id" element={<CloaView />} />
                <Route path="/admin/cloa/verify/:id" element={<CloaVerify />} />
                <Route path="/admin/cloa/verifydownload/:id" element={<CloaVerifyDownload />} />
                <Route path="/admin/cloa/paymentlist/:id" element={<CloaPaymentHistoryListing />} />
                <Route path="/admin/cloa/pdfdownload/:id" element={<CloaPdfView />} />

                <Route path="/admin/loa-travel-letter" element={<LoaTravelLetterList />} />
                <Route path="/admin/loa-travel-letter/edit/:id" element={<LoaTravelLetterEdit />} />
                <Route path="/admin/loa/travel-letter-download/:id" element={<LoaTravelLetterPDF />} />

                <Route path="/admin/cloa-travel-letter" element={<CloaTravelLetterList />} />
                <Route path="/admin/cloa-travel-letter/edit/:id" element={<CloaTravelLetterEdit />} />
                <Route path="/admin/cloa/travel-letter-download/:id" element={<CloaTravelLetterPDF />} />

                <Route path="/admin/student-travel-letter" element={<StudentTravelLetterList />} />
                <Route path="/admin/student-travel-letter/edit/:id" element={<StudentTravelLetterEdit />} />
                <Route path="/admin/student/travel-letter-download/:id" element={<StudentTravelLetterPDF />} />

                <Route path="/admin/student-register-req" element={<StudentRegisterReq />} />

                
                
                <Route path="/admin/adminlogout" element={<AdminLogout />} />

              </Route>
              
        </Routes>
      </Router>
  )
}

const App = () => {

  const [state, dispatch] = useReducer(reducer, initialState);
  
  if(!state && localStorage.getItem('UserF')){
    dispatch({type:"USER_FRONT", payload:true});
  }

  return (

    <>
        <Toaster  position="top-right" />
        
        <UserContext.Provider value={{ state, dispatch }}>
          <Routing />
        </UserContext.Provider>

        <AdminRouting />
        
    </>
    
  )
}

export default App