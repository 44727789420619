import React, { useState, useEffect } from 'react'
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import AdminFooter from "../AdminFooter";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import toast from 'react-hot-toast';
//import { Link } from 'react-router-dom';
//import moment from 'moment';

const LogListing = () => {

  // Delete Role
  const deleteTask = (id) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this item?');
    if (isConfirmed) {
    axios.delete(`/api/activitylog/${id}`).then((response) => {
      if(response.data.success){
        toast.success(response.data.success);
        axios.get('/api/activitylog').then((response2) => setRecords(response2.data));
      }else{
        toast.error(response.data.error);
      }
    });
    }
  };

  // DataTables
  useEffect(() => {
    // Fetch tasks when component mounts
    axios.get('/api/activitylog').then((response) => setRecords(response.data));
  }, []);

  const columns = [
    {
      name: 'Log name',
        selector: row=> row.log_name,
        sortable: true
    },
    {
      name: 'Log Type',
        selector: row=> row.log_type,
        sortable: true
    },
    {
      name: 'Log Id',
        selector: row=> row.log_id,
        sortable: true
    },
    { 
        name: 'Created Date',
        selector: function(row) {
          
          const timestamp = parseInt(row.created_date, 10); // Convert string to number
          const dateObject = new Date(timestamp);

          const formattedDate = dateObject.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZone: 'UTC', // Specify the desired timezone
          });
          return formattedDate;

        },
        sortable: true
    },
    { name: 'Action', selector: function(row) {
        return (
          <>
          <i className='fa fa-trash  text-danger' onClick={() => deleteTask(row._id)}></i>
          
          </>
        );
        
      } 
    }
  ];


  const [records, setRecords] = useState([]);
  function handleFilter(event){
    const inputValue = event.target.value.toLowerCase();
    if(inputValue){
        const newData = records.filter(row=>{
            return (
              row.log_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
              row.log_type.toLowerCase().includes(event.target.value.toLowerCase()) ||
              //row.log_id.toLowerCase().includes(event.target.value.toLowerCase())  ||
              row.created_date.toLowerCase().includes(event.target.value.toLowerCase()) 
            )
        });
        setRecords(newData);
    }else{
      axios.get('/api/activitylog').then((response) => setRecords(response.data));
    }
  }


  return (
    <>
    
      <div className="wrapper">
        <AdminHeader />

        <AdminSidebar />

        <div className="content-wrapper mt-2">
          <section className="content">
            <div className="">
              <div className="row">
                <div className="col-12">
                   
                    <div className="card card-dark">
                    <div className="card-header">
                      <h3 className="card-title">Activity Log Listing</h3>
                      <input type='text' onChange={handleFilter} placeholder="Search..."  style={{float:'right',width:'200px'}} className='form-control'/>

                    </div>

                    <div className="card-body">
                    <DataTable columns={columns} data={records} pagination></DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <br/>
        <AdminFooter />
      </div>
    </>
  );
};

export default LogListing;